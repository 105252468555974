import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { ClientTranInfo, AvgPriceInfo, AcCodeInfo, ChnlInfo, StkCodeInfo, CurrencyInfo } from './Types'
import { SystemHeader, ClientPicker } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "T0001" ;
var isReqAuth2:boolean = false ;

interface ClientTranEntryViewProps {
    gsb: AxiosInstance,
    ClientTran: ClientTranInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    PgmMode: any,
}


export function ClientTranEntryView({ gsb, ClientTran = {} as ClientTranInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, PgmMode }: ClientTranEntryViewProps) {


    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)


 
    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)

    const [isAVGValue, setisAVGValue] = useState("N")

    const [showChannel, setshowChannel] = useState(false)
    const [showMULTI_PRCE, setshowMULTI_PRCE] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
 
                setshowChannel( GsbFlag.MULTI_CHNL ) ;
                setshowMULTI_PRCE( GsbFlag.MULTI_PRCE ) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
    
/*
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
*/

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClientTranEntryView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ClientTran])

/*
    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("ClientTranEntryView ClientTran.d_acCode: ", ClientTran.d_acCode) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACBalInfo", cAcCode: ClientTran.d_acCode, CCY: ClientTran.d_Currency})
                //console.log("ClientTranEntryView GetACInfo data : ", res.data.data) 
                //console.log("ClientTranEntryView GetACInfo  count: ", res.data.count) 
                
                if ( ClientTran.d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    setLatestBalValue(res.data.data[0].acbal_LatestBal) 
                    setUnClrChqValue(res.data.data[0].acbal_UnClrChq) 
                } else {
                    setPrevDayValue(0)
                    setLatestBalValue(0)
                    setUnClrChqValue(0)
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, ClientTran.d_acCode, ClientTran.d_Currency, ClientTran.d_Amount])
*/

    useEffect(() => {
        setisAVGValue("N")
        if ( showMULTI_PRCE && ClientTran.d_Avg_price > 0 && ClientTran.d_Trade > 0 ) {
            setisAVGValue("Y")
        }
    }, [showMULTI_PRCE, ClientTran.d_Avg_price, ClientTran.d_Trade])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_Seq" in ClientTran)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            { PgmMode=="Normal" && <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${ClientTran.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
            </div> }
            { PgmMode=="Cancel" && <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={true} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-2" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Un-Cancel</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-6-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div> }
            { PgmMode=="Correct" && <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                {/* <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${ClientTran.d_acCode}`, '_blank') } } >Balance & Portfolio</Button> */}
            </div> }
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelInfo="*">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Seq)} />
                </FormGroup>
                <FormGroup className="Col-2-1" label="Seq No.C" >
                    <InputGroup readOnly value={ClientTran.d_SeqC} />
                </FormGroup>
                <FormGroup className="Col3-3-1" label="Tran Type" labelInfo="*">
                    <InputGroup readOnly value={ClientTran.d_Tran_type} />
                </FormGroup>
                { ( showChannel ) &&  
                    <FormGroup className="Col3-4-1" label="Channel" labelFor="inputChannel">
                        <InputGroup readOnly value={ClientTran.d_Channel} />
                    </FormGroup>
                }
                <FormGroup className="Col-5-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup readOnly value={ClientTran.d_Input_User} />
                </FormGroup>
                { PgmMode=="Normal" && <FormGroup className="Col-6-1" label="Action" labelFor="inputAction">
                    <InputGroup readOnly value={ClientTran.d_Action} />
                </FormGroup> }
                <FormGroup className="Col-7-2" label="Net Amount" labelFor="inputNetAmount">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Net_Amount)} />
                </FormGroup>
            </div>
            { ! (PgmMode=="Normal")  && <div className="Row">
                <FormGroup className="Col-1-1" label="Doc No." labelFor="inputDocNo">
                    <InputGroup readOnly value={ClientTran.d_Doc_No} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Tran Date" labelFor="input_His_Tran_date">
                    <InputGroup readOnly value= {utils.dispDate(ClientTran.d_CC_Tran_date, GsbFlag.dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-4-2" label="Settlement Date" labelFor="input_MS_settle_date">
                    <InputGroup readOnly value= {utils.dispDate(ClientTran.d_MS_settle_date, GsbFlag.dateFormat)} />
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode">
                    <InputGroup readOnly value={ClientTran.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name">
                    {/* 
                    <InputGroup readOnly value={ClientTran.d_acName1} />
                    <InputGroup readOnly value={ClientTran.d_acName2} />
                     */}
                    <div>
                    <div id="DispText">{ClientTran.d_acName1}</div>
                    <div id="DispText">{ClientTran.d_acName2}</div>
                    </div>
                </FormGroup>
{/*                
                <FormGroup className="Col-6-3" label="Outstanding Balance">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestBalValue)}</div>
                    <div id="DispText">Unclr Chq : {utils.formatNumber2(UnClrChqValue)}</div>
                    </div>
                </FormGroup>
*/}                
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                    <InputGroup readOnly value={ClientTran.d_StkNo} />
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{ClientTran.d_StkCode}</div>
                    <div id="DispText"></div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <RadioGroup
                        name="d_CcassStk"
                        disabled={true}
                        inline={true}
                        selectedValue={ClientTran.d_CcassStk}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <RadioGroup
                        name="d_StampFlag"
                        disabled={true}
                        inline={true}
                        selectedValue={ClientTran.d_StampFlag}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{ClientTran.d_Market}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Code" labelFor="inputCommCode" labelInfo="*">
                    <InputGroup readOnly value={ClientTran.d_CommCode} />
                </FormGroup>
                { GsbFlag.HKIDR_USER1 && <FormGroup className="Col-4-2" label="Aggregated Tran" labelFor="inputAggTran">
                    <RadioGroup
                        name="d_Aggr_transaction_HKIDR"
                        disabled={true}
                        inline={true}
                        selectedValue={ClientTran.d_Aggr_transaction_HKIDR}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
            </div>
            { (showMULTI_PRCE) && ! (PgmMode=="Normal") && <div className="Row">
                <FormGroup className="Col-1-2" label="is Avg Price" labelFor="inputd_isAVG">
                    <RadioGroup
                        name="d_isAVG"
                        disabled={true}
                        inline={true}
                        selectedValue={isAVGValue}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            { ! (PgmMode=="Normal") && isAVGValue=="Y" && <div className="Row">
                <FormGroup className="Col-1-2" label="Trade Quantity" labelFor="inputTrade_Qty1">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Qty1)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Turnover Amount" labelFor="inputd_Trade">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Avg Price" labelFor="inputd_Avg_price">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Avg_price)} />
                </FormGroup>
            </div> }
            { ( PgmMode=="Normal" || ( ! (PgmMode=="Normal") && isAVGValue=="N" ) ) && <div className="Row">
                <FormGroup className="Col-1-1" label="Price 1" labelFor="inputTrade_Price1">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Price1)} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity 1" labelFor="inputTrade_Qty1">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Qty1)} />
                </FormGroup>
                <FormGroup className="Col-4-1" label="Price 2" labelFor="inputTrade_Price2">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Price2)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Quantity 2" labelFor="inputTrade_Qty2">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Qty2)} />
                </FormGroup>
            </div> }
            { ( PgmMode=="Normal" || ( ! (PgmMode=="Normal") && isAVGValue=="N" ) ) && <div className="Row">
                <FormGroup className="Col-1-1" label="Price 3" labelFor="inputTrade_Price3">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Price3)} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity 3" labelFor="inputTrade_Qty3">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Qty3)} />
                </FormGroup>
                <FormGroup className="Col-4-1" label="Price 4" labelFor="inputTrade_Price4">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Price4)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Quantity 4" labelFor="inputTrade_Qty4">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trade_Qty4)} />
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-1" label="T. Deed" labelFor="inputT_Deed">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_T_Deed)} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Interest Accrual" labelFor="inputInterest_Accrual">
                    <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Interest_Accrual)} />
                </FormGroup>
                <FormGroup className="Col-4-2" label="Init by Broker" labelFor="inputInit_by_Broker">
                    <InputGroup readOnly value={ClientTran.d_Init_by_Broker} />
                </FormGroup>
            </div>
            { GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) && GsbFlag.SAS_MKT &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Settle Date (Stock)" labelFor="inputS_settle_date">
                        <InputGroup readOnly value= {utils.dispDate(ClientTran.d_S_settle_date, GsbFlag.dateFormat)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Settle Date (Momey)" labelFor="inputM_settle_date">
                        <InputGroup readOnly value= {utils.dispDate(ClientTran.d_M_settle_date, GsbFlag.dateFormat)} />
                    </FormGroup>
                </div>
            }
            { GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) && ! GsbFlag.SAS_MKT &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Settle Date" labelFor="inputMS_settle_date">
                        <InputGroup readOnly value= {utils.dispDate(ClientTran.d_MS_settle_date, GsbFlag.dateFormat)} />
                    </FormGroup>
                </div>
            }
            { (( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) ) || (GsbFlag.TBOB_USER && ClientTran.d_acCode.substr(0,1) ==="B" ) ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Commission" labelFor="inputCommission">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Commission)} />
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Stamp Fee" labelFor="inputStamp">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Stamp)} />
                    </FormGroup>
                    <FormGroup className="Col-4-1" label="Levy" labelFor="inputLevy">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Levy)} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="AFRC" labelFor="inputFRC">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_FRC)} />
                    </FormGroup>
                </div>
            }
            { (( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) ) || (GsbFlag.TBOB_USER && ClientTran.d_acCode.substr(0,1) ==="B" ) ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="VAT/Trading Fee" labelFor="inputTrading_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Trading_Fee)} />
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Clearing Fee" labelFor="inputCCASS_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_CCASS_Fee)} />
                    </FormGroup>
                    <FormGroup className="Col-4-1" label="Transfer Fee" labelFor="inputTransfer_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_Transfer_Fee)} />
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-4"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Handling_Fee" labelFor="inputSAS_Handling_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_SAS_Handling_Fee)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Management Fee" labelFor="inputSAS_Management_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_SAS_Management_Fee)} />
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(ClientTran.d_Market) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Transfer Fee" labelFor="inputTransfer_Fee">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_SAS_Transfer_Fee)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Transfer Fee HK" labelFor="inputSAS_Transfer_Fee_HK">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_SAS_Transfer_Fee_HK)} />
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Stamp Duty (For Seller)" labelFor="inputSAS_Stamp_Duty">
                        <InputGroup readOnly value={utils.formatNumber2(ClientTran.d_SAS_Stamp_Duty)} />
                    </FormGroup>
                </div>
            }

        </div>
    )
}

interface ClientTranEntryEditProps {
    gsb: AxiosInstance,
    ClientTran?: ClientTranInfo,
    ChnlList?: ChnlInfo[],
    isNew?: boolean,
    onSave?: (stk: ClientTranInfo) => void,
    onCancel?: () => void,
    PgmMode: any,
}

export function ClientTranEntryEdit({ gsb, ClientTran = {} as ClientTranInfo, ChnlList=[] as ChnlInfo[], isNew = false, onSave = () => { }, onCancel = () => { }, PgmMode }: ClientTranEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<ClientTranInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [showEditComm, setshowEditComm] = useState(false)

    const [ChannelValue, setChannelValue] = useState(ClientTran.d_Channel)
    const [AcCodeValue, setAcCodeValue] = useState(ClientTran.d_acCode)
    const [setClCodeValue, setSetClCodeValue] = useState(() => setAcCodeValue)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const [AcCodeList, setAcCodeList] = useState([] as AcCodeInfo[])
    const [AcName1Value, setAcName1Value] = useState(ClientTran.d_acName1)
    const [AcName2Value, setAcName2Value] = useState(ClientTran.d_acName2)
    const [CommCodeValue, setCommCodeValue] = useState(ClientTran.d_CommCode)
    const [StkNoValue, setStkNoValue] = useState(ClientTran.d_StkNo)
    const [Caretdown2, setCaretdown2] = useState(false)
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [StkCodeValue, setStkCodeValue] = useState(ClientTran.d_StkCode)
    const [StkNameValue, setStkNameValue] = useState("")
    const [CCASSStockValue, setCCASSStockValue] = useState(ClientTran.d_CcassStk)
    const [StampFlagValue, setStampFlagValue] = useState(ClientTran.d_StampFlag)
    const [MarketValue, setMarketValue] = useState(ClientTran.d_Market)
    const [FeeCodeValue, setFeeCodeValue] = useState(ClientTran.d_FeeCode)
    const [CurrencyValue, setCurrencyValue] = useState(ClientTran.d_Currency)
    const [AggTranValue, setAggTranValue] = useState(ClientTran.d_Aggr_transaction_HKIDR)
    const [isAVGValue, setisAVGValue] = useState("N")
    const [TradeValue, setTradeValue] = useState(utils.numToString(ClientTran.d_Trade))
    const [AvgPriceValue, setAvgPriceValue] = useState(utils.numToString(ClientTran.d_Avg_price))
    const [isAvgPriceEditPress, setisAvgPriceEditPress] = useState(false)
    const [AvgPriceData, setAvgPriceData] = useState([] as AvgPriceInfo[])
    const [AvgPriceItemNo, setAvgPriceItemNo] = useState(18)

    const [Qty1Value, setQty1Value] = useState(utils.numToString(ClientTran.d_Trade_Qty1))
    const [Qty2Value, setQty2Value] = useState(utils.numToString(ClientTran.d_Trade_Qty2))
    const [Qty3Value, setQty3Value] = useState(utils.numToString(ClientTran.d_Trade_Qty3))
    const [Qty4Value, setQty4Value] = useState(utils.numToString(ClientTran.d_Trade_Qty4))
    const [Price1Value, setPrice1Value] = useState(utils.numToString(ClientTran.d_Trade_Price1))
    const [Price2Value, setPrice2Value] = useState(utils.numToString(ClientTran.d_Trade_Price2))
    const [Price3Value, setPrice3Value] = useState(utils.numToString(ClientTran.d_Trade_Price3))
    const [Price4Value, setPrice4Value] = useState(utils.numToString(ClientTran.d_Trade_Price4))

    const [netAmountValue, setnetAmountValue] = useState(ClientTran.d_Net_Amount)

    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)

    const [TranTypeValue, setTranTypeValue] = useState(ClientTran.d_Tran_type)

    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [showChannel, setshowChannel] = useState(false)
    const [showMULTI_PRCE, setshowMULTI_PRCE] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
 
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setshowChannel( GsbFlag.MULTI_CHNL ) ;
                setshowMULTI_PRCE( GsbFlag.MULTI_PRCE ) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const [o_Doc_No, seto_Doc_No] = useState("")

    let o_acCode = ClientTran.d_acCode
    let o_Skip_Recal = ClientTran.d_Skip_Recal 
    let o_Commission = ClientTran.d_Commission
    let o_Stamp = ClientTran.d_Stamp
    let o_Levy = ClientTran.d_Levy
    let o_Trading_Fee = ClientTran.d_Trading_Fee
    let o_FLevy = ClientTran.d_FRC
    let o_CCASS_Fee = ClientTran.d_CCASS_Fee
    let o_Transfer_Fee = ClientTran.d_Transfer_Fee

    let o_SAS_Handling_Fee = 0
    let o_SAS_Management_Fee = 0
    let o_SAS_Transfer_Fee = 0
    let o_SAS_Transfer_Fee_HK = 0
    let o_SAS_Stamp_Duty = 0

    if ( GsbFlag.MULTI_MKT &&  GsbFlag.SAS_MKT) {
        o_SAS_Handling_Fee = ClientTran.d_SAS_Handling_Fee
        o_SAS_Management_Fee = ClientTran.d_SAS_Management_Fee
        o_SAS_Transfer_Fee = ClientTran.d_SAS_Transfer_Fee
        o_SAS_Transfer_Fee_HK = ClientTran.d_SAS_Transfer_Fee_HK
        o_SAS_Stamp_Duty = ClientTran.d_SAS_Stamp_Duty
    }



    useEffect(() => {
        // Call reset to get the values of record ClientTranInfo 
        if ("d_Seq" in ClientTran) {
            reset(ClientTran)
        }
    }, [ClientTran, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        register({ name: "d_Channel" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


/*    
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [gsb])
*/    

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    //setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    //setLatestQtyValue(res.data.data[0].acbal_LatestBal) 
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    if ( o_acCode != AcCodeValue ) {
                        setCommCodeValue(res.data.data[0].d_CommCode)
                    }
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setCommCodeValue("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])


    useEffect(() => {
        (async () => {
            try {
                // get AC List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "acCode",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false,
                    SortByName: true
                },
                cPgmId: "Clmast",
                cAction: "ListRec",
            });

                //console.log("res.data.data: ", res.data.data) 
                //setAcCodeList(utils.sortByColumn(res.data.data, "d_acName1"))
                setAcCodeList(res.data.data)
                //console.log("set AcCodeList: ", AcCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)
                    setCCASSStockValue(res.data.data[0].d_CcassStk)
                    setStampFlagValue(res.data.data[0].d_StampFlag)
                    setMarketValue(res.data.data[0].d_Market)
                    setFeeCodeValue(res.data.data[0].d_FeeCode)
                    setCurrencyValue(res.data.data[0].d_Currency)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                    setCCASSStockValue("N")
                    setStampFlagValue("N")
                    setMarketValue("")
                    setFeeCodeValue("")
                    setCurrencyValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])

    useEffect(() => {
        setisAVGValue("N")
        if ( showMULTI_PRCE && utils.StringToNum(TradeValue) > 0 && utils.StringToNum(AvgPriceValue) > 0 ) {
            setisAVGValue("Y")
        }
    }, [showMULTI_PRCE, TradeValue, AvgPriceValue])

    useEffect(() => {
        //console.log("GetAvgPrice_Info AvgPriceItemNo: ", AvgPriceItemNo) 
        for (let i = AvgPriceData.length+1; i <= AvgPriceItemNo; i++) {
            AvgPriceData.push({d_AvgPrice_SeqNo: 0, d_AvgPrice_No: i, d_AvgPrice_TradePrice: "0", d_AvgPrice_TradeQty: "0"})
        }
        (async () => {
            //console.log("GetAvgPrice_Info o_Doc_No: ", o_Doc_No, ClientTran.d_Doc_No) 
            if ( ! (PgmMode=="Normal") && ! ( o_Doc_No === ClientTran.d_Doc_No ) && ClientTran.d_Doc_No.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Transaction', {sb_data: "", cPgmId: "CancelCorrectTrade", cAction: "GetAvgPrice_Info", cRefNo: ClientTran.d_Doc_No})
                    //console.log("GetAvgPrice_Info res.data.data: ", res.data.data) 
                    
                    if ( res.data.count > 0 )
                    { 
                        seto_Doc_No(ClientTran.d_Doc_No)
                        setAvgPriceData(res.data.data)
                    }
                } catch (error) {
                    showErrorBox(error)
                }
            }
        })()

    }, [ClientTran.d_Doc_No, AvgPriceItemNo])


    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    if (! isNew && ! ("d_Seq" in ClientTran)) return null


    let DescLength = 45
    if ( GsbFlag.LONG_DESC ) {
        DescLength = 60
    }
    
    const onChangeAvgPrice_TradePrice = (i:number, Price:string) => {
        let mTradeValue = 0
        let mqty = 0

        if (i >= 0 ) {
            //console.log("onChangeAvgPrice_TradePrice AvgPriceData", AvgPriceData);
            //console.log("onChangeAvgPrice_TradePrice i, Price", i, Price);
            //let newData = [...AvgPriceData]

            AvgPriceData[i].d_AvgPrice_TradePrice = Price
            for (let j = 0; j < AvgPriceData.length-1; j++) {
                if ( (utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradePrice) > 0) && (utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty) > 0) ) {
                    mqty = mqty + utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty)
                    mTradeValue = mTradeValue + utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradePrice) * utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty)
                }
            }
            setTradeValue(utils.numToString(mTradeValue))
            setQty1Value(utils.numToString(mqty))
            if ( mqty > 0 ) {
                let mAvgPrice = mTradeValue/mqty
                setAvgPriceValue(utils.formatNumber3(mAvgPrice, 6))
            } else {
                setAvgPriceValue("0")
            }
        }
    }

    const onChangeAvgPrice_TradeQty = (i:number, Qty:string) => {
        let mTradeValue = 0
        let mqty = 0

        if (i >= 0 ) {
            //console.log("onChangeAvgPrice_TradeQty AvgPriceData", AvgPriceData, AvgPriceData.length);
            //console.log("onChangeAvgPrice_TradeQty i, Qty", i, Qty);
            //let newData = [...AvgPriceData]

            AvgPriceData[i].d_AvgPrice_TradeQty = Qty
            for (let j = 0; j < AvgPriceData.length-1; j++) {
                if ( (utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradePrice) > 0) && (utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty) > 0) ) {
                    mqty = mqty + utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty)
                    mTradeValue = mTradeValue + utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradePrice) * utils.StringToNum(AvgPriceData[j].d_AvgPrice_TradeQty)
                }
            }
            setTradeValue(utils.numToString(mTradeValue))
            setQty1Value(utils.numToString(mqty))
            if ( mqty > 0 ) {
                let mAvgPrice = mTradeValue/mqty
                setAvgPriceValue(utils.formatNumber3(mAvgPrice, 6))
            } else {
                setAvgPriceValue("0")
            }
        }
    }


    const onSubmit = (data: ClientTranInfo) => {

        //console.log("ClientTranEntryedit onsubmit data: ", data)
        //console.log("ClientTranEntryedit onsubmit StkPrice: ", StkPrice)
        //console.log("ClientTranEntryedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf
        if ( typeof(data.d_Seq) === "undefined") {
            data.d_Seq = 0
        }

        data.d_Tran_type = TranTypeValue;
        data.d_Tran_type = data.d_Tran_type.toUpperCase();
//console.log("ClientTranEntryedit onsubmit data.d_Tran_type: ", data.d_Tran_type)
        data.d_Net_Amount = netAmountValue

        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();
        data.d_acName1 = AcName1Value
        data.d_acName2 = AcName2Value
        data.d_CommCode = CommCodeValue

        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;
        data.d_CcassStk = CCASSStockValue
        data.d_StampFlag = StampFlagValue
        data.d_Market = MarketValue;
        data.d_FeeCode = FeeCodeValue;
        data.d_Currency = CurrencyValue;
        data.d_Aggr_transaction_HKIDR = AggTranValue
        if ( typeof(data.d_Channel) === "undefined" ) {
            data.d_Channel = " "
        }
        if  ( showChannel ) {
            data.d_Channel = ChannelValue.toUpperCase();
        }
        //console.log("ClientTranEntryedit onsubmit data.d_Channel: ", data.d_Channel)
   
        data.d_Trade_Qty1 = utils.StringToNum(Qty1Value);
        data.d_Trade_Qty2 = utils.StringToNum(Qty2Value);
        data.d_Trade_Qty3 = utils.StringToNum(Qty3Value);
        data.d_Trade_Qty4 = utils.StringToNum(Qty4Value);
        data.d_Trade_Price1 = utils.StringToNum(Price1Value);
        data.d_Trade_Price2 = utils.StringToNum(Price2Value);
        data.d_Trade_Price3 = utils.StringToNum(Price3Value);
        data.d_Trade_Price4 = utils.StringToNum(Price4Value);

        if ( typeof(data.d_Commission) === "undefined" ) {
            data.d_Commission = o_Commission
        }
        if ( typeof(data.d_Stamp) === "undefined" ) {
            data.d_Stamp = o_Stamp
        }
        if ( typeof(data.d_Levy) === "undefined" ) {
            data.d_Levy = o_Levy
        }
        if ( typeof(data.d_Trading_Fee) === "undefined" ) {
            data.d_Trading_Fee = o_Trading_Fee
        }
        if ( typeof(data.d_FRC) === "undefined" ) {
            data.d_FRC = o_FLevy
        }
        if ( typeof(data.d_CCASS_Fee) === "undefined" ) {
            data.d_CCASS_Fee = o_CCASS_Fee
        }
        if ( typeof(data.d_Transfer_Fee) === "undefined" ) {
            data.d_Transfer_Fee = o_Transfer_Fee
        }

        if ( typeof(data.d_SAS_Handling_Fee) === "undefined" ) {
            data.d_SAS_Handling_Fee = o_SAS_Handling_Fee
        }
        if ( typeof(data.d_SAS_Management_Fee) === "undefined" ) {
            data.d_SAS_Management_Fee = o_SAS_Management_Fee
        }
        if ( typeof(data.d_SAS_Transfer_Fee) === "undefined" ) {
            data.d_SAS_Transfer_Fee = o_SAS_Transfer_Fee
        }
        if ( typeof(data.d_SAS_Transfer_Fee_HK) === "undefined" ) {
            data.d_SAS_Transfer_Fee_HK = o_SAS_Transfer_Fee_HK
        }
        if ( typeof(data.d_SAS_Stamp_Duty) === "undefined" ) {
            data.d_SAS_Stamp_Duty = o_SAS_Stamp_Duty
        }

        if ( typeof(data.d_Skip_Recal) === "undefined" ) {
            data.d_Skip_Recal = o_Skip_Recal
        }
        

        data.d_isAVG = isAVGValue
        data.d_Trade = utils.StringToNum(TradeValue);
        data.d_Avg_price = utils.StringToNum(AvgPriceValue);

        if ( ! (PgmMode=="Normal") ) {
            if (utils.StringToNum(AvgPriceValue) > 0) {
                data.d_Trade_Price1 = utils.StringToNum(AvgPriceValue);
            }
            data.d_AvgPrice_info = AvgPriceData
        }


/*
        if ( ! (o_Commission+0 == data.d_Commission+0) ) {
            console.log("call ClientTranEntryedit o_Commission", o_Commission, data.d_Commission)
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_Stamp+0 == data.d_Stamp+0) || ! (o_Levy+0 == data.d_Levy+0) ) {
            console.log("call ClientTranEntryedit o_Stamp", o_Stamp, data.d_Stamp)
            console.log("call ClientTranEntryedit o_Levy", o_Levy, data.d_Levy)
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_FLevy+0 == data.d_FRC+0) ) {
            console.log("call ClientTranEntryedit o_FLevy", o_FLevy, data.d_FRC)
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_Trading_Fee+0 == data.d_Trading_Fee+0) || ! (o_CCASS_Fee+0 == data.d_CCASS_Fee+0) ) {
            console.log("call ClientTranEntryedit o_Trading_Fee", o_Trading_Fee, data.d_Trading_Fee)
            console.log("call ClientTranEntryedit o_CCASS_Fee", o_CCASS_Fee, data.d_CCASS_Fee)
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_Transfer_Fee+0 == data.d_Transfer_Fee+0) ) {
            console.log("call ClientTranEntryedit o_Transfer_Fee", o_Transfer_Fee, data.d_Transfer_Fee)
            data.d_Skip_Recal = "Y"
        }

        if ( ! (o_SAS_Handling_Fee == data.d_SAS_Handling_Fee) || ! (o_SAS_Management_Fee == data.d_SAS_Management_Fee) ) {
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_SAS_Transfer_Fee == data.d_SAS_Transfer_Fee) || ! (o_SAS_Transfer_Fee_HK == data.d_SAS_Transfer_Fee_HK) ) {
            data.d_Skip_Recal = "Y"
        }
        if ( ! (o_SAS_Stamp_Duty == data.d_SAS_Stamp_Duty) ) {
            data.d_Skip_Recal = "Y"
        }
*/


        (async () => {
            try {
                let cPgmId = "ClientTranEntry"
                let cAction = "ClientTranValidate"
                if ( PgmMode=="Cancel" ) {
                    cPgmId = "CancelCorrectTrade"
                    cAction = "CancelPrevTradeValidate"
                }
                if ( PgmMode=="Correct" ) {
                    cPgmId = "CancelCorrectTrade"
                    cAction = "CorrectTradeValidate"
                }
                //console.log("call ClientTranEntryedit data", data)
                //console.log("call ClientTranEntryedit isNew", isNew)
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: cPgmId, cAction: cAction, isNew: isNew})
                //console.log("ClientTranEntryedit res.data.ok", res.data.ok)
                //console.log("ClientTranEntryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    const acRenderer: ItemRenderer<AcCodeInfo> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<AcCodeInfo> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: AcCodeInfo) => {
        return ac.d_acCode
    }

    const ChnlRenderer: ItemRenderer<ChnlInfo> = (ChnlList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${ChnlList.d_ChnlDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ChnlList.d_ChnlCode}
                key={ChnlList.d_ChnlCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const ChnlPredicate: ItemPredicate<ChnlInfo> = (query, ChnlList, _index, exactMatch) => {
        const normalizedText = `${ChnlList.d_ChnlCode} - ${ChnlList.d_ChnlDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const ChnlValueRenderer = (ChnlList: ChnlInfo) => {
        return ChnlList.d_ChnlCode
    }

    

    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }





    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }

             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelFor="inputSeqNo" labelInfo="*">
                    <InputGroup className="inputNumberNoSpin" disabled={true} id="inputSeqNo" name="d_Seq" autoFocus={isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Seq && errors.d_Seq.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Seq && errors.d_Seq.types && <span id="Err_msg">{errors.d_Seq.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Seq No.C" labelFor="inputSeqNoC" >
                    <InputGroup className="inputNumberNoSpin" disabled={isNew} id="inputSeqNoC" name="d_SeqC" defaultValue="" inputRef={register} />
                    {errors.d_SeqC && errors.d_SeqC.type === "required" && (<span id="Err_msg">Seq No. C cannot be Blank</span>)}
                    {errors.d_SeqC && errors.d_SeqC.types && <span id="Err_msg">{errors.d_SeqC.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-3-1" label="Tran Type" labelFor="inputTranType" labelInfo="*">
                    {/*
                    <InputGroup id="inputTranType" name="d_Tran_type" autoFocus={! isNew} defaultValue="" placeholder="Tran Type ..." inputRef={register({ required: true , maxLength: 1 }) } />
                    {errors.d_Tran_type && errors.d_Tran_type.type === "required" && <span id="Err_msg">Tran Type cannot be Blank</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.types && <span id="Err_msg">{errors.d_Tran_type.types.message}</span>}
                    */}
                    <select  id="inputTranType"  
                        value={TranTypeValue}
                        autoFocus
                        onChange={utils.handleStringChange(s => setTranTypeValue(s)) }
                    >
                        <option value="B">B: Buy</option>
                        <option value="S">S: Sell</option>
                    </select>
                </FormGroup>
                { ( showChannel ) &&  
                    <FormGroup className="Col3-4-1" label="Channel" labelFor="inputChannel">
                        {/* <InputGroup id="inputChannel" name="d_Channel"  defaultValue="" placeholder="Channel ..."  inputRef={register({ maxLength: 1 }) } />
                        {errors.d_Channel && errors.d_Channel.type === "maxLength" && <span id="Err_msg">Max length is 1</span>} */}
                        <Suggest2
                            inputProps={{ placeholder: "Channel ..." }}
                            activeItem={ChnlList.find((Chnl) => Chnl.d_ChnlCode === ChannelValue)}
                            selectedItem={ChnlList.find((Chnl) => Chnl.d_ChnlCode === ChannelValue)}
                            items={ChnlList}
                            itemRenderer={ChnlRenderer}
                            itemPredicate={ChnlPredicate}
                            inputValueRenderer={ChnlValueRenderer}
                            onItemSelect={(ClientTran) => { setChannelValue(ClientTran.d_ChnlCode) }}
                        />
                        {errors.d_Channel && errors.d_Channel.types && <span id="Err_msg">{errors.d_Channel.types.message}</span>}
                    </FormGroup>
                }
                <FormGroup className="Col-5-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup disabled={true} id="inputInputUser" name="d_Input_User" defaultValue="" placeholder="Input User ..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_Input_User && errors.d_Input_User.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_Input_User && errors.d_Input_User.types && <span id="Err_msg">{errors.d_Input_User.types.message}</span>}
                </FormGroup>
                { PgmMode=="Normal" && <FormGroup className="Col-6-1" label="Action" labelFor="inputAction">
                    <InputGroup disabled={true} id="inputAction" name="d_Action" defaultValue="" placeholder="Action ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_Action && errors.d_Action.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Action && errors.d_Action.types && <span id="Err_msg">{errors.d_Action.types.message}</span>}
                </FormGroup> }
             </div>
             { ! (PgmMode=="Normal")  && <div className="Row">
                <FormGroup className="Col-1-1" label="Doc No." labelFor="inputDocNo">
                    <InputGroup id="inputDocNo" name="d_Doc_No" defaultValue="" placeholder="Doc No. ..." inputRef={register({ maxLength: 8}) } />
                    {errors.d_Doc_No && errors.d_Doc_No.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_Doc_No && errors.d_Doc_No.types && <span id="Err_msg">{errors.d_Doc_No.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Tran Date" labelFor="input_His_Tran_date">
                    <InputGroup id="input_His_Tran_date" name="d_CC_Tran_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_CC_Tran_date && errors.d_CC_Tran_date.types && <span id="Err_msg">{errors.d_CC_Tran_date.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label="Settlement Date" labelFor="input_MS_settle_date">
                    <InputGroup id="input_MS_settle_date" name="d_MS_settle_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_MS_settle_date && errors.d_MS_settle_date.types && <span id="Err_msg">{errors.d_MS_settle_date.types.message}</span>}
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode" labelInfo="*">
                    <InputGroup id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s.toUpperCase())) } inputRef={register({ required: true , maxLength: 8}) } 
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>setAcCodeValue); setIsClPickerOpen(true)}}></Button>}
                    />
                    {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name" labelFor="inputAcName1">
                    {/*
                    <InputGroup disabled={true} id="inputAcName1" name="d_acName1" autoFocus={! isNew} defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    <InputGroup disabled={true} id="inputAcName2" name="d_acName2" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    */}
                    <div>
                    <div id="DispText">{AcName1Value}</div>
                    <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
{/*                
                <FormGroup className="Col-6-3" label="Outstanding Balance">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestBalValue)}</div>
                    <div id="DispText">Unclr Chq : {utils.formatNumber2(UnClrChqValue)}</div>
                    </div>
                </FormGroup>
*/}                
            </div>
            <div className="Row">
                { ! Caretdown2 && 
                    <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                        <InputGroup id="inputStkNo" value={StkNoValue} defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                        {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Stock No. cannot be Blank</span>}
                        {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                    </FormGroup>
                }
                { Caretdown2 && 
                    <FormGroup className="Col3-1-1" label="Stock No." labelFor="inputStkNo" >
                        <Suggest
                            inputProps={{ placeholder: "Please choose Stock No. ..." }}
                            defaultSelectedItem={StkCodeList.find((stk1) => stk1.d_StkNo === StkNoValue)}
                            items={StkCodeList}
                            itemRenderer={StkRenderer}
                            itemPredicate={StkPredicate}
                            inputValueRenderer={StkValueRenderer}
                            onItemSelect={(Stk) => { setStkNoValue(Stk.d_StkNo) ; setStkCodeValue(Stk.d_StkCode) ; setStkNameValue(Stk.d_StkName) ; setCaretdown2(!Caretdown2) }}
                        />
                    </FormGroup>
                }
                {/* <span onClick={() => { setCaretdown2(!Caretdown2) } }> {imgCaretdown} </span> */}
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <RadioGroup
                        name="d_CcassStk"
                        disabled={true}
                        inline={true}
                        selectedValue={CCASSStockValue}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <RadioGroup
                        name="d_StampFlag"
                        disabled={true}
                        inline={true}
                        selectedValue={StampFlagValue}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{MarketValue}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Code" labelFor="inputCommCode" labelInfo="*">
                    <InputGroup disabled={! showEditComm} id="inputCommCode" defaultValue={CommCodeValue} value={CommCodeValue} onChange={utils.handleStringChange(s => setCommCodeValue(s))} inputRef={register({ maxLength: 2 }) } />
                    {errors.d_CommCode && errors.d_CommCode.type === "required" && (<span id="Err_msg">Commission Code cannot be Blank</span>)}
                    {errors.d_CommCode && errors.d_CommCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    {errors.d_CommCode && errors.d_CommCode.types && <span id="Err_msg">{errors.d_CommCode.types.message}</span>}
                </FormGroup>
                <FormGroup label="Amend Code">
                    <Button className="Col-3-1" icon="edit" onClick={() => setshowEditComm(true) } >Amend</Button>
                </FormGroup>
                { GsbFlag.HKIDR_USER1 && <FormGroup className="Col-4-2" label="Aggregated Tran" labelFor="inputAggTran">
                    <RadioGroup
                        name="d_Aggr_transaction_HKIDR"
                        inline={true}
                        selectedValue={AggTranValue}
                        onChange={utils.handleStringChange(s => setAggTranValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Aggr_transaction_HKIDR && errors.d_Aggr_transaction_HKIDR.types && <span id="Err_msg">{errors.d_Aggr_transaction_HKIDR.types.message}</span>}
                </FormGroup> }
            </div>
            { showMULTI_PRCE && ! (PgmMode=="Normal") && <div className="Row">
                <FormGroup className="Col-1-2" label="is Avg Price" labelFor="inputd_isAVG">
                    <RadioGroup
                        name="d_isAVG"
                        inline={true}
                        selectedValue={isAVGValue}
                        onChange={utils.handleStringChange(s => setisAVGValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_isAVG && errors.d_isAVG.types && <span id="Err_msg">{errors.d_isAVG.types.message}</span>}
                </FormGroup>
            </div> }
            { ! (PgmMode=="Normal") && isAVGValue=="Y" && <div className="Row">
                <FormGroup className="Col-1-2" label="Trade Quantity" labelFor="inputTrade_Qty1">
                    <InputGroup id="inputTrade_Qty1" disabled={true} defaultValue={Qty1Value} value={Qty1Value} onChange={utils.handleStringChange(n => setQty1Value(n))}  />
                    {errors.d_Trade_Qty1 && errors.d_Trade_Qty1.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty1 && errors.d_Trade_Qty1.types && <span id="Err_msg">{errors.d_Trade_Qty1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Turnover Amount" labelFor="inputd_Trade">
                    <InputGroup id="inputd_Trade"  disabled={true} defaultValue={TradeValue} value={TradeValue} onChange={utils.handleStringChange(n => setTradeValue(n))} />
                    {errors.d_Trade && errors.d_Trade.type === "min" && <span id="Err_msg">Amount sholud be greater than 0 </span>}
                    {errors.d_Trade && errors.d_Trade.types && <span id="Err_msg">{errors.d_Trade.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Avg Price" labelFor="inputd_Avg_price">
                    <InputGroup id="inputd_Avg_price"  disabled={true} defaultValue={AvgPriceValue} value={AvgPriceValue} onChange={utils.handleStringChange(n => setAvgPriceValue(n))} />
                    {errors.d_Avg_price && errors.d_Avg_price.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                    {errors.d_Avg_price && errors.d_Avg_price.types && <span id="Err_msg">{errors.d_Avg_price.types.message}</span>}
                </FormGroup>
                <Button className="Col-7-1" icon="edit" onClick={() => setisAvgPriceEditPress(true) } >Edit</Button>
                    <Dialog
                        icon={IconNames.EDIT} 
                        title = "Input Average Price"
                        isOpen={isAvgPriceEditPress}
                        onClose={ () => { alert("Please press OK to exit.")  } }
                        style={{ minWidth: '66.67%' }}
                    >
                        <DialogBody>
                            <form >
                                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                                    <HTMLTable striped interactive condensed>
                                    <thead>
                                        <tr>
                                            <th className="TCol-No">No.</th>
                                            <th className="TCol-TradePrice">Trade Price</th>
                                            <th className="TCol-TradeQty">Trade Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AvgPriceData.map((item, i) =>
                                            <tr key={i}>
                                                <td className="TCol-No" onClick={() => { } }>{item.d_AvgPrice_No}</td>
                                                <td className="TCol-TradePrice" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputd_AvgPrice_TradePrice" defaultValue={item.d_AvgPrice_TradePrice} onChange={utils.handleStringChange(s => onChangeAvgPrice_TradePrice(i, s ))} /></td>
                                                <td className="TCol-TradeQty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputd_AvgPrice_TradeQty" defaultValue={item.d_AvgPrice_TradeQty} onChange={utils.handleStringChange(s => onChangeAvgPrice_TradeQty(i, s ))} /></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="TCol-No"></td>
                                            <td className="TCol-TradePrice" onClick={() => { setAvgPriceItemNo(AvgPriceItemNo+10) } } style={{justifyContent: 'flex-end', color:"blue"}}>+ More 10 items</td>
                                            <td className="TCol-TradeQty"><b>{Qty1Value}</b></td>
                                        </tr>
                                    </tfoot>
                                    </HTMLTable>
                                    <div className="Col-1-5">
                                        <div>Turnover Amount: <b>{TradeValue}</b></div>
                                    </div>
                                    <div className="Col-1-5">
                                        <div>Calculated Avg Price: <b>{AvgPriceValue}</b></div>
                                    </div>
                                    <div className="Spacing-V-16"></div>
                                    <div className="Row-8">
                                        <Button className="Col-4-1" intent={Intent.WARNING} onClick={ () => { setisAvgPriceEditPress(false) } }>OK</Button>
                                    </div>
                                </Card>
                            </form>
                        </DialogBody>
                    </Dialog>
            </div> }
            { ( PgmMode=="Normal" || ( ! (PgmMode=="Normal") && isAVGValue=="N" ) ) && <div className="Row">
                <FormGroup className="Col-1-1" label="Price 1" labelFor="inputTrade_Price1">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Price1"  defaultValue="0" value={Price1Value} onValueChange={(n => setPrice1Value(n))} type="number" buttonPosition="none" asyncControl={true} inputRef={register} /> */}
                    {/* <InputGroup className="inputNumberNoSpin" id="inputTrade_Price1" defaultValue={Price1Value} value={Price1Value} type="number" onChange={utils.handleStringChange(n => setPrice1Value(n))}  /> */}
                    <InputGroup id="inputTrade_Price1" defaultValue={Price1Value} value={Price1Value} onChange={utils.handleStringChange(n => setPrice1Value(n))} />
                    {errors.d_Trade_Price1 && errors.d_Trade_Price1.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                    {errors.d_Trade_Price1 && errors.d_Trade_Price1.types && <span id="Err_msg">{errors.d_Trade_Price1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity 1" labelFor="inputTrade_Qty1">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Qty1"  defaultValue="0" value={Qty1Value} onValueChange={(n => setQty1Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup id="inputTrade_Qty1" defaultValue={Qty1Value} value={Qty1Value} onChange={utils.handleStringChange(n => setQty1Value(n))}  />
                    {errors.d_Trade_Qty1 && errors.d_Trade_Qty1.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty1 && errors.d_Trade_Qty1.types && <span id="Err_msg">{errors.d_Trade_Qty1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Price 2" labelFor="inputTrade_Price2">
                        {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Price2"  defaultValue="0" value={Price2Value} onValueChange={(n => setPrice2Value(n))} type="number" buttonPosition="none" asyncControl={true} inputRef={register} /> */}
                        {/* <InputGroup className="inputNumberNoSpin" id="inputTrade_Price2" defaultValue={Price2Value} value={Price2Value} type="number" onChange={utils.handleStringChange(n => setPrice2Value(n))}  /> */}
                        <InputGroup className="inputNumberNoSpin" id="inputTrade_Price2" defaultValue={Price2Value} value={Price2Value} onChange={utils.handleStringChange(n => setPrice2Value(n))}  /> 
                        {errors.d_Trade_Price2 && errors.d_Trade_Price2.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                        {errors.d_Trade_Price2 && errors.d_Trade_Price2.types && <span id="Err_msg">{errors.d_Trade_Price2.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Quantity 2" labelFor="inputTrade_Qty2">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Qty2"  defaultValue="0" value={Qty2Value} onValueChange={(n => setQty2Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup id="inputTrade_Qty2" defaultValue={Qty2Value} value={Qty2Value} onChange={utils.handleStringChange(n => setQty2Value(n))}  />
                    {errors.d_Trade_Qty2 && errors.d_Trade_Qty2.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty2 && errors.d_Trade_Qty2.types && <span id="Err_msg">{errors.d_Trade_Qty2.types.message}</span>}
                </FormGroup>
            </div> }
            { ( PgmMode=="Normal" || ( ! (PgmMode=="Normal") && isAVGValue=="N" ) ) && <div className="Row">
                <FormGroup className="Col-1-1" label="Price 3" labelFor="inputTrade_Price3">
                        {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Price3"  defaultValue="0" value={Price3Value} onValueChange={(n => setPrice3Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                        <InputGroup id="inputTrade_Price3" defaultValue={Price3Value} value={Price3Value} onChange={utils.handleStringChange(n => setPrice3Value(n))}  />
                        {errors.d_Trade_Price3 && errors.d_Trade_Price3.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                        {errors.d_Trade_Price3 && errors.d_Trade_Price3.types && <span id="Err_msg">{errors.d_Trade_Price3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity 3" labelFor="inputTrade_Qty3">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Qty3"  defaultValue="0" value={Qty3Value} onValueChange={(n => setQty3Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup id="inputTrade_Qty3" defaultValue={Qty3Value} value={Qty3Value} onChange={utils.handleStringChange(n => setQty3Value(n))}  />
                    {errors.d_Trade_Qty3 && errors.d_Trade_Qty3.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty3 && errors.d_Trade_Qty3.types && <span id="Err_msg">{errors.d_Trade_Qty3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Price 4" labelFor="inputTrade_Price4">
                        {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Price4"  defaultValue="0" value={Price4Value} onValueChange={(n => setPrice4Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                        <InputGroup id="inputTrade_Price4" defaultValue={Price4Value} value={Price4Value} onChange={utils.handleStringChange(n => setPrice4Value(n))}  />
                        {errors.d_Trade_Price4 && errors.d_Trade_Price4.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                        {errors.d_Trade_Price4 && errors.d_Trade_Price4.types && <span id="Err_msg">{errors.d_Trade_Price4.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Quantity 4" labelFor="inputTrade_Qty4">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Qty4"  defaultValue="0" value={Qty4Value} onValueChange={(n => setQty4Value(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup id="inputTrade_Qty4" defaultValue={Qty4Value} value={Qty4Value} onChange={utils.handleStringChange(n => setQty4Value(n))}  />
                    {errors.d_Trade_Qty4 && errors.d_Trade_Qty4.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty4 && errors.d_Trade_Qty4.types && <span id="Err_msg">{errors.d_Trade_Qty4.types.message}</span>}
                </FormGroup>
            </div> }
            <div className="Row">
                <FormGroup className="Col-1-1" label="T. Deed" labelFor="inputT_Deed">
                    <InputGroup disabled={TranTypeValue === "B"} className="inputNumberNoSpin" id="inputT_Deed" name="d_T_Deed" defaultValue="0" type="number" inputRef={register({min: 0})} />
                    {errors.d_T_Deed && errors.d_T_Deed.type === "min" && <span id="Err_msg">T. Deed sholud be greater than 0 </span>}
                    {errors.d_T_Deed && errors.d_T_Deed.types && <span id="Err_msg">{errors.d_T_Deed.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Interest Accrual" labelFor="inputInterest_Accrual">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputInterest_Accrual" defaultValue="0" value="0" type="number" buttonPosition="none" asyncControl={true} inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputInterest_Accrual" name="d_Interest_Accrual" defaultValue="0" type="number" inputRef={register({min: 0})} />
                    {errors.d_Interest_Accrual && errors.d_Interest_Accrual.type === "min" && <span id="Err_msg">Interest Accrual sholud be greater than 0 </span>}
                    {errors.d_Interest_Accrual && errors.d_Interest_Accrual.types && <span id="Err_msg">{errors.d_Interest_Accrual.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label="Init by Broker" labelFor="inputInit_by_Broker">
                    <InputGroup id="inputInit_by_Broker" name="d_Init_by_Broker" defaultValue="" inputRef={register({ maxLength: 1 }) } />
                    {errors.d_Init_by_Broker && errors.d_Init_by_Broker.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Init_by_Broker && errors.d_Init_by_Broker.types && <span id="Err_msg">{errors.d_Init_by_Broker.types.message}</span>}
                </FormGroup>
            </div>
            { GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) && GsbFlag.SAS_MKT &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Settle Date (Stock)" labelFor="inputS_settle_date">
                        <InputGroup id="inputS_settle_date" name="d_S_settle_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                        {errors.d_S_settle_date && errors.d_S_settle_date.types && <span id="Err_msg">{errors.d_S_settle_date.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Settle Date (Momey)" labelFor="inputM_settle_date">
                        <InputGroup id="inputM_settle_date" name="d_M_settle_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                        {errors.d_M_settle_date && errors.d_M_settle_date.types && <span id="Err_msg">{errors.d_M_settle_date.types.message}</span>}
                    </FormGroup>
                </div>
            }
            { GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) && ! GsbFlag.SAS_MKT &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Settle Date" labelFor="inputMS_settle_date">
                        <InputGroup id="inputMS_settle_date" name="d_MS_settle_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                        {errors.d_MS_settle_date && errors.d_MS_settle_date.types && <span id="Err_msg">{errors.d_MS_settle_date.types.message}</span>}
                    </FormGroup>
                </div>
            }
            { (( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) ) || (GsbFlag.TBOB_USER && AcCodeValue.substr(0,1) ==="B" ) ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Commission" labelFor="inputCommission">
                            <InputGroup className="inputNumberNoSpin" id="inputCommission" name="d_Commission" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Commission && errors.d_Commission.type === "min" && <span id="Err_msg">Commission sholud be greater than 0 </span>}
                            {errors.d_Commission && errors.d_Commission.types && <span id="Err_msg">{errors.d_Commission.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Stamp Fee" labelFor="inputStamp">
                            <InputGroup className="inputNumberNoSpin" id="inputStamp" name="d_Stamp" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Stamp && errors.d_Stamp.type === "min" && <span id="Err_msg">Stamp Fee sholud be greater than 0 </span>}
                            {errors.d_Stamp && errors.d_Stamp.types && <span id="Err_msg">{errors.d_Stamp.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-4-1" label="Levy" labelFor="inputLevy">
                            <InputGroup className="inputNumberNoSpin" id="inputLevy" name="d_Levy" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Levy && errors.d_Levy.type === "min" && <span id="Err_msg">Levy sholud be greater than 0 </span>}
                            {errors.d_Levy && errors.d_Levy.types && <span id="Err_msg">{errors.d_Levy.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="AFRC" labelFor="inputFRC">
                            <InputGroup className="inputNumberNoSpin" id="inputFRC" name="d_FRC" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_FRC && errors.d_FRC.type === "min" && <span id="Err_msg">AFRC sholud be greater than 0 </span>}
                            {errors.d_FRC && errors.d_FRC.types && <span id="Err_msg">{errors.d_FRC.types.message}</span>}
                    </FormGroup>
                </div>
            }
            { (( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) ) || (GsbFlag.TBOB_USER && AcCodeValue.substr(0,1) ==="B" ) ) &&
            <div className="Row">
                <FormGroup className="Col-1-2" label="VAT/Trading Fee" labelFor="inputTrading_Fee">
                        <InputGroup className="inputNumberNoSpin" id="inputTrading_Fee" name="d_Trading_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                        {errors.d_Trading_Fee && errors.d_Trading_Fee.type === "min" && <span id="Err_msg">VAT/Trading Fee sholud be greater than 0 </span>}
                        {errors.d_Trading_Fee && errors.d_Trading_Fee.types && <span id="Err_msg">{errors.d_Trading_Fee.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Clearing Fee" labelFor="inputCCASS_Fee">
                        <InputGroup className="inputNumberNoSpin" id="inputCCASS_Fee" name="d_CCASS_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                        {errors.d_CCASS_Fee && errors.d_CCASS_Fee.type === "min" && <span id="Err_msg">Clearing Fee sholud be greater than 0 </span>}
                        {errors.d_CCASS_Fee && errors.d_CCASS_Fee.types && <span id="Err_msg">{errors.d_CCASS_Fee.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Transfer Fee" labelFor="inputTransfer_Fee">
                        <InputGroup className="inputNumberNoSpin" id="inputTransfer_Fee" name="d_Transfer_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                        {errors.d_Transfer_Fee && errors.d_Transfer_Fee.type === "min" && <span id="Err_msg">Transfer Fee sholud be greater than 0 </span>}
                        {errors.d_Transfer_Fee && errors.d_Transfer_Fee.types && <span id="Err_msg">{errors.d_Transfer_Fee.types.message}</span>}
                </FormGroup>
            </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-4"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Handling_Fee" labelFor="inputSAS_Handling_Fee">
                            <InputGroup readOnly className="inputNumberNoSpin" id="inputSAS_Handling_Fee" name="d_SAS_Handling_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_SAS_Handling_Fee && errors.d_SAS_Handling_Fee.type === "min" && <span id="Err_msg">Handling Fee sholud be greater than 0 </span>}
                            {errors.d_SAS_Handling_Fee && errors.d_SAS_Handling_Fee.types && <span id="Err_msg">{errors.d_SAS_Handling_Fee.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Management Fee" labelFor="inputSAS_Management_Fee">
                            <InputGroup readOnly className="inputNumberNoSpin" id="inputSAS_Management_Fee" name="d_SAS_Management_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_SAS_Management_Fee && errors.d_SAS_Management_Fee.type === "min" && <span id="Err_msg">Management Fee sholud be greater than 0 </span>}
                            {errors.d_SAS_Management_Fee && errors.d_SAS_Management_Fee.types && <span id="Err_msg">{errors.d_SAS_Management_Fee.types.message}</span>}
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.MULTI_MKT &&  ! utils.IsHkMarket(MarketValue) && GsbFlag.SAS_MKT) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Transfer Fee" labelFor="inputTransfer_Fee">
                            <InputGroup readOnly className="inputNumberNoSpin" id="inputTrading_Fee" name="d_SAS_Transfer_Fee" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_SAS_Transfer_Fee && errors.d_SAS_Transfer_Fee.type === "min" && <span id="Err_msg">Transfer Fee sholud be greater than 0 </span>}
                            {errors.d_SAS_Transfer_Fee && errors.d_SAS_Transfer_Fee.types && <span id="Err_msg">{errors.d_SAS_Transfer_Fee.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Transfer Fee HK" labelFor="inputSAS_Transfer_Fee_HK">
                            <InputGroup readOnly className="inputNumberNoSpin" id="inputSAS_Transfer_Fee_HK" name="d_SAS_Transfer_Fee_HK" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_SAS_Transfer_Fee_HK && errors.d_SAS_Transfer_Fee_HK.type === "min" && <span id="Err_msg">Transfer Fee HK sholud be greater than 0 </span>}
                            {errors.d_SAS_Transfer_Fee_HK && errors.d_SAS_Transfer_Fee_HK.types && <span id="Err_msg">{errors.d_SAS_Transfer_Fee_HK.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Stamp Duty (For Seller)" labelFor="inputSAS_Stamp_Duty">
                            <InputGroup readOnly className="inputNumberNoSpin" id="inputSAS_Stamp_Duty" name="d_SAS_Stamp_Duty" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_SAS_Stamp_Duty && errors.d_SAS_Stamp_Duty.type === "min" && <span id="Err_msg">Stamp Duty sholud be greater than 0 </span>}
                            {errors.d_SAS_Stamp_Duty && errors.d_SAS_Stamp_Duty.types && <span id="Err_msg">{errors.d_SAS_Stamp_Duty.types.message}</span>}
                    </FormGroup>
                </div>
            }
        </form>
    )
}

interface ClientTranEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClientTranEntryMain({ gsb }: ClientTranEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as ClientTranInfo[])
    const [ChnlList, setChnlList] = useState([] as ChnlInfo[])
    const [ChnlList2, setChnlList2] = useState([] as ChnlInfo[])

    const [searchKey, setSearchKey] = useState("SeqNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchAcName, setsearchAcName] = useState("")
    const [searchStkNo, setsearchStkNo] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentClientTran, setcurrentClientTran] = useState({} as ClientTranInfo)
    const [BlankClientTran, setBlankClientTran] = useState({} as ClientTranInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    const [showChannel, setshowChannel] = useState(false)

    //gsb default value
    //BlankClientTran.d_Currency = "HKD"   //no work
    BlankClientTran.d_acCode = ""
    BlankClientTran.d_Tran_type = "B"
    BlankClientTran.d_StkNo = ""
    BlankClientTran.d_Net_Amount = 0
    BlankClientTran.d_Trade_Qty1 =0
    BlankClientTran.d_Trade_Qty2 = 0
    BlankClientTran.d_Trade_Qty3 = 0
    BlankClientTran.d_Trade_Qty4 = 0
    BlankClientTran.d_Trade_Price1 = 0
    BlankClientTran.d_Trade_Price2 = 0
    BlankClientTran.d_Trade_Price3 = 0
    BlankClientTran.d_Trade_Price4 = 0
    BlankClientTran.d_CommRate = 0
    BlankClientTran.d_Commission = 0
    BlankClientTran.d_Stamp = 0
    BlankClientTran.d_Levy = 0
    BlankClientTran.d_Trading_Fee = 0
    BlankClientTran.d_FRC = 0
    BlankClientTran.d_CCASS_Fee = 0
    BlankClientTran.d_Transfer_Fee = 0
    BlankClientTran.d_SAS_Handling_Fee = 0
    BlankClientTran.d_SAS_Management_Fee = 0
    BlankClientTran.d_SAS_Transfer_Fee = 0
    BlankClientTran.d_SAS_Transfer_Fee_HK = 0
    BlankClientTran.d_SAS_Stamp_Duty = 0
    BlankClientTran.d_Skip_Recal = "N"
    BlankClientTran.d_Aggr_transaction_HKIDR = "N"
    BlankClientTran.d_Channel = " "

    //console.log("ClientTranEntryMain url: ", url)
    //console.log("ClientTranEntryMain qString: ", qString)
    //console.log("ClientTranEntryMain logKey: ", logKey)
    //console.log("ClientTranEntryMain logKey2: ", logKey2)
    //console.log("ClientTranEntryMain logviewonly: ", logviewonly)

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClientTranEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClientTranEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClientTranEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClientTranEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("ClientTranEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClientTranEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("ClientTranEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClientTranEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClientTranEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 
                setshowChannel( GsbFlag.MULTI_CHNL ) ;

                ChnlList2.push({d_ChnlCode: " ", d_ChnlDesc: "Normal"})
                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.CHN_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpChnlName = "Normal"
                        tmpChnlName = GsbFlag.CHN_DESC[i]

                        ChnlList2.push({d_ChnlCode: GsbFlag.CHN_STATUS.substr(i, 1), d_ChnlDesc: tmpChnlName})
                    }
                }
                setChnlList(ChnlList2)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClientTranEntryMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])



    const PrevRecProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call PrevRecProcess", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "ClientTranEntry", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_Seq)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentClientTran(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "ClientTranEntry", cAction: "DelRec"})
            showSuccess("Seq No. " + ClientTran.d_Seq+ClientTran.d_SeqC + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call NextRecProcess", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "ClientTranEntry", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call newOnSave", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "ClientTranEntry", cAction: "AddRec"})
            //showSuccess("Seq No. " + ClientTran.d_Seq + " record added")
            showSuccess("Seq No. " + res.data.data.d_Seq+ClientTran.d_SeqC + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call editOnSave", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "ClientTranEntry", cAction: "UpdateRec"})
            showSuccess("Seq No. " + ClientTran.d_Seq+ClientTran.d_SeqC + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    //console.log("editOnSave data", i, data)
                    setData(data)
                    //setCurrentStk(stk)
                    break
                }
            }
            //console.log("editOnSave stk", stk)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get ClientTranEntry list if StkNo have value
        try {
            //console.log("ClientTranEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            AcName: searchAcName,
                            StkNo: searchStkNo,
                        }, 
                    },
                    cPgmId: "ClientTranEntry",
                    cAction: "ListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentClientTran(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        d_Seq: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "ClientTranEntry",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentClientTran(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one ClientTranEntry record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("ClientTranEntryMain useEffect length > 0  called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "ClientTranEntry",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentClientTran(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                AcName: searchAcName,
                                StkNo: searchStkNo,
                            }, 
                        },
                        cPgmId: "ClientTranEntry",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentClientTran(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <ClientTranEntryView gsb={gsb} ClientTran={currentClientTran} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentClientTran)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentClientTran)} onNextRec={() => NextRecProcess(currentClientTran)} PgmMode="Normal" ></ClientTranEntryView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <ClientTranEntryEdit gsb={gsb} ClientTran={BlankClientTran} ChnlList={ChnlList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="Normal"></ClientTranEntryEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <ClientTranEntryEdit gsb={gsb} ClientTran={currentClientTran} ChnlList={ChnlList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="Normal"></ClientTranEntryEdit>
            break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-SeqNo">Seq No</th>
                <th className="TCol-TranType">Tran Type</th>
                {( showChannel ) &&
                    <th className="TCol-Channel">Channel</th>
                }
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-CcassStk">CCASS</th>
                <th className="TCol-StampFlag">Stamp</th>
                <th className="TCol-Qty1">Qty 1</th>
                <th className="TCol-Price1">Price 1</th>
                <th className="TCol-NetAmount">Net Amount</th>
                <th className="TCol-Comm">Comm Code</th>
                <th className="TCol-Action">Action</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ClientTran, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-SeqNo" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Seq}</td>
                        <td className="TCol-TranType" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Tran_type}</td>
                        {( showChannel ) &&
                            <td className="TCol-Channel" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Channel}</td>
                        }
                        <td className="TCol-ACCode" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_acName1}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_StkCode}</td>
                        <td className="TCol-CcassStk" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_CcassStk}</td>
                        <td className="TCol-StampFlag" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_StampFlag}</td>
                        <td className="TCol-Qty1" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Trade_Qty1}</td>
                        <td className="TCol-Price1" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Trade_Price1}</td>
                        <td className="TCol-NetAmount" onClick={() => { setcurrentClientTran(ClientTran) }}>{utils.formatNumber2(ClientTran.d_Net_Amount)}</td>
                        <td className="TCol-Comm" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_CommCode}</td>
                        <td className="TCol-Action" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Action}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Client Transaction Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Client Transaction " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="SeqNo">Seq No</option>
                                    {/* <option value="acCode">Account No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Account Name" labelFor="searchAcName">
                                <InputGroup id="searchAcName" value={searchAcName} onChange={utils.handleStringChange(s => setsearchAcName(s))} />
                            </FormGroup>
                            <FormGroup className="Col-3-1" label="Stock No." labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setsearchStkNo(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ClientTranEntryMain