import React, { useState, FormEvent, useEffect, WheelEventHandler } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import {  HkidrInfo,CountryINFO,CCEPINFO} from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';
import { access } from 'fs';


interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;

interface HkidrMastViewProps {
    gsb: AxiosInstance,
    Bcan: HkidrInfo,
    Bcan0: HkidrInfo,
    CountryList?: CountryINFO[],
    CCEPList? :CCEPINFO[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onReplicate?: () => void,
    onRegGenBcan?:()=>void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
    PgmMode: any,    
}



function HkidrMastView({ gsb, Bcan = {} as HkidrInfo, Bcan0 = {} as HkidrInfo, CountryList = [] as CountryINFO[] ,CCEPList =[] as CCEPINFO[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onReplicate = () => { }, onRegGenBcan=()=>{ }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2, PgmMode }: HkidrMastViewProps) {

    const [isReplicate, setisReplicate] = useState(false)
    const [isRegenbcan, setisRegenbcan] = useState(false)
    const [CountryIssuanceDesc, setCountryIssuanceDesc] = useState("")   
    const [ExeCCEPFirmNameDesc,setExeCCEPFirmNameDesc] = useState("")


    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")


    const [userKey, setuserKey] = useState("DEMO")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)


    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [showChiAddressMore, setshowChiAddressMore] = useState(true)
    const [BCAN_Allow_Change, setBCAN_Allow_Change] = useState(false)
    const [Allow_SMPID, setAllow_SMPID] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                setSUSPEND_AC(GsbFlag.SUSPEND_AC)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

 
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkidrMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Bcan])


    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: Bcan.d_acCode})
                
                if ( Bcan.d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, Bcan.d_acCode])





    useEffect(() => {
        (async () => {
            try {
                // get BcanConfig Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "BcanConfig", cAction: "GetBcanConfigInfo", cAcCode: ""})
                
                if (  res.data.count > 0 )
                {
                    setBCAN_Allow_Change(res.data.data[0].BCAN_Allow_Change=="Y")
                    setAllow_SMPID(res.data.data[0].SMPID_OK=="Y")
                    
                } else { 
                    setBCAN_Allow_Change(false)
                    setAllow_SMPID(false)
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb,  Bcan.d_acCode])














    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    useEffect(() => {
        setCountryIssuanceDesc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: CountryINFO) => CountryItem.CountryCode === Bcan.d_Country_Issuance)
        console.log("ClMastView CountryIssuanceDesc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView CountryIssuanceDesc.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList2[CountryItem3].CountryCode) 
             setCountryIssuanceDesc(CountryList[CountryItem3].Country)
        }

        console.log("ClMastView CountryIssuanceDesc.find CountryIssuanceDesc : ", CountryIssuanceDesc) 
    }, [Bcan.d_acCode, Bcan.d_Country_Issuance])


    useEffect(() => {
        setExeCCEPFirmNameDesc("")
       
        let CountryItem3 = CCEPList.findIndex((CCEPITEM: CCEPINFO) => CCEPITEM.ParticipantID === Bcan.d_ExeCCEP_FirmID)
        console.log("ClMastView ExeCCEPFirmNameDesc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView ExeCCEPFirmNameDesc.find CountryItem3.d_Country : ", CCEPList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            console.log("CCEPList .find RegionItem3.d_Country2 : ", CCEPList[CountryItem3].ParticipantName) 
            setExeCCEPFirmNameDesc(CCEPList[CountryItem3].ParticipantName)
        }

 
    }, [Bcan, Bcan.d_ExeCCEP_FirmID])






    if (! ("d_acCode" in Bcan)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>


    const CENumToolTip = 
    <div>
        <div>CE Number. </div>     
        <div>Default Blank use system default</div> 
        <div>For example ABC123</div> 
    </div>


    return (
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="add" onClick={() => setisReplicate(true) } >Replicate Holder No</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Replicate"
                        canEscapeKeyCancel={true}
                        icon="duplicate"
                        intent={Intent.WARNING}
                        isOpen={isReplicate}
                        onCancel={ () => setisReplicate(false) }
                        onConfirm={ () => { onReplicate(); setisReplicate(false) } }
                    >
                        <p>
                            Do you Replicate NEW Account Holder No. ?                      ?
                        </p>
                    </Alert>
                {/*<Button className="Col-4-1" icon="airplane" onClick={() => setisRegenbcan(true) } >Regen HKBcan No</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="ReGenBcan"
                        canEscapeKeyCancel={true}
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isRegenbcan}
                        onCancel={ () => setisRegenbcan(false) }
                        onConfirm={ () => { onRegGenBcan(); setisRegenbcan(false) } }
                    >
                        <p>
                            Do you regen HKBcan No.                ?                      ?
                        </p>
                </Alert>      */}          
                <Button className="Col-4-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-5-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-6-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-7-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
{/*
                
                <FormGroup className="Col-7-2" label="">
                    <a target="_blank" rel="" href={`/#/ClientPosEnquiry?acCode=${ac.d_acCode}`}>
                    <Button className="Col-7-2" icon="document" onClick={() => {} } >Balance & Portfolio</Button>
                    </a>
                    <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${ac.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
                </FormGroup>
*/}                
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {Bcan.d_acCode}
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
        
            <div className="Row" >
                <FormGroup className="Col3-1-2" label="Client Type" labelFor="inputClientType">                     
                     <HTMLSelect  id="input_IdType" name="d_ClientType" value={Bcan.d_ClientType} disabled  >
               
                                <option value="">{t('Please select')}</option>
                                <option value="1">{t('Individual')}</option>
                                <option value="2">{t('Joint Account')}</option>
                                <option value="3">{t('Funds')}</option>
                                <option value="4">{t('Legal Entity - Fund managers')}</option>
                                <option value="5">{t('Proprietary Trading')}</option>
                                <option value="6">{t('Legal entity - Others')}</option>                
                         
                            </HTMLSelect>
                </FormGroup>
            </div> 

            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={Bcan.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Account Holder No." labelFor="inputHolderNo" labelInfo="*">
                    <InputGroup readOnly value={Bcan.d_acHolderNo} />
                </FormGroup>
                { SUSPEND_AC && <FormGroup className="Col-5-1" label="Status" labelFor="inputStatus">
                    <div>
                        <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup> }
                <FormGroup className="Col-6-3" label="Name in GSB" labelFor="inputName">
                    <div>
                        <div id="DispText">{AcName1Value}</div>
                        <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
            </div>
            {/* when clienttype 1,2 */}
            { (Bcan.d_ClientType === "1" || Bcan.d_ClientType === "2" || Bcan.d_ClientType  === "" ) && <div className="Row">
   
                <FormGroup className="Col-1-3" label="English First and Middle Name" labelFor="inputEnglish_First_Middle_Name">
                    <InputGroup readOnly value={Bcan.d_English_First_Middle_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_English_First_Middle_Name.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_English_First_Middle_Name} </div> }
                </FormGroup>
                <FormGroup className="Col-4-2" label="English Last Name" labelFor="inputEnglish_Last_Name">
                    <InputGroup readOnly value={Bcan.d_English_Last_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_English_Last_Name.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_English_Last_Name} </div> }
                </FormGroup>
            </div> }
            { (Bcan.d_ClientType === "1" || Bcan.d_ClientType === "2"  || Bcan.d_ClientType  === "") && <div className="Row">
                <FormGroup className="Col-1-2" label="Chinese Name" labelFor="inputChinese_Name">
                    <InputGroup readOnly value={Bcan.d_Chinese_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_Chinese_Name.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_Chinese_Name} </div> }
                </FormGroup>
            </div> }
      

            {/* when clienttype 3,4,5,6  */}

            { (Bcan.d_ClientType === "3" || Bcan.d_ClientType === "4" || Bcan.d_ClientType === "5" || Bcan.d_ClientType === "6" ) && <div className="Row">
    
                <FormGroup className="Col-1-4" label="English Entity Name" labelFor="inputEnglish_Legal_Entity_Name">
                    <InputGroup readOnly value={Bcan.d_English_Legal_Entity_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_English_Legal_Entity_Name.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_English_Legal_Entity_Name} </div> }
                </FormGroup>
            </div> }
            { (Bcan.d_ClientType === "3" || Bcan.d_ClientType === "4" || Bcan.d_ClientType === "5" || Bcan.d_ClientType === "6" )  && <div className="Row">
                <FormGroup className="Col-1-4" label="Chinese Entity Name" labelFor="inputChinese_Entity_Name">
                    <InputGroup readOnly value={Bcan.d_Chinese_Entity_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_Chinese_Entity_Name.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_Chinese_Entity_Name} </div> }
                </FormGroup>
           </div> }



           {(GsbFlag.SYNC_TEXT)  
            ? <div className="Row">     
                <FormGroup className="Col-1-2" label="BCAN No.(HKIDR)">
                    <InputGroup readOnly value={Bcan.d_BCAN} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_BCAN.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_BCAN} </div> }
                </FormGroup>     


                <FormGroup className="Col-3-2" label="CE Number(HKIDR)">
                    <InputGroup readOnly value={Bcan.d_CE_Number} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_CE_Number.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_CE_Number} </div> }
                </FormGroup>        

                {Allow_SMPID &&   <FormGroup className="Col-1-2" label="SMPID">
                    <InputGroup readOnly value={Bcan.d_SMPID} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_SMPID.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_SMPID} </div> }
                </FormGroup>
                }

                <FormGroup className="Col-1-2" label="BCAN No.(CNBCAN)">
                    <InputGroup readOnly value={Bcan.d_CNBCAN} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_CNBCAN.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_CNBCAN} </div> }
                </FormGroup>



                <FormGroup className="Col-3-3" label="Execute CCEP FirmID.(CNBCAN)">
                    <InputGroup readOnly value={Bcan.d_ExeCCEP_FirmID} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ExeCCEP_FirmID.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ExeCCEP_FirmID} </div> }                  
                    <div id="DispText">{ExeCCEPFirmNameDesc}</div>
                </FormGroup>

            </div>
            :<div className="Row"> 
                <FormGroup className="Col-1-2" label="BCAN No.(HKIDR)">
                    <InputGroup readOnly value={Bcan.d_BCAN} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_BCAN.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_BCAN} </div> }
                </FormGroup> 

                <FormGroup className="Col-1-2" label="SMPID">
                    <InputGroup readOnly value={Bcan.d_SMPID} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_SMPID.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_SMPID} </div> }
                </FormGroup>

                <FormGroup className="Col-1-2" label="BCAN No.(CNBCAN)">
                    <InputGroup readOnly value={Bcan.d_CNBCAN} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_CNBCAN.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_CNBCAN} </div> }
                </FormGroup>
              
                <FormGroup className="Col-3-2" label="Execute CCEP FirmID.(CNBCAN)">
                    <InputGroup readOnly value={Bcan.d_ExeCCEP_FirmID} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ExeCCEP_FirmID.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ExeCCEP_FirmID} </div> } 
                    <div id="DispText">{ExeCCEPFirmNameDesc}</div>
                </FormGroup>
                
            </div>
            }

           
         
            <div className="Row">
   
            </div>
            <div className="Row">
                 <FormGroup className="Col3-1-2" label="ID TYPE" labelFor="input_ID_TYPE">  
                    <HTMLSelect  id="input_IdType" name="d_ID_Type" value={Bcan.d_ID_Type}  disabled >    
					{/*
					   hkidr-> CNBCAN  /SAVE TO CN ID TYPE SAME TIME WHEN EDIT
						1 -> 1 Identity Card
						2 -> 5 = Other official identity document (only for Client Types 1 and 2)
						3 -> 2 = Passport
						4 -> 4 = LEI
						5 -> 3 = Certificate of Incorporation / other official incorporation documents
						6 -> 3 = Certificate of Incorporation / other official incorporation documents
					
					*/}

                        <option value="">{t('Please select')}</option>
                        <option value="1">{t('HKID')}</option>
                        <option value="2">{t('National Identification Document')}</option>
                        <option value="3">{t('Passport')}</option>
                        <option value="4">{t('LEI')}</option>
                        <option value="5">{t('Certificate of Incorporation')}</option>
                        <option value="6">{t('Business Registration Certificate')}</option>
                        <option value="7">{t('Other official incorporation documents')}</option>
                    </HTMLSelect>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ID_Type.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ID_Type} </div> }
                </FormGroup>
            </div>   
            <div className="Row">

                <FormGroup className="Col3-1-1" label="Country Issuance">
                    <InputGroup readOnly value={Bcan.d_Country_Issuance} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_Country_Issuance.toString() == "") && 
                    <div id="DispText2" >{Bcan0.d_Country_Issuance}</div> }
                    <div id="DispText">{CountryIssuanceDesc}</div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="ID No.">
                    <InputGroup readOnly value={Bcan.d_ID_Number} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ID_Number.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ID_Number} </div> }
                </FormGroup>  
                <div className="Spacing-V-16" />
            </div>
       
            {! showChiAddressMore && (GsbFlag.GSBx_USER ===true) &&<div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChiAddressMore(! showChiAddressMore) }} >+ Show Chinese Address </div> }
            { showChiAddressMore  && (GsbFlag.GSBx_USER ===true) && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChiAddressMore(! showChiAddressMore) }} >- Chinese Address </div> }
   
   
            {showChiAddressMore  && (GsbFlag.GSBx_USER ===true) && <div className="Row">
                <FormGroup className="Col-1-4" label="Chinese Address">
                    <InputGroup readOnly value={Bcan.d_ChineseAddress1} />
                    <InputGroup readOnly value={Bcan.d_ChineseAddress2} />
                    <InputGroup readOnly value={Bcan.d_ChineseAddress3} />
                    <InputGroup readOnly value={Bcan.d_ChineseAddress4} />               
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ChineseAddress1.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ChineseAddress1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ChineseAddress2.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ChineseAddress2} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ChineseAddress3.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ID_Number} </div> }                    
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_ChineseAddress4.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_ChineseAddress4} </div> }
                </FormGroup> 

                 {/* Select Chinese Name or Address on Statement (Y/N) */}
                <FormGroup className="Col-1-2" label="Select Chinese Name or Address on Statement (Y/N)">
                <RadioGroup
                        label="" 
                        name="d_StmtChiAdd"
                        disabled={true}
                        inline={true}
                        selectedValue={Bcan.d_StmtChiAdd}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_StmtChiAdd.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_StmtChiAdd} </div> }
                </FormGroup>
            </div>}

            <div className="Row">
                <FormGroup className="Col-1-2" label="HKConsent">
                <RadioGroup
                        label="" 
                        name="d_Consent_Agree"
                        disabled={true}
                        inline={true}
                        selectedValue={Bcan.d_Consent_Agree}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_Consent_Agree.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_Consent_Agree} </div> }
                </FormGroup>
               
                <FormGroup className="Col-3-4" label="CNConsent">
                <RadioGroup
                        label="" 
                        name="d_CN_Consent_Agree"
                        disabled={true}
                        inline={true}
                        selectedValue={Bcan.d_CN_Consent_Agree}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_CN_Consent_Agree.toString() == "") && 
                    <div id="DispText2" > {Bcan0.d_CN_Consent_Agree} </div> }
                </FormGroup>
                
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('HK Consent_Date')} labelFor="inputConsent_Date">
                    <InputGroup readOnly value= {utils.dispDate(Bcan.d_Consent_Date, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_Consent_Date.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Bcan0.d_Consent_Date, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                
                <FormGroup className="Col-3-2" label={t('CN Consent_Date')} labelFor="inputCNConsent_Date">
                    <InputGroup readOnly value= {utils.dispDate(Bcan.d_CN_Consent_Date, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Bcan0.d_CN_Consent_Date.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Bcan0.d_CN_Consent_Date, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                
            </div>


        </div>
    )
}

interface HkidrMastEditProps {
    gsb: AxiosInstance,
    Bcan?: HkidrInfo,
    CountryList?: CountryINFO[],
    CCEPList?:CCEPINFO[],
    isNew?: boolean,
    onSave?: (Bcan: HkidrInfo) => void,
    onCancel?: () => void;
    PgmMode: any,    
}


// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function HkidrMastEdit({ gsb, Bcan = {} as HkidrInfo, CountryList = [] as CountryINFO[], CCEPList = [] as CCEPINFO[] ,isNew = false, onSave = () => { }, onCancel = () => { }, PgmMode }: HkidrMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<HkidrInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [CountryIssuanceDesc, setCountryIssuanceDesc] = useState("")
    const [CountryIssuance, setCountryIssuance] = useState("")
   

  
    const [ExeCCEPFirmNameDesc, setExeCCEPFirmNameDesc] = useState("")
    const [ExeCCEPFirmName, setExeCCEPFirmName] = useState("")

    const [ParticipantNameDesc,setParticipantNameDesc] = useState("")


    const [userKey, setuserKey] = useState("DEMO")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                setSUSPEND_AC(GsbFlag.SUSPEND_AC)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Bcan])




    useEffect(() => {
        setCountryIssuanceDesc("")
        setCountryIssuance("")
        let CountryItem3 = CountryList.findIndex((CountryItem: CountryINFO) => CountryItem.CountryCode === Bcan.d_Country_Issuance)
        console.log("ClMastView CounrtyIssuance.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView CounrtyIssuance.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList2[CountryItem3].CountryCode) 
            setCountryIssuanceDesc(CountryList[CountryItem3].Country)
            setCountryIssuance(CountryList[CountryItem3].CountryCode)
        }

        console.log("ClMastView JursidictionCountry1Desc.find CountryIssuanceDesc : ", CountryIssuanceDesc) 
    }, [Bcan, Bcan.d_Country_Issuance])


    useEffect(()=>{
        setExeCCEPFirmNameDesc("")
        setExeCCEPFirmName("")
        let CCepItem3 = CCEPList.findIndex((CCepItem: CCEPINFO) => CCepItem.ParticipantID === Bcan.d_ExeCCEP_FirmID)
        if ( CCepItem3 == 0 || CCepItem3 > 0) {
            setExeCCEPFirmNameDesc(CCEPList[CCepItem3].ParticipantName)
        }    
    },[Bcan,Bcan.d_ExeCCEP_FirmID])

    const [AcCodeValue, setAcCodeValue] = useState(Bcan.d_acCode)
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")
    const [BCAN_Allow_Change, setBCAN_Allow_Change] = useState(false)
    
    const [Allow_SMPID,setAllow_SMPID] = useState(false)

    const [def_d_ID_Type, setdef_d_ID_Type] = useState(Bcan.d_ID_Type)
    const [def_d_ClientType, setdef_d_ClientType] = useState(Bcan.d_ClientType)
    const [def_d_Consent_Agree, setdef_d_Consent_Agree] = useState(Bcan.d_Consent_Agree)
    const [def_d_CNConsent_Agree, setdef_d_CNConsent_Agree] = useState(Bcan.d_CN_Consent_Agree)
 
    const [def_d_SMPID, setdef_d_SMPID] = useState(Bcan.d_SMPID)
   
    const [def_d_StmtChiAdd, setdef_d_StmtChiAdd] = useState(Bcan.d_StmtChiAdd) // Select Chinese Name or Address on Statement (Y/N)    
 
    const [showChiAddressMore, setshowChiAddressMore] = useState(true)

    const [def_d_JurisdictionofResidence3, setdef_d_JurisdictionofResidence3] = useState("")
 
    const [searchKey, setSearchKey] = useState("acCode")


    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
    </div>

    const CCEPToolTip = 
    <div>
        <div>Execut CCEP Firm ID.</div>
        <div>When blank, system will use default  </div>  
    </div>

    const CENUMToolTip = 
    <div>
       <div>CE Number.</div>
       <div>When blank, system will use default  </div>  
    </div>
  

   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in Bcan) {
            reset(Bcan)
        }
    }, [Bcan, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_Country_Issuance" })    
        //register({ name: "d_status" })
        register({ name: "d_ExeCCEP_FirmID" })    
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    useEffect(() => {
        (async () => {
            try {
                // get BcanConfig Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "BcanConfig", cAction: "GetBcanConfigInfo", cAcCode: ""})
                
                if (  res.data.count > 0 )
                {
                    setBCAN_Allow_Change(res.data.data[0].BCAN_Allow_Change=="Y")
                    setAllow_SMPID(res.data.data[0].SMPID_OK=="Y")
                    
                } else { 
                    setBCAN_Allow_Change(false)
                    setAllow_SMPID(false)
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    if (!isNew && !("d_acCode" in Bcan)) return null

    let idNoLength = 40
//    if ( GsbFlag.user_key === "TATLEE" ) {
//        idNoLength = 22
//    }



    const onSubmit = (data: HkidrInfo) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();

        if ( typeof(data.d_CE_Number) === "undefined" ) {
            data.d_CE_Number = ""
        }
        
        data.d_SMPID = def_d_SMPID; 
       
        data.d_CE_Number = data.d_CE_Number.toUpperCase();
    
        data.d_ClientType =def_d_ClientType ;        
        data.d_ID_Type =def_d_ID_Type ; //def_d_ID_Type;
        data.d_Consent_Agree = def_d_Consent_Agree;
        data.d_CN_Consent_Agree = def_d_CNConsent_Agree;
        
        data.d_StmtChiAdd = def_d_StmtChiAdd;
        
        if ( typeof(data.d_English_First_Middle_Name) === "undefined" ) {
            data.d_English_First_Middle_Name = ""
        }
        if ( typeof(data.d_English_Last_Name) === "undefined" ) {
            data.d_English_Last_Name = ""
        }
        if ( typeof(data.d_Chinese_Name) === "undefined" ) {
            data.d_Chinese_Name = ""
        }

        if ( typeof(data.d_English_Legal_Entity_Name) === "undefined" ) {
            data.d_English_Legal_Entity_Name = ""
        }
        if ( typeof(data.d_Chinese_Entity_Name) === "undefined" ) {
            data.d_Chinese_Entity_Name = ""
        }


        if ( typeof(data.d_ExeCCEP_FirmID) === "undefined" ) {
            data.d_ExeCCEP_FirmID = ""
        }

        if ( typeof(data.d_CNBCAN) === "undefined" ) {
            data.d_CNBCAN = ""
        }

        if ( typeof(data.d_CN_Consent_Date) === "undefined" ) {
            data.d_CN_Consent_Agree = "N"
        }
        if ( typeof(data.d_StmtChiAdd) === "undefined" ) {
            data.d_StmtChiAdd = "N"
        }



        if ( typeof(data.d_CN_Consent_Date) === "undefined" ) {
            data.d_CN_Consent_Date = "  -  -    "
        }


 
        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call HkidrMastedit data", data)
                //console.log("call HkidrMastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "HkidrMast", cAction: "HkidrValidate", isNew: isNew})
                // console.log("HkidrMastedit res.data.ok", res.data.ok)
                //console.log("HkidrMastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                //showErrorBox(error)
                utils.showError(error)
            }
        })()


    }

    //CountryINFO
    function setCountry1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setCountryIssuanceDesc("")
        setCountryIssuance("")
        let CountryItem = CountryList.findIndex((CountryItem: CountryINFO) => CountryItem.CountryCode === text)
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem : ", CountryItem) 
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setCountryIssuanceDesc(CountryList[CountryItem].Country)
            setCountryIssuance(CountryList[CountryItem].CountryCode)
        }

        return setCountry1;
    }

 
      //ExeCCEPFirmNameDesc
      function setExeCCEP(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");


        setExeCCEPFirmNameDesc("")
        let CCEPItem = CCEPList.findIndex((CCEP: CCEPINFO) => CCEP.ParticipantID === text)
        if ( CCEPItem == 0 || CCEPItem > 0) {
            setExeCCEPFirmNameDesc(CCEPList[CCEPItem].ParticipantName)
        }

        return setExeCCEP;
    }




    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }



    const CountryRenderer: ItemRenderer<CountryINFO> = (Bcan, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Bcan.Country}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Bcan.CountryCode}
                key={Bcan.CountryCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CountryPredicate: ItemPredicate<CountryINFO> = (query, Bcan, _index, exactMatch) => {
        const normalizedText = `${Bcan.CountryCode} - ${Bcan.Country}`.toLowerCase();
        
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CountryValueRenderer = (Bcan: CountryINFO) => {
        return Bcan.CountryCode
    }




    const CCEPRenderer: ItemRenderer<CCEPINFO> = (Bcan, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Bcan.ParticipantName}`;  // show in list
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Bcan.ParticipantID}
                key={Bcan.ParticipantID}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };



    const  CCEPPredicate: ItemPredicate<CCEPINFO> = (query, Bcan, _index, exactMatch) => {
        const normalizedText = `${Bcan.ParticipantName} - ${Bcan.ParticipantID}`.toLowerCase();      
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    
    const CCEPValueRenderer = (Bcan: CCEPINFO) => {
        return Bcan.ParticipantID
    }    



    const CCEPNameRenderer: ItemRenderer<CCEPINFO> = (Bcan, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Bcan.ParticipantName}`;  // show in list
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Bcan.ParticipantName}
                key={Bcan.ParticipantID}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };



    const  CCEPNamePredicate: ItemPredicate<CCEPINFO> = (query, Bcan, _index, exactMatch) => {
        const normalizedText = `${Bcan.ParticipantName} - ${Bcan.ParticipantID}`.toLowerCase();      
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CCEPNameValueRenderer = (Bcan: CCEPINFO) => {
        return Bcan.ParticipantName
    }



    const inputCNBCAN =       
  
        <InputGroup id="inputCNBCAN" name="d_CNBCAN"     placeholder="CNBcan no."    inputRef={register({ max:9999999999, minLength:1,maxLength: 10})} />
        {errors.d_CNBCAN && errors.d_CNBCAN.types && <span id="Err_msg">{errors.d_CNBCAN.types.message}</span>}
        {errors.d_CNBCAN && errors.d_CNBCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
        {errors.d_CNBCAN && errors.d_CNBCAN.type === "min" && <span id="Err_msg">Min value is 1</span>}
        {errors.d_CNBCAN && errors.d_CNBCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>}
   


        const inputBCAN = 
        (BCAN_Allow_Change===true)
        ? <InputGroup id="inputBCAN" name="d_BCAN"  type="number"   placeholder="HKBcan no."   inputRef={register({max:9999999999,minLength:1, maxLength: 10})} />
        : <InputGroup readOnly value={Bcan.d_BCAN} />       
        {errors.d_BCAN && errors.d_BCAN.types && <span id="Err_msg">{errors.d_BCAN.types.message}</span>}
        {errors.d_BCAN && errors.d_BCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
        {/* errors.d_BCAN && errors.d_BCAN.type === "min" && <span id="Err_msg">Min value is 1</span>*/}
        {errors.d_BCAN && errors.d_BCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>} 
      

 
    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        {/* <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } /> */}
                        <InputGroup disabled={! isNew} id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Account Holder No." labelFor="inputHolderNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputHolderNo" name="d_acHolderNo" defaultValue="" placeholder="Holder No. ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_acHolderNo && errors.d_acHolderNo.types && <span id="Err_msg">{errors.d_acHolderNo.types.message}</span>}
                    {errors.d_acHolderNo && errors.d_acHolderNo.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
                { SUSPEND_AC && <FormGroup className="Col-5-1" label="Status" labelFor="inputStatus">
                    <div>
                        <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup> }
                <FormGroup className="Col-6-3" label="Name in GSB" labelFor="inputName">
                    <div>
                        <div id="DispText">{AcName1Value}</div>
                        <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
            </div>


            <div className="Row">
                <FormGroup className="Col3-1-3" label="Client Type" labelFor="inputClientType">
                     
                     <HTMLSelect  id="input_IdType" name="d_ClientType" value={def_d_ClientType} onChange={utils.handleStringChange(s => setdef_d_ClientType(s)) } ref={register}>
    
                                <option value="">{t('PLease select')}</option>
                                <option value="1">{t('Individual')}</option>
                                <option value="2">{t('Joint Account')}</option>
                                <option value="3">{t('Funds')}</option>
                                <option value="4">{t('Legal Entity - Fund managers')}</option>
                                <option value="5">{t('Proprietary Trading')}</option>
                                <option value="6">{t('Legal entity - Others')}</option>                   
                         
                            </HTMLSelect>
                    {errors.d_ClientType && errors.d_ClientType.types && <span id="Err_msg">{errors.d_ClientType.types.message}</span>}
                    {errors.d_ClientType && errors.d_ClientType.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}                            
                </FormGroup>
            </div>
           {/* when clienttype 1,2 */}
           { (def_d_ClientType === "1" || def_d_ClientType === "2" || def_d_ClientType === "" ) && <div className="Row">

                <FormGroup className="Col-1-3" label="English First and Middle Name" labelFor="inputEnglish_First_Middle_Name">
                    <InputGroup id="inputEnglish_First_Middle_Name" name="d_English_First_Middle_Name" autoFocus={true} defaultValue="" placeholder="First Name  ..." inputRef={register({ maxLength: 40}) } />
                    {errors.d_English_First_Middle_Name && errors.d_English_First_Middle_Name.types && <span id="Err_msg">{errors.d_English_First_Middle_Name.types.message}</span>}
                    {errors.d_English_First_Middle_Name && errors.d_English_First_Middle_Name.type === "maxLength" && <span id="Err_msg">Max length is 40</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label="English Last Name" labelFor="inputEnglish_Last_Name">
                    <InputGroup id="inputEnglish_Last_Name" name="d_English_Last_Name" defaultValue="" placeholder="First Name  ..." inputRef={register({ maxLength: 40}) } />
                    {errors.d_English_Last_Name && errors.d_English_Last_Name.types && <span id="Err_msg">{errors.d_English_Last_Name.types.message}</span>}
                    {errors.d_English_Last_Name && errors.d_English_Last_Name.type === "maxLength" && <span id="Err_msg">Max length is 40</span>}
                </FormGroup>
            </div> }
            { (def_d_ClientType === "1" || def_d_ClientType === "2" || def_d_ClientType === "" ) && <div className="Row">
                <FormGroup className="Col-1-2" label="Chinese Name" labelFor="inputChinese_Name">
                    <InputGroup id="inputChinese_Name" name="d_Chinese_Name" defaultValue="" placeholder="Chinese Name ..." inputRef={register({ maxLength: 80}) } />
                    {errors.d_Chinese_Name && errors.d_Chinese_Name.types && <span id="Err_msg">{errors.d_Chinese_Name.types.message}</span>}
                    {errors.d_Chinese_Name && errors.d_Chinese_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
            </div> }

            { (def_d_ClientType === "3" || def_d_ClientType === "4" || def_d_ClientType === "5" || def_d_ClientType === "6" ) && <div className="Row">

                <FormGroup className="Col-1-4" label="English Entity Name" labelFor="inputEnglish_Legal_Entity_Name">
                    <InputGroup id="inputEnglish_Legal_Entity_Name" name="d_English_Legal_Entity_Name" autoFocus={true} defaultValue="" placeholder="Entity Name ..." inputRef={register({ maxLength: 80}) } />
                    {errors.d_English_Legal_Entity_Name && errors.d_English_Legal_Entity_Name.types && <span id="Err_msg">{errors.d_English_Legal_Entity_Name.types.message}</span>}
                    {errors.d_English_Legal_Entity_Name && errors.d_English_Legal_Entity_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
            </div> }
            { (def_d_ClientType === "3" || def_d_ClientType === "4" || def_d_ClientType === "5" || def_d_ClientType === "6" ) && <div className="Row">

                <FormGroup className="Col-1-4" label="Chinese Entity Name" labelFor="inputChinese_Entity_Name">
                    <InputGroup id="inputChinese_Entity_Name" name="d_Chinese_Entity_Name" defaultValue="" placeholder="Chinese Entity Name ..." inputRef={register({ maxLength: 120}) } />
                    {errors.d_Chinese_Entity_Name && errors.d_Chinese_Entity_Name.types && <span id="Err_msg">{errors.d_Chinese_Entity_Name.types.message}</span>}
                    {errors.d_Chinese_Entity_Name && errors.d_Chinese_Entity_Name.type === "maxLength" && <span id="Err_msg">Max length is 120</span>}
                </FormGroup>

            </div> }

            {(GsbFlag.SYNC_TEXT)
            ?   <div className="Row">
                   
                   <FormGroup className="Col-1-2" label="BCAN No.(HKIDR)">
                        {(BCAN_Allow_Change===true)
                        ? <InputGroup id="inputBCAN" name="d_BCAN"  type="number"   placeholder="HKBcan no."   inputRef={register({max:9999999999,minLength:1, maxLength: 10})} />
                        : <InputGroup readOnly value={Bcan.d_BCAN} />
                        }
                        {errors.d_BCAN && errors.d_BCAN.types && <span id="Err_msg">{errors.d_BCAN.types.message}</span>}
                        {errors.d_BCAN && errors.d_BCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                        {/* errors.d_BCAN && errors.d_BCAN.type === "min" && <span id="Err_msg">Min value is 1</span>*/}
                        {errors.d_BCAN && errors.d_BCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>} 
                    </FormGroup>

                    <FormGroup className="Col-3-2" label="CE Number.(HKIDR)" labelFor="inputCENumber">
                        <Tooltip content={CENUMToolTip} position={Position.TOP}>
                        {(BCAN_Allow_Change===true)
                            ? <InputGroup id="inputCENumber" name="d_CE_Number" defaultValue="" placeholder="CE Number ..." inputRef={register({ maxLength: 6}) } />
                            : <InputGroup id="inputCENumber" name="d_CE_Number" readOnly value={Bcan.d_CE_Number} />
                        }
                        </Tooltip>
                        <div>
                            {errors.d_CE_Number && errors.d_CE_Number.types && <span id="Err_msg">{errors.d_CE_Number.types.message}</span>}
                            {errors.d_CE_Number && errors.d_CE_Number.type === "maxLength" && <span id="Err_msg">Max length is {6}</span>}
                        </div>
                    </FormGroup>
                    {(Allow_SMPID===true)&& <FormGroup className="Col-1-2 search-div" label="SMPID">
                    <InputGroup id="inputSMPID" name="d_SMPID" placeholder="SMP ID is a unique identifier"  onChange={utils.handleStringChange(s => setdef_d_SMPID(s)) }    inputRef={register({ max:999999999,minLength:1,maxLength: 9})} />
                        {errors.d_SMPID && errors.d_SMPID.types && <span id="Err_msg">{errors.d_SMPID.types.message}</span>}
                        {errors.d_SMPID && errors.d_SMPID.type === "maxLength" && <span id="Err_msg">Max length is 9</span>}
                        {errors.d_SMPID && errors.d_SMPID.type === "min" && <span id="Err_msg">Min value is 1</span>}
                        {errors.d_SMPID && errors.d_SMPID.type === "max" && <span id="Err_msg">Max value is 999999999</span>}       
                    </FormGroup>
                    }

                    <FormGroup className="Col-1-2 search-div" label="BCAN No.(CNBCAN)">                                   
                       

                        {(BCAN_Allow_Change===true)
                        ? <InputGroup id="inputCNBCAN" name="d_CNBCAN"  type="number"   placeholder="CNBcan no."   inputRef={register({max:9999999999,minLength:1, maxLength: 10})} />
                        : <InputGroup readOnly value={Bcan.d_CNBCAN} />
                        }
                        {/*<InputGroup id="inputCNBCAN" name="d_CNBCAN"  placeholder="CNBcan no."    inputRef={register({ max:9999999999, minLength:1,maxLength: 10})} />*/}
                        {errors.d_CNBCAN && errors.d_CNBCAN.types && <span id="Err_msg">{errors.d_CNBCAN.types.message}</span>}
                        {errors.d_CNBCAN && errors.d_CNBCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                        {errors.d_CNBCAN && errors.d_CNBCAN.type === "min" && <span id="Err_msg">Min value is 1</span>}
                        {errors.d_CNBCAN && errors.d_CNBCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>}                
                         
                    </FormGroup>
                    
                    {(GsbFlag.CNBCAN && BCAN_Allow_Change===true)
                    ?
                     
                       <FormGroup   className="Col3-3-4" label={t('Excute CCEP Firm ID.(CNBCAN)')} labelFor="inputd_CCEP">
                        <Suggest
                            inputProps={{ placeholder: "Excute CCEP Firm ID." }}
                            defaultSelectedItem={CCEPList.find((aj) => aj.ParticipantID.trim() === Bcan.d_ExeCCEP_FirmID.trim())}
                            items={CCEPList}
                            itemRenderer={CCEPRenderer}
                            itemPredicate={CCEPPredicate}
                            inputValueRenderer={CCEPValueRenderer}                  
                            onItemSelect={(Bcan) => { setValue("d_ExeCCEP_FirmID", Bcan.ParticipantID);setExeCCEP(Bcan.ParticipantID)}}
                        
                        />
                        {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.types && <span id="Err_msg">{errors.d_ExeCCEP_FirmID.types.message}</span>}
                        {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "maxLength" && <span id="Err_msg">Max length is 4</span>} 
                        {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "max" && <span id="Err_msg">Max value is 9999</span>}                     
                        <div>
                            <div id="DispText">{ExeCCEPFirmNameDesc}</div>
                        </div>
                        </FormGroup> 
                         
                    :
                        
                        
                        <FormGroup className="Col-3-2 search-div" label="Excute CCEP Firm ID.(CNBCAN)">
                            <Tooltip content={CCEPToolTip} position={Position.TOP}>              
                                <InputGroup id="inputCNBCAN" readOnly={!BCAN_Allow_Change} name="d_ExeCCEP_FirmID" placeholder="Excute CCEP Firm ID."   inputRef={register({ max:9999, maxLength: 4})} />
                            </Tooltip>
                            <div>
                                {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.types && <span id="Err_msg">{errors.d_ExeCCEP_FirmID.types.message}</span>}
                                {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "maxLength" && <span id="Err_msg">Max length is 4</span>} 
                                {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "max" && <span id="Err_msg">Max value is 9999</span>}
                            </div>
                        </FormGroup>                           
                        
                    }

                </div>               
            :<div className="Row">           
                <FormGroup className="Col-1-2" label="BCAN No.(HKIDR)">
                    {(BCAN_Allow_Change===true)
                        ? <InputGroup id="inputBCAN" name="d_BCAN"  placeholder="HKBcan no."     inputRef={register({max:9999999999,minLength:1, maxLength: 10})} />
                        : <InputGroup readOnly value={Bcan.d_BCAN} />
                    }
                    {errors.d_BCAN && errors.d_BCAN.types && <span id="Err_msg">{errors.d_BCAN.types.message}</span>}
                    {errors.d_BCAN && errors.d_BCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {/* errors.d_BCAN && errors.d_BCAN.type === "min" && <span id="Err_msg">Min value is 1</span>*/}
                    {errors.d_BCAN && errors.d_BCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>} 
               
                </FormGroup>
                
                <FormGroup className="Col-1-2 search-div" label="SMPID">
                    <InputGroup id="inputSMPID" name="d_SMPID" placeholder="SMP ID is a unique identifier"     inputRef={register({ max:999999999,minLength:1,maxLength: 9})} />
                    {errors.d_SMPID && errors.d_SMPID.types && <span id="Err_msg">{errors.d_SMPID.types.message}</span>}
                    {errors.d_SMPID && errors.d_SMPID.type === "maxLength" && <span id="Err_msg">Max length is 9</span>}
                    {errors.d_SMPID && errors.d_SMPID.type === "min" && <span id="Err_msg">Min value is 1</span>}
                    {errors.d_SMPID && errors.d_SMPID.type === "max" && <span id="Err_msg">Max value is 999999999</span>}       
                </FormGroup>
                
                <FormGroup className="Col-1-2 search-div" label="BCAN No.(CNBCAN)">
                    {(BCAN_Allow_Change===true)
                        ?<InputGroup id="inputCNBCAN" name="d_CNBCAN" placeholder="CNBcan no." inputRef={register({ max:9999999999, minLength:1,maxLength: 10})} />
                        :<InputGroup id="inputCNBCAN" name="d_CNBCAN" readOnly value={Bcan.d_CNBCAN}  />
                    }
                    {errors.d_CNBCAN && errors.d_CNBCAN.types && <span id="Err_msg">{errors.d_CNBCAN.types.message}</span>}
                    {errors.d_CNBCAN && errors.d_CNBCAN.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_CNBCAN && errors.d_CNBCAN.type === "min" && <span id="Err_msg">Min value is 1</span>}
                    {errors.d_CNBCAN && errors.d_CNBCAN.type === "max" && <span id="Err_msg">Max value is 9999999999</span>}               
                </FormGroup>

             
                <FormGroup className="Col-3-2 search-div" label="Excute CCEP Firm ID.(CNBCAN)">
                        <InputGroup id="inputCNBCAN" name="d_ExeCCEP_FirmID"   placeholder="Excute CCEP Firm ID."   inputRef={register({ max:99999, maxLength: 5})} />
                            {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.types && <span id="Err_msg">{errors.d_ExeCCEP_FirmID.types.message}</span>}
                            {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "maxLength" && <span id="Err_msg">Max length is 5</span>} 
                            {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.type === "max" && <span id="Err_msg">Max value is 99999</span>}
                    
                </FormGroup>                
            
                {/*
                <FormGroup   className="Col3-3-2" label={t('Excute CCEP Firm ID.(CNBCAN)')} labelFor="inputd_CCEP">
                    <Suggest
                        inputProps={{ placeholder: "Excute CCEP Firm ID." }}
                        defaultSelectedItem={CCEPList.find((aj) => aj.ParticipantID === Bcan.d_ExeCCEP_FirmID)}
                        items={CCEPList}
                        itemRenderer={CCEPRenderer}
                        itemPredicate={CCEPPredicate}
                        inputValueRenderer={CCEPValueRenderer}                  
                        onItemSelect={(Bcan) => { setValue("d_ExeCCEP_FirmID", Bcan.ParticipantID);  setValue("d_ExeCCEP_FirmName", Bcan.ParticipantName)}}
                      
                    />
                     {errors.d_ExeCCEP_FirmID && errors.d_ExeCCEP_FirmID.types && <span id="Err_msg">{errors.d_ExeCCEP_FirmID.types.message}</span>}
                </FormGroup>     
                */}
            </div>
            
            }

            <div className="Row">
                <FormGroup className="Col3-1-3" label="ID Type" labelFor="inputIdType">
                        
                       <HTMLSelect  id="input_IdType" name="d_ID_Type" value={def_d_ID_Type} onChange={utils.handleStringChange(s => setdef_d_ID_Type(s)) } ref={register}>

							{/*
							
							   hkidr CNBCAN /SAVE TO CN ID TYPE SAME TIME WHEN EDIT
								1 HKID -> 1 Identity Card
								2 National Identification Document -> 5 = Other official identity document (only for Client Types 1 and 2)
								3 Passport -> 2 = Passport
								4 -> 4 = LEI
								5 -> 3 = Certificate of Incorporation / other official incorporation documents
								6 -> 3 
							
							*/}
		
		                        <option value="">{t('Please select')}</option>
		                        <option value="1">{t('HKID')}</option>
		                        <option value="2">{t('National Identification Document')}</option>
		                        <option value="3">{t('Passport')}</option>
		                        <option value="4">{t('LEI')}</option>
		                        <option value="5">{t('Certificate of Incorporation')}</option>
		                        <option value="6">{t('Business Registration Certificate')}</option>
		                        <option value="7">{t('Other official incorporation documents')}</option>

                         
                        </HTMLSelect>
                                                   
                        {errors.d_ID_Type && errors.d_ID_Type.types && <span id="Err_msg">&nbsp;{errors.d_ID_Type.types.message}</span>}                      
                 </FormGroup>   
             
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Country of Issuance')} labelFor="inputd_Country_Issuance">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Country of Issuance" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.CountryCode === Bcan.d_Country_Issuance)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Bcan) => { setValue("d_Country_Issuance", Bcan.CountryCode) ;setCountry1(Bcan.CountryCode)}}
                    />
                    {errors.d_Country_Issuance && errors.d_Country_Issuance.types && <span id="Err_msg">{errors.d_Country_Issuance.types.message}</span>}
                    <div>
                    <div id="DispText">{CountryIssuanceDesc}</div>
                    </div>
                </FormGroup> 
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="ID No." labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="d_ID_Number" defaultValue="" placeholder="ID Number ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_ID_Number && errors.d_ID_Number.types && <span id="Err_msg">{errors.d_ID_Number.types.message}</span>}
                    {errors.d_ID_Number && errors.d_ID_Number.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
            </div>
          
            {! showChiAddressMore && (GsbFlag.GSBx_USER ===true) &&<div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChiAddressMore(! showChiAddressMore) }} >+ Show Chinese Address </div> }
            { showChiAddressMore  && (GsbFlag.GSBx_USER ===true) && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChiAddressMore(! showChiAddressMore) }} >- Chinese Address </div> }
   
   
            {showChiAddressMore  && (GsbFlag.GSBx_USER ===true) && <div className="Row">

                   <FormGroup className="Col-1-4" label="Chinese Address" labelFor="inputChineseAddress">
                        <InputGroup id="inputChineseAddress1" name="d_ChineseAddress1" defaultValue="" placeholder="Chinese Address1..." inputRef={register({ maxLength: 40}) } />
                        <InputGroup id="inputChineseAddress2" name="d_ChineseAddress2" defaultValue="" placeholder="Chinese Address2..." inputRef={register({ maxLength: 40}) } />
                        <InputGroup id="inputChineseAddress3" name="d_ChineseAddress3" defaultValue="" placeholder="Chinese Address3..." inputRef={register({ maxLength: 40}) } />
                        <InputGroup id="inputChineseAddress4" name="d_ChineseAddress4" defaultValue="" placeholder="Chinese Address4..." inputRef={register({ maxLength: 40}) } />
                        {errors.d_ChineseAddress1 && errors.d_ChineseAddress1.types && <span id="Err_msg">{errors.d_ChineseAddress1.types.message}</span>}
                        {errors.d_ChineseAddress1 && errors.d_ChineseAddress1.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                        {errors.d_ChineseAddress2 && errors.d_ChineseAddress2.types && <span id="Err_msg">{errors.d_ChineseAddress2.types.message}</span>}
                        {errors.d_ChineseAddress2 && errors.d_ChineseAddress2.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                        {errors.d_ChineseAddress3 && errors.d_ChineseAddress3.types && <span id="Err_msg">{errors.d_ChineseAddress3.types.message}</span>}
                        {errors.d_ChineseAddress3 && errors.d_ChineseAddress3.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                        {errors.d_ChineseAddress4 && errors.d_ChineseAddress4.types && <span id="Err_msg">{errors.d_ChineseAddress4.types.message}</span>}
                        {errors.d_ChineseAddress4 && errors.d_ChineseAddress4.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                   </FormGroup>
                    <FormGroup className="Col-1-2" label="Select Chinese Name or Address on Statement (Y/N) " labelFor="StmtChiAdd">
                        <RadioGroup
                            name="d_StmtChiAdd"
                            inline={true}
                            selectedValue={def_d_StmtChiAdd}
                            onChange={utils.handleStringChange(s => setdef_d_StmtChiAdd(s)) }
                            ref={register}>
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_StmtChiAdd && errors.d_StmtChiAdd.types && <span id="Err_msg">{errors.d_StmtChiAdd.types.message}</span>}
                    </FormGroup>
                     
            </div> }

            <div className="Row">
                <FormGroup className="Col-1-2" label="HKConsent " labelFor="HKConsent">
                    <RadioGroup
                        name="d_Consent_Agree"
                        inline={true}
                        selectedValue={def_d_Consent_Agree}
                        onChange={utils.handleStringChange(s => setdef_d_Consent_Agree(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Consent_Agree && errors.d_Consent_Agree.types && <span id="Err_msg">{errors.d_Consent_Agree.types.message}</span>}
                </FormGroup>
               
                <FormGroup className="Col-3-3" label="CNConsent " labelFor="CNConsent">
                    <RadioGroup
                        name="d_CN_Consent_Agree"
                        inline={true}
                        selectedValue={def_d_CNConsent_Agree}
                        onChange={utils.handleStringChange(s => setdef_d_CNConsent_Agree(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_CN_Consent_Agree && errors.d_CN_Consent_Agree.types && <span id="Err_msg">{errors.d_CN_Consent_Agree.types.message}</span>}
                </FormGroup>
                
            </div>

      
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Consent_Date')} labelFor="inputConsent_Date">
                    {/* def_d_Consent_Agree === "Y" && <InputGroup id="inputDoB" name="d_Consent_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} /> */}
                    {/* ! ( def_d_Consent_Agree === "Y" ) && <InputGroup readOnly value= {utils.dispDate(Bcan.d_Consent_Date, GsbFlag.dateFormat)} /> */}
                    <InputGroup disabled={! (def_d_Consent_Agree === "Y")} id="inputDoB" name="d_Consent_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Consent_Date && errors.d_Consent_Date.types && <span id="Err_msg">{errors.d_Consent_Date.types.message}</span>}
                </FormGroup> 
                
                <FormGroup className="Col-3-2" label={t('CNConsent_Date')} labelFor="inputCNConsent_Date">
                        <InputGroup disabled={! (def_d_CNConsent_Agree === "Y")} id="inputDoB" name="d_CN_Consent_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                        {errors.d_CN_Consent_Date && errors.d_CN_Consent_Date.types && <span id="Err_msg">{errors.d_CN_Consent_Date.types.message}</span>}
                </FormGroup> 
                
            </div>
            
        </form>
    )
}


interface HkidrMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function HkidrMastMain({ gsb }: HkidrMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [data, setData] = useState([] as HkidrInfo[])


    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchSMPID,setSearchSMPID] = useState("")
    const [searchEcpRdStatus,setsearchEcpRdStatus] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchCENumber, setSearchCENumber] = useState("")
    const [searchConsent, setsearchConsent] = useState("")
    const [searchClientType, setsearchClientType] = useState("")
    const [searchIDType, setsearchIDType] = useState("")
    const [searchCNConsent, setsearchCNConsent] = useState("")


    const [s_AcStatus, sets_AcStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as HkidrInfo)
    const [BlankAc, setBlankAc] = useState({} as HkidrInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as HkidrInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as HkidrInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    const [SYNCTEXT ,setSYNCTEXT] = useState(false)

    const { t, i18n } = useTranslation();

    //HKIDR default value
    //BlankAc.string = ""
    

    const [CountryList, setCountryList] = useState([] as CountryINFO[])

    const [CCEPList, setCCEPList] = useState([] as CCEPINFO[])

    //console.log("ClMastMain logKey: ", logKey)
    //console.log("ClMastMain logKey2: ", logKey2)
    //console.log("ClMastMain logviewonly: ", logviewonly)
    const [CNBCAN ,setCNBCAN] = useState(false)
    const [Allow_SMPID, setAllow_SMPID] = useState(false)



    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("HkidrMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("HkidrMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("HkidrMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("HkidrMastMain disp GsbFlag: ", GsbFlag)
                //console.log("HkidrMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("HkidrMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                  
 

                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("HkidrMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("HkidrMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("HkidrMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);
 

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            try {
                // get BcanConfig Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "BcanConfig", cAction: "GetBcanConfigInfo", cAcCode: ""})
                
                if (  res.data.count > 0 )
                {
                    
                    setAllow_SMPID(res.data.data[0].SMPID_OK=="Y")
                    
                } else { 
                    
                    setAllow_SMPID(false)
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, UserID])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }
        
        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "HkidrMast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("HkidrMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])




    useEffect(() => {

      

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListCountry",
                    });
                setCountryList(res.data.data)
                //console.log("ListCountryInfores.data.data", res.data.data);
               // console.log("ListCountryInfoJurisdictionList", CountryList);
               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    useEffect(() => {      

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListCCEP",
                    });
                setCCEPList(res.data.data)
               // console.log("ListCCEP.data.data", res.data.data);
                console.log("ListCCEPList",CCEPList);
               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReplicateProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReplicateProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReplicateRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReGenbcanProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReGebcanProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReGenHkBcanRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen HKBcan no")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen HKBcan no")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReGenCNbcanProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReGebcanProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReGenCNBcanRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen CNHKBcan no")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen CNHKBcan no")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }
    const DeleteProcess = async (Bcan: HkidrInfo) => {
        try {
            Bcan.d_AuthTran = ""
            if (isReqAuth2) {
                Bcan.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
            } else {
                showSuccess("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo  +"] is record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call NextRecProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Bcan: HkidrInfo) => {
        try {
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
            } else {
                showSuccess("Account Code " + Bcan.d_acCode + " record added")

                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }    

            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (Bcan: HkidrInfo) => {
        try {
            console.log("call editOnSave", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account Code " +Bcan.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            AcStatus:s_AcStatus,
                            idNo: searchIdNo,
                            Name: searchName,
                            CENumber: searchCENumber,
                            clientype : searchClientType,
                            IDType : searchIDType,
                            Consent: searchConsent,
                            CNConsent: searchCNConsent,
                            
                            SMPID: searchSMPID,

                            EcpRdStatus:searchEcpRdStatus,
                        },
                    },
                    cPgmId: "HkidrMast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)
                setSYNCTEXT(GsbFlag.SYNC_TEXT)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "HkidrMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                AcStatus:s_AcStatus,
                                idNo: searchIdNo,
                                clientype : searchClientType,
                                CENumber:searchCENumber,
                                IDType : searchIDType,
                                Consent: searchConsent,
                                CNConsent: searchCNConsent,

                            },
                            },
                        cPgmId: "HkidrMast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }

                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <HkidrMastView gsb={gsb} Bcan={currentAc} Bcan0={currentAc}  CountryList={CountryList} CCEPList ={CCEPList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onRegGenBcan={() => ReGenbcanProcess(currentAc)} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKIDR" ></HkidrMastView>
    switch (currentMode) {
        case "edit":
            detail = <HkidrMastEdit gsb={gsb} Bcan={currentAc} CountryList={CountryList} CCEPList ={CCEPList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="HKIDR" ></HkidrMastEdit>
            break
        case "new":
            detail = <HkidrMastEdit gsb={gsb} Bcan={BlankAc} CountryList={CountryList} CCEPList ={CCEPList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="HKIDR" ></HkidrMastEdit>
            break
    }

    let logKeyViewRec = <HkidrMastView gsb={gsb} Bcan={CurrentLog1} Bcan0={CurrentLog0} CountryList={CountryList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onRegGenBcan={() => ReGenbcanProcess(currentAc)} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKIDR" ></HkidrMastView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


 
 
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                 {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>
 

    
    let list = <>
      
        <div className="Row">
       
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
             
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACCode">Holder No</th>
                <th className="TCol-ACCode">Status</th>
                
                <th className="TCol-ACCode">Client Type</th>          
                <th className="TCol-ACName">Name / Chinese Name</th>
                
                {(SYNCTEXT) && <th className="TCol-IDNo">CE Number</th>}
            
                 <th className="TCol-ACName">HK Bcan no</th>   
                {Allow_SMPID === true &&  <th className="TCol-ACName">SMPID</th>}
                <th className="TCol-ACName">CN Bcan no</th>  
                <th className="TCol-IDNo">ID Type</th>
                <th className="TCol-IDNo">ID</th>
                <th className="TCol-ACName">HKConsent</th> 
                <th className="TCol-ACName">HKConsent DATE</th> 
				
                {CNBCAN && <th className="TCol-ACName">CNConsent</th>}
                {CNBCAN && <th className="TCol-ACName">CNConsent DATE</th>}

                <th className="TCol-ACName">Ecp Record status</th> 
               {/* <th className="TCol-ACName">CNConsent</th> 
                <th className="TCol-ACName">CNConsent DATE</th> */}
            </thead>
            <tbody id="ma_tr">
                {data.map((Bcan, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_acCode}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_acHolderNo}</td>
                         {/*
                        {(() => {
                            switch (Bcan.d_ACStatus)  {
                                case ' ':
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Active</td>   
                                case 'A':
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Active</td>   
                                case ' ':
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Active</td>
                                case 'C':
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Closed</td>
                                case 'S':
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>SUSPEND</td>   
                                                                        
                                default:
                                    return   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_ACStatus}</td>                                 
               
                            }
                        })()} 
                        */}
                        {/* 

                        {(Bcan.d_ACStatus === "A"  || Bcan.d_ACStatus === ""  || Bcan.d_ACStatus === " " )
                            ? <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Active</td>      
                            : <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_ACStatus}</td>  
                        }*/}

                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_ACStatus}</td> 

                        
                        {/* show client type name */}
                         {/* 
                        {(Bcan.d_ClientType === "1") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Individual</td>
                        }
                        {(Bcan.d_ClientType === "2") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Joint Account</td>
                        }
                        {(Bcan.d_ClientType === "3") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Funds</td>
                        }
                        {(Bcan.d_ClientType === "4") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Legal Entity - Fund managers</td>
                        }
                        {(Bcan.d_ClientType === "5") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Proprietary Trading</td>
                        }
                        {(Bcan.d_ClientType === "6") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Legal entity - Others</td>
                        }             
                        */}


                        {(() => {
                            switch (Bcan.d_ClientType)  {
                                case '1':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Individual</td> 
                                case '2':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Joint Account</td>   
                                case '3':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Funds</td>
                                case '4':
                                    return   <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Legal Entity - Fund managers</td>
                                case '5':
                                    return   <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Proprietary Trading</td>
                                case '6':                                                                       
                                   return   <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Legal entity - Others</td>                             
                                default:
                                   return   <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}> &nbsp;</td>                             
   
                            }
                        })()}


                        {(Bcan.d_ClientType === "1" ||Bcan.d_ClientType === "2" || Bcan.d_ClientType === "")
                            ? <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_English_Last_Name} {Bcan.d_English_First_Middle_Name} &nbsp; {Bcan.d_Chinese_Name}</td>    
                            : <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_English_Legal_Entity_Name} &nbsp; {Bcan.d_Chinese_Entity_Name}</td>
                        }       
                        {(SYNCTEXT) && <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_CE_Number} </td>    }
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_BCAN}</td> 
                        {Allow_SMPID === true && <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_SMPID}</td> }
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_CNBCAN}</td> 

     
                        {/*show id tpye*/}   
                        {/*                                                                                                        
                        {(Bcan.d_ID_Type === "") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>&nbsp;</td>
                        }
                        {(Bcan.d_ID_Type === "1") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>HKID</td>
                        }
                        {(Bcan.d_ID_Type === "2") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>National Identification Document</td>
                        }
                        {(Bcan.d_ID_Type === "3") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Passport</td>
                        }
                        {(Bcan.d_ID_Type === "4") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>LEIs</td>
                        }
                        {(Bcan.d_ID_Type === "5") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Certificate of Incorporation</td>
                        }
                        {(Bcan.d_ID_Type === "6") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Business Registration Certificate</td>
                        }     
                        {(Bcan.d_ID_Type === "7") &&                        
                            <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Other official incorporation documents</td>
                        }           
                        */}

                        {(() => {
                            switch (Bcan.d_ID_Type)  {
                                case '1':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>HKID</td>
                                case '2':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>National Identification Document</td>
                                case '3':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Passport</td>
                                case '4':
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>LEIs</td>
                                case '5':
                                    return    <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Certificate of Incorporation</td>
                                case '6':                                                                       
                                   return    <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Business Registration Certificate</td>                      
                                case '7':
                                    return   <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Other official incorporation documents</td>
                                default:
                                    return  <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>&nbsp;</td>
                            }
                        })()}

                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_ID_Number}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_Consent_Agree}</td>                   
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Bcan.d_Consent_Date, GsbFlag.dateFormat)}</td>   
                        {CNBCAN && <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_CN_Consent_Agree}</td>}
                        {CNBCAN && <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Bcan.d_CN_Consent_Date, GsbFlag.dateFormat)}</td>}
      
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>
                            {(Bcan.d_EcpRecordStatus) === "N" && <Button icon="small-tick" intent="success" text="To ECP" />
                            || (Bcan.d_EcpRecordStatus) === "S" &&  <Button icon="small-cross"  intent= "warning" text="Cancel" />
                            }</td>   
                
                       {/*<td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_CN_Consent_Agree}</td>                   
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Bcan.d_CN_Consent_Date, GsbFlag.dateFormat)}</td>  */}
                    </tr>
                )}
            </tbody>
        </HTMLTable>      
    

        {ShowWait && nPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>HKIDR Master</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>                                    
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>


                        { SUSPEND_AC && <div className="Row">
                            <FormGroup className="Col3-1-1" label="A/C Status" labelFor="inputAcStatus">
                                <select  id="inputAcStatus" value={s_AcStatus} onChange={utils.handleStringChange(s => sets_AcStatus(s))}>
                                    <option value=""> </option>
                                    <option value="A">Active</option>
                                    <option value="NA">Non-Active</option>
                                    {/*<option value="C">Closed</option>*/}
                                    {/*<option value="S">Suspend</option>*/}                                                                        
                                </select>
                            </FormGroup>
                        </div> }
                        <div className="Row">
                           <FormGroup className="Col-1-1" label="ID No" labelFor="searchIdNo">
                                <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                            </FormGroup>          

                            <FormGroup className="Col3-2-1" label="ClientType" labelFor="searchClientType">
                                <select  id="searchClientType" value={searchClientType} onChange={utils.handleStringChange(s => setsearchClientType(s))}>
                                    <option value="">{t('')}</option>
                                    <option value=" ">{t('no client type')}</option>
                                    <option value="1">{t('Individual')}</option>
                                    <option value="2">{t('Joint Account')}</option>
                                    <option value="3">{t('Funds')}</option>
                                    <option value="4">{t('Legal Entity - Fund managers')}</option>
                                    <option value="5">{t('Proprietary Trading')}</option>
                                    <option value="6">{t('Legal entity - Others')}</option>    
                                </select>
                            </FormGroup>       
   
                               <FormGroup className="Col3-3-1" label="IDType" labelFor="searchIDType">
                                <select  id="searchClientType" value={searchIDType} onChange={utils.handleStringChange(s => setsearchIDType(s))}>
                                    <option value="">{t('')}</option>
                                    <option value=" ">{t('no ID type')}</option>
                                    <option value="1">{t('HKID')}</option>
                                    <option value="2">{t('National Identification Document')}</option>
                                    <option value="3">{t('Passport')}</option>
                                    <option value="4">{t('LEI')}</option>
                                    <option value="5">{t('Certificate of Incorporation')}</option>
                                    <option value="6">{t('Business Registration Certificate')}</option>    
                                    <option value="7">{t('Other official incorporation documents')}</option>    
                                </select>
                            </FormGroup>           
                               
                            <FormGroup className="Col3-4-1" label="Consent" labelFor="searchConsent">
                                <select  id="searchConsent" value={searchConsent} onChange={utils.handleStringChange(s => setsearchConsent(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>       

                            <FormGroup className="Col3-5-1" label="CNConsent" labelFor="searchCNConsent">
                                <select  id="searchCNonsent" value={searchCNConsent} onChange={utils.handleStringChange(s => setsearchCNConsent(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>           

                             <FormGroup className="Col-1-1" label="CE Number" labelFor="searchCENUM">
                                <InputGroup id="searchCENUM" value={searchCENumber} onChange={utils.handleStringChange(s => setSearchCENumber(s))} />
                            </FormGroup>       

                            <FormGroup className="Col-1-1" label="Have SMP ID." labelFor="searchSMPID">
                                <select  id="searchSMPID" value={searchSMPID} onChange={utils.handleStringChange(s => setSearchSMPID(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>    
                            </FormGroup>          
                            
                            <FormGroup className="Col-1-1" label="Ecp Record status" labelFor="searchEcpRdStatus">
                                <select  id="searchEcpRdStatus" value={searchEcpRdStatus} onChange={utils.handleStringChange(s => setsearchEcpRdStatus(s))}>
                                    <option value=""> </option>
                                    <option value="N">To ECP</option>
                                    <option value="S">Cancel</option>
                                </select>    

                            </FormGroup>         
                                   
                        </div>

                    </Collapse>

                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>HKIDR Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default HkidrMastMain

interface CLHkidrMastViewProps {
    gsb: AxiosInstance,
    acCode:  any,
}

export function CLHkidrMastView({ gsb, acCode }: CLHkidrMastViewProps) {

    const { t, i18n } = useTranslation();
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [currentMode, setCurrentMode] = useState("show")

    const [data, setData] = useState([] as HkidrInfo[])
    const [currentAc, setCurrentAc] = useState({} as HkidrInfo)
    const [BlankAc, setBlankAc] = useState({} as HkidrInfo)

    const [CountryList, setCountryList] = useState([] as CountryINFO[])
    const [CCEPList, setCCEPList] = useState([] as CCEPINFO[])


    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")



    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)
                UserID = res.data.Flag.UserID

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListCountry",
                    });
                setCountryList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {      

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListCCEP",
                    });
                setCCEPList(res.data.data)
               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: acCode,
                        nPage: 1,
                        limit: 1,
                    },
                    cPgmId: "HkidrMast",
                    cAction: "GetRec_CL",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, acCode])


    const PrevRecProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "PrevRec_CL"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReplicateProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReplicateProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReplicateRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReGenbcanProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReGebcanProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReGenHkBcanRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen HKBcan no")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen HKBcan no")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReGenCNbcanProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call ReGebcanProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "ReGenCNBcanRec"})
            showSuccess("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen CNHKBcan no")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Bcan.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. regen CNHKBcan no")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }
    const DeleteProcess = async (Bcan: HkidrInfo) => {
        try {
            Bcan.d_AuthTran = ""
            if (isReqAuth2) {
                Bcan.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
            } else {
                showSuccess("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo  +"] is record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Bcan: HkidrInfo) => {
        try {
            //console.log("call NextRecProcess", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "NextRec_CL"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Bcan: HkidrInfo) => {
        try {
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
            } else {
                showSuccess("Account Code " + Bcan.d_acCode + " record added")

                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }    

            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " record adding, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (Bcan: HkidrInfo) => {
        try {
            console.log("call editOnSave", Bcan)
            const res = await gsb.post('/Master', {sb_data: Bcan, cPgmId: "HkidrMast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account Code " +Bcan.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <HkidrMastView gsb={gsb} Bcan={currentAc} Bcan0={currentAc}  CountryList={CountryList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onRegGenBcan={() => ReGenbcanProcess(currentAc)} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode="V" logKey2="" PgmMode="fmCL" ></HkidrMastView>
    switch (currentMode) {
        case "edit":
            detail = <HkidrMastEdit gsb={gsb} Bcan={currentAc} CountryList={CountryList} CCEPList ={CCEPList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="fmCL" ></HkidrMastEdit>
            break
        case "new":
            detail = <HkidrMastEdit gsb={gsb} Bcan={BlankAc} CountryList={CountryList} CCEPList ={CCEPList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show")} } PgmMode="fmCL" ></HkidrMastEdit>
            break
    }
    
    return (
        <>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>HKIDR Master</H3></div>
                {/* <HkidrMastView gsb={gsb} Bcan={currentAc} Bcan0={currentAc}  CountryList={CountryList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onRegGenBcan={() => ReGenbcanProcess(currentAc)} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode="V" logKey2="" PgmMode="fmCL" ></HkidrMastView> */}

                {detail}
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </>
    )

}
