import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FileInput, ProgressBar } from "@blueprintjs/core";
import { Suggest, Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { Account, cbMast, Document, StatusInfo, CurrencyInfo, GetCategoryInfo, HkidrInfo, CommInfo, IntRInfo } from './Types'
import { LocationInfo, BranchEmailInfo, ExtraFeeInfo, MktFeeInfo, RegionCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';
import { CLHkidrMastView } from './HkidrMast';
import { CLHkCrsMastView } from './HkCrsMast';

import i18next from 'i18next';
import { Label } from 'react-konva';


interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "M0001" ;
var isReqAuth2:boolean = false ;


interface ClMastViewProps {
    gsb: AxiosInstance,
    ac: Account,
    ac0: Account,
    StatusList?: StatusInfo[],
    CurrencyList?: CurrencyInfo[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}

const getDocIcon = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "id-number"
        case "signature":
            return "draw"
    }
    // contract, others
    return "document"
}

const getDocText = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "ID"
        case "signature":
            return "Signature"
        case "contract":
            return "Contract"
        case "others":
            return "Others"
    }
    // contract, others
    return "Unknown"
}


function ClMastView({ gsb, ac = {} as Account, ac0 = {} as Account, StatusList=[] as StatusInfo[], CurrencyList=[] as CurrencyInfo[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: ClMastViewProps) {


    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [isViewClientDoc, setisViewClientDoc] = useState(false)
    const [isViewClientDocPress, setisViewClientDocPress] = useState(false)
    const [isManageClientDoc, setisManageClientDoc] = useState(false)
    const [isManageClientDocPress, setisManageClientDocPress] = useState(false)
    const [showClientDoc, setshowClientDoc] = useState(true)
    const [isUploadDocPress, setisUploadDocPress] = useState(false)
    const [reloadDoc, setreloadDoc] = useState(false)
    const [docList, setDocList] = useState([] as Document[])

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isForeignCurrencyPress, setisForeignCurrencyPress] = useState(false)
    const [BlankCB, setBlankCB] = useState({} as cbMast)

    const [isHKIDRPress, setisHKIDRPress] = useState(false)
    const [isHKCRSPress, setisHKCRSPress] = useState(false)

    const [showSIMore, setshowSIMore] = useState(true)

    const [RegionCodeList, setRegionCodeList] = useState([] as RegionCodeInfo[])

    const [CategoryList, setCategoryList] = useState([] as GetCategoryInfo[])
    const [CategoryList2, setCategoryList2] = useState([] as GetCategoryInfo[])
    const [CategoryDesc, setCategoryDesc] = useState("")


    const [HKIDR_Info2, setHKIDR_Info2] = useState({} as HkidrInfo)
    const [Blank_HKIDR_Info2, setBlank_HKIDR_Info2] = useState({} as HkidrInfo)
    const [Comm_Info, setComm_Info] = useState({} as CommInfo)
    const [Blank_Comm_Info, setBlank_Comm_Info] = useState({} as CommInfo)
    const [IntR_Info, setIntR_Info] = useState({} as IntRInfo)
    const [Blank_IntR_Info, setBlank_IntR_Info] = useState({} as IntRInfo)

    const [StatusDesc, setStatusDesc] = useState("")
    const [ExtraFeeDesc, setExtraFeeDesc] = useState("")
    const [CountryDesc, setCountryDesc] = useState("")


    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [showPenaltyInt, setshowPenaltyInt] = useState(false)
    const [ShowAdd4, setShowAdd4] = useState(false)
    

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    //console.log("ClMastView ViewMode ", ViewMode)
    Blank_HKIDR_Info2.d_Chinese_Name = ""
    Blank_HKIDR_Info2.d_ChineseAddress1 = ""
    Blank_HKIDR_Info2.d_ChineseAddress2 = ""
    Blank_HKIDR_Info2.d_ChineseAddress3 = ""
    Blank_HKIDR_Info2.d_ChineseAddress4 = ""
    Blank_HKIDR_Info2.d_StmtChiAdd = ""
    Blank_HKIDR_Info2.d_BCAN = ""

    //cbMast default value
    BlankCB.d_acCode = ""
    BlankCB.d_Currency = ""
    BlankCB.d_BankInfo = ""
    BlankCB.d_BankACNo = ""

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setshowPenaltyInt( (GsbFlag.OVRMGN_INT) || ( GsbFlag.OVRLMT_INT) );
                //setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );

                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.CAT_STATUS.substr(i, 1).length > 0 ) {
                        let tmpCategoryDesc = " "
                        tmpCategoryDesc = GsbFlag.CAT_DESC[i]

                        CategoryList2.push({d_CategoryCode: GsbFlag.CAT_STATUS.substr(i, 1), d_CategoryDesc: tmpCategoryDesc})
                    }
                }
                setCategoryList(CategoryList2)
                //console.log("set CategoryList: ", CategoryList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                        setisViewClientDoc(false)
                        setisManageClientDoc(false)
                        setshowClientDoc(false)
                        if ( res.data.data[0].d_Can_ViewClientDoc == "Y" ) {
                            setisViewClientDoc(true)
                            setshowClientDoc(true)
                        }
                        if ( res.data.data[0].d_Can_ManageClientDoc == "Y" ) {
                            setisManageClientDoc(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

    const onReqViewClientDoc = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_ViewClientDoc == "Y") {
                        setshowClientDoc(true);
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }

    const onReqManageClientDoc = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_ManageClientDoc == "Y") {
                        setisManageClientDoc(true);
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }

    useEffect(() => {
        if (userKey === "CHEERFUL" || userKey === "BENDURA" || userKey === "DEMO") {
            setShowAdd4(true);
        }
    }, [gsb])


    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key === "CHEERFUL") {
                try {
                    //console.log("ClMastView ac.d_acCode: ", ac.d_acCode) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetHKIDR_Info2", cAcCode: ac.d_acCode})
                    //console.log("ClMastView GetHKIDR_Info2 data : ", res.data.data, res.data.count) 
                    //console.log("ClMastView GetHKIDR_Info2 data[0] : ", res.data.data[0], res.data.count) 
                    
                    if ( ac.d_acCode.trim().length > 0 && res.data.count > 0 )
                    {
                        setHKIDR_Info2(res.data.data[0])
                    } else {
                        setHKIDR_Info2(Blank_HKIDR_Info2)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [gsb, ac, ac.d_acCode])
    

    useEffect(() => {
        (async () => {
            try {
                //console.log("ClMastView ac.d_commCode: ", ac.d_commCode) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Commmast", cAction: "GetCommInfo", cCommCode: ac.d_commCode})
                //console.log("ClMastView GetCommInfo data : ", res.data.data, res.data.count) 
                //console.log("ClMastView GetCommInfo data[0] : ", res.data.data[0], res.data.count) 
                
                if ( ac.d_commCode.trim().length > 0 && res.data.count > 0 )
                {
                    setComm_Info(res.data.data[0])
                } else {
                    setComm_Info(Blank_Comm_Info)
                }
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, ac, ac.d_commCode])


    useEffect(() => {
        (async () => {
            try {
                //console.log("ClMastView ac.d_commCode: ", ac.d_intCode) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "IntRmast", cAction: "GetIntRInfo", cIntRCode: ac.d_intCode})
                //console.log("ClMastView GetIntRInfo data : ", res.data.data, res.data.count) 
                //console.log("ClMastView GetIntRInfo data[0] : ", res.data.data[0], res.data.count) 
                
                if ( ac.d_intCode.trim().length > 0 && res.data.count > 0 )
                {
                    setIntR_Info(res.data.data[0])
                } else {
                    setIntR_Info(Blank_IntR_Info)
                }
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, ac, ac.d_intCode])

    useEffect(() => {
        (async () => {
            setExtraFeeDesc("")
            if (GsbFlag.user_key === "CHEERFUL") {
                try {
                    //console.log("ClMastView ac.d_Extra_Fee: ", ac.d_Extra_Fee) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExtraFeeMast", cAction: "GetExtraFeeInfo",cExtraFee: ac.d_Extra_Fee})
                    //console.log("ClMastView GetExtraFeeList data : ", res.data.data, res.data.count) 
                    
                    if ( ac.d_Extra_Fee.trim().length > 0 && res.data.count > 0 )
                    {
                        setExtraFeeDesc("RATE: "+res.data.data[0].d_ExtraFee_Rate+ " MIN: "+res.data.data[0].d_ExtraFee_Min+ " MAX: "+res.data.data[0].d_ExtraFee_Max)
                    } else {
                        setExtraFeeDesc("")
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [gsb, ac, ac.d_Extra_Fee])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetRegionCodeList"})
                //console.log("ClMastView GetRegionCodeList data : ", res.data.data, res.data.count) 
                
                if ( res.data.count > 0 )
                {
                    setRegionCodeList(res.data.data)
                }
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/documentlist',
                {
                    acCode: ac.d_acCode,
                    idNo: ac.d_idNo
                });
                setDocList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
            setreloadDoc(false)
        })()
    }, [gsb, ac.d_acCode, reloadDoc])



    useEffect(() => {
        setCountryDesc("")
        let RegionItem3 = RegionCodeList.findIndex((RegionCode: RegionCodeInfo) => RegionCode.d_RegionCode === ac.d_RegionCode)
        //console.log("ClMastView RegionCodeList.find RegionItem3 : ", RegionItem3) 
        //console.log("ClMastView RegionCodeList.find RegionItem3.d_Country : ", RegionCodeList[RegionItem3]) 
        if ( RegionItem3 > 0 ) {
            //console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", RegionCodeList[RegionItem3].d_Country) 
            setCountryDesc(RegionCodeList[RegionItem3].d_Country)
        }

        //console.log("ClMastView RegionCodeList.find CountryDesc : ", CountryDesc) 
    }, [ac, ac.d_RegionCode])


    useEffect(() => {
        //console.log("set StatusList: ", StatusList)
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        //console.log("ClMastView StatusList.find StatusItem3 : ", StatusItem3) 
        //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc : ", StatusList[StatusItem3]) 
        if ( StatusItem3 > 0 ) {
            //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc2 : ", StatusList[StatusItem3].d_StatusDesc) 
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

        //console.log("ClMastView StatusList.find StatusDes : ", StatusDes) 
    }, [ac, ac.d_status])


    useEffect(() => {
        console.log("get CategoryList: ", CategoryList)
        setCategoryDesc("INDIVIDUAL")
        if ( ac.d_Category === " " ) {
            setCategoryDesc("INDIVIDUAL")
        } else {
            let CategoryItem3 = CategoryList.findIndex((CategoryCode: GetCategoryInfo) => CategoryCode.d_CategoryCode === ac.d_Category)
            console.log("ClMastView CategoryList.find CategoryItem3 : ", CategoryItem3) 
            console.log("ClMastView CategoryList.find CategoryItem3.d_CategoryDesc : ", CategoryList[CategoryItem3]) 
            if ( CategoryItem3 >= 0 ) {
                console.log("ClMastView CategoryList.find CategoryItem3.d_CategoryDesc 2 : ", CategoryList[CategoryItem3].d_CategoryDesc) 
                setCategoryDesc(CategoryList[CategoryItem3].d_CategoryDesc)
            }
        }

        console.log("ClMastView CategoryList.find CategoryDes : ", CategoryDesc) 
    }, [ac, ac.d_Category])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_acCode" in ac)) return null



    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    
    return (
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec() } >Prev</Button>
                <Button className="Col-2-1" disabled={ isReadOnly } icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={ isReadOnly } icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={ isReadOnly } icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }

				{ (GsbFlag.HKIDR_USER1) && <Button className="Col-7-1" icon="document" onClick={() => { setisHKIDRPress(true) } } >HKIDR</Button> }
                    <Dialog
                        icon={IconNames.EDIT} 
                        title = "HKIDR Master"
                        isOpen={isHKIDRPress}
                        onClose={ () => { setisHKIDRPress(false) } }
                        style={{ minWidth: '90.0%' , minHeight: '100.0%' }}
                    >
                        <DialogBody>
                        <CLHkidrMastView gsb={gsb} acCode={ac.d_acCode} ></CLHkidrMastView>
                        </DialogBody>
                    </Dialog>
                { (GsbFlag.GSBXHKCRS) && <Button className="Col-8-1" icon="document" onClick={() => { setisHKCRSPress(true) } } >HKCRS</Button> }
                    <Dialog
                            icon={IconNames.EDIT} 
                            title = "HKCRS Master"
                            isOpen={isHKCRSPress}
                            onClose={ () => { setisHKCRSPress(false) } }
                            style={{ minWidth: '90.0%' , minHeight: '100.0%' }}
                        >
                            <DialogBody>
                            <CLHkCrsMastView gsb={gsb} acCode={ac.d_acCode} ></CLHkCrsMastView>
                            </DialogBody>
                        </Dialog>
				{ (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) && <Button className="Col-9-2" icon="document" onClick={() => { setisForeignCurrencyPress(true) } } >Foreign Currency</Button> }
                    <Dialog
                        icon={IconNames.EDIT} 
                        title = "Client General Information (Foreign Currency)"
                        isOpen={isForeignCurrencyPress}
                        onClose={ () => { setisForeignCurrencyPress(false) } }
                        style={{ minWidth: '80.0%' }}
                    >
                        <DialogBody>
                        <ForeignCurrencyForm gsb={gsb} ac={ac} cb={BlankCB} cb0={BlankCB} StatusList={StatusList} CurrencyList={CurrencyList} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ForeignCurrencyForm> 
                        </DialogBody>
                    </Dialog>
{/*
                
                <FormGroup className="Col-11-2" label="">
                    <a target="_blank" rel="" href={`/#/AccInfo?acCode=${ac.d_acCode}`}>
                    <Button className="Col-11-2" icon="document" onClick={() => {} } >Balance & Portfolio</Button>
                    </a>
                </FormGroup>
*/}                
{/*             <Button className="Col-11-2" icon="document" onClick={() => { window.open(`/#/AccInfo?acCode=${ac.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
*/}
				<Button className="Col-11-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${ac.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
 
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {ac.d_acCode}
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={ac.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name">
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acName1.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acName1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acName2.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acName2} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No.">
                    <InputGroup readOnly value={ac.d_idNo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_idNo.toString() == "") && 
                    <div id="DispText2" > {ac0.d_idNo} </div> }
                </FormGroup>
                <FormGroup className="Col3-7-1" label="Status">
                    <InputGroup readOnly value={ac.d_status} />
                    <div>
                    <div id="DispText">{StatusDesc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_status.toString() == "") && 
                    <div id="DispText2" > {ac0.d_status} </div> }
                </FormGroup>
                <FormGroup className="Col-8-2" label="Open">
                    <InputGroup readOnly value= {utils.dispDate(ac.d_openDate, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_openDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(ac0.d_openDate, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                <FormGroup className="Col-10-2" label="Renew / GEM">
                    <InputGroup readOnly value={utils.dispDate(ac.d_renewDate, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_renewDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(ac0.d_renewDate, GsbFlag.dateFormat)} </div> }
                    <InputGroup readOnly value={utils.dispDate(ac.d_gemDate, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_gemDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(ac0.d_gemDate, GsbFlag.dateFormat)} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="" labelFor="">
                </FormGroup>
                {(GsbFlag.CHQ_NAME) && 
                    <FormGroup className="Col-2-3" label="Cheque Name" labelFor="inputChqName1">
                        <InputGroup readOnly value={ac.d_ChqName1} />
                        <InputGroup readOnly value={ac.d_ChqName2} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_ChqName1.toString() == "") && 
                        <div id="DispText2" > {ac0.d_ChqName1} </div> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_ChqName2.toString() == "") && 
                        <div id="DispText2" > {ac0.d_ChqName2} </div> }
                    </FormGroup>
                }
                {(GsbFlag.user_key === "CHEERFUL") && 
                    <FormGroup className="Col-2-3" label="Chinese Name" labelFor="inputChiName">
                        <InputGroup readOnly value={HKIDR_Info2.d_Chinese_Name} />
                    </FormGroup>
                }
                {(GsbFlag.SEL_CQPRT) && 
                    <FormGroup className="Col-5-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_PrintChq}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_PrintChq.toString() == "") && 
                        <div id="DispText2" > {ac0.d_PrintChq} </div> }
                    </FormGroup>
                }
                {(GsbFlag.CBBC_USER) && 
                    <FormGroup className="Col-7-2" label="Print CBBC Cheque" labelFor="inputPrintCBBCChq">
                        <RadioGroup
                            name="d_PrintCBBCChq"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_PrintCBBCChq}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_PrintCBBCChq.toString() == "") && 
                        <div id="DispText2" > {ac0.d_PrintCBBCChq} </div> }
                    </FormGroup>
                }
                <FormGroup className="Col-9-2" label="Print Div Cheque (For Margin Client)" labelFor="inputPrintDivChq">
                    <RadioGroup
                        name="d_PrintDivChq"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_PrintDivChq}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_PrintDivChq.toString() == "") && 
                    <div id="DispText2" > {ac0.d_PrintDivChq} </div> }
                </FormGroup>
                {(GsbFlag.user_key === "CHEERFUL") && 
                    <FormGroup className="Col-11-2" label="Custom_Term" labelFor="inputCustom_Term">
                        <RadioGroup
                            name="d_Custom_Term"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_Custom_Term}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Custom_Term.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Custom_Term} </div> }
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label="Address">
                    <InputGroup readOnly value={ac.d_acAddr1} />
                    <InputGroup readOnly value={ac.d_acAddr2} />
                    <InputGroup readOnly value={ac.d_acAddr3} />
                    { ShowAdd4 && <InputGroup readOnly value={ac.d_acAddr4} /> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acAddr1.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acAddr1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acAddr2.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acAddr2} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acAddr3.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acAddr3} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ShowAdd4 && ! (ac0.d_acAddr4.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acAddr4} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="Phone / Office">
                    <InputGroup readOnly value={ac.d_tel} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_tel.toString() == "") && 
                    <div id="DispText2" > {ac0.d_tel} </div> }
                    <InputGroup readOnly value={ac.d_offTel} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_offTel.toString() == "") && 
                    <div id="DispText2" > {ac0.d_offTel} </div> }
                </FormGroup>
                <FormGroup className="Col-7-2" label="Mobile / Fax">
                    <InputGroup readOnly value={ac.d_mobile} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_mobile.toString() == "") && 
                    <div id="DispText2" > {ac0.d_mobile} </div> }
                    <InputGroup readOnly value={ac.d_fax} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_fax.toString() == "") && 
                    <div id="DispText2" > {ac0.d_fax} </div> }
                </FormGroup>
                <FormGroup className="Col-9-3" label="Bank / AC No.">
                    <InputGroup readOnly value={ac.d_BankInfo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_BankInfo.toString() == "") && 
                    <div id="DispText2" > {ac0.d_BankInfo} </div> }
                    <InputGroup readOnly value={ac.d_BankACNo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_BankACNo.toString() == "") && 
                    <div id="DispText2" > {ac0.d_BankACNo} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                {(GsbFlag.CHQ_TO_BANK || GsbFlag.CCHQ_FILE || GsbFlag.DIVCHQ_TO_BANK) && <FormGroup className="Col-1-2" label="AutoPay Bank Code">
                    <InputGroup readOnly value={ac.d_Autopay_BankCode} /> 
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Autopay_BankCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Autopay_BankCode} </div> }
                </FormGroup> }
            </div>

            {(GsbFlag.user_key === "CHEERFUL") && <div className="Row">
                <FormGroup className="Col-1-4" label="Chinese Address">
                    <InputGroup readOnly value={HKIDR_Info2.d_ChineseAddress1} />
                    <InputGroup readOnly value={HKIDR_Info2.d_ChineseAddress2} />
                    <InputGroup readOnly value={HKIDR_Info2.d_ChineseAddress3} />
                    <InputGroup readOnly value={HKIDR_Info2.d_ChineseAddress4} />
                </FormGroup>
                <FormGroup className="Col-5-3" label="Select Chinese Address/Name on Statement" labelFor="inputStmtChiAdd">
                    <RadioGroup
                        name="d_StmtChiAdd"
                        disabled={true}
                        inline={true}
                        selectedValue={HKIDR_Info2.d_StmtChiAdd}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-8-2" label="BCAN No.(HKIDR)">
                    <InputGroup readOnly value={HKIDR_Info2.d_BCAN} />
                </FormGroup>
            </div> }

            <div className="Row">
                <FormGroup className="Col-1-1" label="Runner">
                    <InputGroup readOnly value={ac.d_aeCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_aeCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_aeCode} </div> }
                </FormGroup>
                <FormGroup className="Col-2-4" label="E-Mail">
                    <InputGroup readOnly value={ac.d_email} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_email.toString() == "") && 
                    <div id="DispText2" > {ac0.d_email} </div> }
                </FormGroup>
                <FormGroup className="Col-6-2" label="Send e-mail Statement" labelFor="inputSendemail">
                    <RadioGroup
                        name="d_sendemail"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_sendemail}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_sendemail.toString() == "") && 
                    <div id="DispText2" > {ac0.d_sendemail} </div> }
                </FormGroup>
                <FormGroup className="Col-8-2" label="Skip Hard Copy Statement" labelFor="inputHardCopyStmt">
                    <RadioGroup
                        name="d_HardCopyStmt"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_HardCopyStmt}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_HardCopyStmt.toString() == "") && 
                    <div id="DispText2" > {ac0.d_HardCopyStmt} </div> }
                </FormGroup>
                {(GsbFlag.HC_MTH_STMT) && 
                    <FormGroup className="Col-10-2" label="Skip Monthly Statement" labelFor="inputHardCopyMthStmt">
                        <RadioGroup
                            name="d_HardCopyMthStmt"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_HardCopyMthStmt}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_HardCopyMthStmt.toString() == "") && 
                        <div id="DispText2" > {ac0.d_HardCopyMthStmt} </div> }
                    </FormGroup>
                }
                { ( GsbFlag.user_key === "CHEERFUL" ) && 
                    <FormGroup className="Col3-10-1" label="Mailling" labelFor="inputMailing">
                        <InputGroup readOnly value={ac.d_Mailing} />
                        { ac.d_Mailing === "M" && <div id="DispText" >By Mail</div> }
                        { ac.d_Mailing === "S" && <div id="DispText" >Self Collect</div> }
                        { ac.d_Mailing === "O" && <div id="DispText" >Self CollectOversea</div> }
                        { ac.d_Mailing === "F" && <div id="DispText" >By Mail *</div> }
                        { ac.d_Mailing === " " && <div id="DispText" >*Undefine*</div> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Mailing.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Mailing} </div> }
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Commission">
                    <InputGroup readOnly value={ac.d_commCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_commCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_commCode} </div> }
                </FormGroup>
                <FormGroup className="Col-2-1" label="Interest">
                    <InputGroup readOnly value={ac.d_intCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_intCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_intCode} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Loan Limit">
                    <InputGroup readOnly value={utils.formatNumber2(ac.d_loanLimit)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_loanLimit.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(ac0.d_loanLimit)} </div> }
                </FormGroup>
                { (! GsbFlag.MGNGP_USER) &&
                    <FormGroup className="Col-5-1" label="Margin Ratio">
                        <InputGroup readOnly value={utils.formatNumber2(ac.d_MarginRatio)} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_MarginRatio.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(ac0.d_MarginRatio)} </div> }
                    </FormGroup>
                }
                {(GsbFlag.MGNGP_USER) &&
                    <FormGroup className="Col-5-1" label="Margin Group">
                        <InputGroup readOnly value={ac.d_MarginGroup} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_MarginGroup.toString() == "") && 
                        <div id="DispText2" > {ac0.d_MarginGroup} </div> }
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-12" >
                    <div>
                    <div id="DispText">Comm Rate: {Comm_Info.d_CommRate} Min Comm: {Comm_Info.d_MinComm} Rebate Rate: {Comm_Info.d_RebateRate} Min Rebate: {Comm_Info.d_MinRebate} Net Comm: {Comm_Info.d_NetComm}</div>
                    </div>
                    <div>
                    { ( ! showPenaltyInt || ! (ac.d_acCode.substring(0,1) == "M") )  && <div id="DispText">DB Int Rate: {utils.formatNumber2(IntR_Info.d_Eff_DB_Rate)} CR Int Rate: {utils.formatNumber2(IntR_Info.d_Eff_CR_Rate)} </div> }
                    { showPenaltyInt && ac.d_acCode.substring(0,1) == "M" && <div id="DispText">DB Int Rate: {utils.formatNumber2(IntR_Info.d_Eff_DB_Rate)} CR Int Rate: {utils.formatNumber2(IntR_Info.d_Eff_CR_Rate)} Penalty Interest Rate: {utils.formatNumber2(IntR_Info.d_Penalty_DB_Rate)} </div> }
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Allow High Risk" labelFor="inputHighRisk">
                    <RadioGroup
                        name="d_HighRisk"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_HighRisk}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_HighRisk.toString() == "") && 
                        <div id="DispText2" > {ac0.d_HighRisk} </div> }
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-3-2" label="High Risk verified on" labelFor="inputHighRiskDate">
                    <InputGroup readOnly value= {utils.dispDate(ac.d_HighRiskDate, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_HighRiskDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(ac0.d_HighRiskDate, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                {(GsbFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Auto Calc Average Price" labelFor="inputAutoCalcAvgPrice">
                        <RadioGroup
                            name="d_AutoCalcAvgPrice"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_AutoCalcAvgPrice}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_AutoCalcAvgPrice.toString() == "") && 
                        <div id="DispText2" > {ac0.d_AutoCalcAvgPrice} </div> }
                    </FormGroup>
                }
                {(GsbFlag.MULTI_PRCE) && (! GsbFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Print Detail Transaction" labelFor="inputPrnDetailTran">
                        <RadioGroup
                            name="d_PrnDetailTran"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_PrnDetailTran}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_PrnDetailTran.toString() == "") && 
                        <div id="DispText2" > {ac0.d_PrnDetailTran} </div> }
                    </FormGroup>
                }
                {(GsbFlag.SEL_CCASS) && 
                    <FormGroup className="Col-7-2" label="Charge CCASS Fee" labelFor="inputCharge_CCASS_Fee">
                        <RadioGroup
                            name="d_Charge_CCASS_Fee"
                            disabled={true}
                            inline={true}
                            selectedValue={ac.d_Charge_CCASS_Fee}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Charge_CCASS_Fee.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Charge_CCASS_Fee} </div> }
                    </FormGroup>
                }
            </div>
            {(GsbFlag.ChrgPortFee) && <div className="Row">
                <FormGroup className="Col-1-2" label="Charge Custody Fee" labelFor="input_ChgPortfolioFee">
                    <RadioGroup
                        name="d_ChgPortfolioFee"
                        disabled={true}
                        inline={true}
                        selectedValue={ac.d_ChgPortfolioFee}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_ChgPortfolioFee.toString() == "") && 
                        <div id="DispText2" > {ac0.d_ChgPortfolioFee} </div> }
                </FormGroup>
            </div> }
            <div className="Row">
                {( GsbFlag.MU_BRANCH ) &&
                    <FormGroup className="Col3-1-1" label="Location" labelFor="inputLocation">
                        <InputGroup readOnly value={ac.d_Location} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Location.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Location} </div> }
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-2-1" label="Branch Code" labelFor="inputBranchEmail">
                        <InputGroup readOnly value={ac.d_Branch_code} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Branch_code.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Branch_code} </div> }
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-3-3" label="Extra Fee" labelFor="inputExtra_Fee">
                        <InputGroup readOnly value={ac.d_Extra_Fee} />
                        <div>
                        <div id="DispText">{ExtraFeeDesc}</div>
                        </div>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Extra_Fee.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Extra_Fee} </div> }
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-6-3" label="Plan No. of Dividend Charges" labelFor="inputPlan_no">
                        <InputGroup readOnly value={ac.d_Plan_no} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Plan_no.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Plan_no} </div> }
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Region code" labelFor="inputRegionCode">
                    <InputGroup readOnly value={ac.d_RegionCode} />
                    <div id="DispText">{CountryDesc}</div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_RegionCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_RegionCode} </div> }
                </FormGroup>
                <FormGroup className="Col3-3-3" label="Professional Investors" labelFor="inputProfessional_Investor">
                    <InputGroup readOnly value={ac.d_Professional_Investor} />
                    { ac.d_Professional_Investor === "1" && <div id="DispText" >Institutional</div> }
                    { ac.d_Professional_Investor === "2" && <div id="DispText" >Corporate</div> }
                    { ac.d_Professional_Investor === "3" && <div id="DispText" >Individual</div> }
                    { ac.d_Professional_Investor === "4" && <div id="DispText" >Others</div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Professional_Investor.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Professional_Investor} </div> }
                </FormGroup>
                {( GsbFlag.CL_CATEGY ) && <FormGroup className="Col3-6-2" label="Category">
                    <InputGroup readOnly value={ac.d_Category} />
                    <div>
                    <div id="DispText">{CategoryDesc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Category.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Category} </div> }
                </FormGroup> }
            </div>
            {( GsbFlag.SI_USER ) && ! showSIMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSIMore(! showSIMore) }} >+ Show PART-ID & CUSTODY A/C FOR "SI" </div> }
            {( GsbFlag.SI_USER ) && showSIMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSIMore(! showSIMore) }} >- PART-ID & CUSTODY A/C FOR "SI" </div> }
            {( GsbFlag.SI_USER && showSIMore ) && <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Part-ID No." labelFor="inputd_Part_ID">
                    <InputGroup readOnly value={ac.d_Part_ID} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Part_ID.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Part_ID} </div> }
                </FormGroup>
                <FormGroup className="Col-3-1" label="Sender BIC" labelFor="inputd_Sender_BIC">
                    <InputGroup readOnly value={ac.d_Sender_BIC} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Sender_BIC.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Sender_BIC} </div> }
                </FormGroup>
                <FormGroup className="Col-4-2" label="Custody A/C No." labelFor="inputd_Custody_AC">
                <InputGroup readOnly value={ac.d_Custody_AC} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Custody_AC.toString() == "") && 
                    <div id="DispText2" > {ac0.d_Custody_AC} </div> }
                </FormGroup>
                <FormGroup className="Col-6-2" label="Payment Terms" labelFor="inputd_Payment_Terms">
                    <InputGroup readOnly value={ac.d_Payment_Terms} />
                        { ac.d_Payment_Terms === "D" && <div id="DispText" >D-DVP</div> }
                        { ac.d_Payment_Terms === "F" && <div id="DispText" >F-FOP</div> }
                        { ac.d_Payment_Terms === "R" && <div id="DispText" >R-RDVP</div> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_Payment_Terms.toString() == "") && 
                        <div id="DispText2" > {ac0.d_Payment_Terms} </div> }
                </FormGroup>
            </div> }
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-6" label="Footnote">
                    <TextArea readOnly fill rows={4} value={ac.d_footnote} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_footnote.toString() == "") && 
                    <div id="DispText2" > {ac0.d_footnote} </div> }
                </FormGroup>
            </div>

            { ViewMode === "V" && <div>
            { isViewClientDoc && ! showClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowClientDoc(! showClientDoc) }} >Show Documents</div> }
            { ! isViewClientDoc && ! showClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setisViewClientDocPress(true) }} >Show Documents</div> }
            <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isViewClientDocPress}
                    onCancel={ () => setisViewClientDocPress(false) }
                    onConfirm={ () => { onReqViewClientDoc(); setisViewClientDocPress(false) } }
                >
                    <div>
                        <div>Authorization Required to View Document</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>

            { showClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowClientDoc(! showClientDoc) }} >- Documents</div> }
                {/* showClientDoc && <p className="No-Print">Documents</p> */}
                { showClientDoc && <div className="Row No-Print">
                    {docList.map((doc, i) =>
                        <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload?doctype=${doc.docType}&filename=${encodeURIComponent(doc.fileName)}&acCode=${ac.d_acCode}&inline`}>{getDocText(doc.docType)}</AnchorButton>
                    )}

                    { ! isManageClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setisManageClientDocPress(true) }} >Show Manage Document</div> }
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="OK"
                        canEscapeKeyCancel={true}
                        icon="warning-sign"
                        intent={Intent.WARNING}
                        isOpen={isManageClientDocPress}
                        onCancel={ () => setisManageClientDocPress(false) }
                        onConfirm={ () => { onReqManageClientDoc(); setisManageClientDocPress(false) } }
                    >
                        <div>
                            <div>Authorization Required to Manage Document</div>
                            <div>Please Input User & Password</div>
                        </div>
                        <div className="Spacing-V-16"></div>
                        <FormGroup label="User" labelFor="inputAuthUser">
                            <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                        </FormGroup>
                        <FormGroup label="Password" labelFor="inputPassword">
                            <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                        </FormGroup>
                    </Alert>

                    { isManageClientDoc &&<Button icon={IconNames.UPLOAD} onClick={() => { setisUploadDocPress(true) } } >Manage Document</Button> }
                        <Dialog
                            icon={IconNames.UPLOAD} 
                            title = "Manage Document"
                            isOpen={isUploadDocPress}
                            onClose={ () => { setisUploadDocPress(false) ; setreloadDoc(true) } }
                            style={{ minWidth: '80.0%' }}
                        >
                            <DialogBody>
                            <DocUploadForm gsb={gsb} ac={ac} ViewMode={ViewMode} logKey2={logKey2.trim()} ></DocUploadForm> 
                            </DialogBody>
                        </Dialog>
                </div> }
            </div> }
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}


interface ForeignCurrencyFormProps {
    gsb: AxiosInstance,
    ac: Account,
    cb: cbMast,
    cb0: cbMast,
    StatusList?: StatusInfo[],
    CurrencyList?: CurrencyInfo[],
    ViewMode: any,
    logKey2: any,
}


function ForeignCurrencyForm({ gsb, ac = {} as Account, cb = {} as cbMast, cb0 = {} as cbMast, StatusList=[] as StatusInfo[], CurrencyList=[] as CurrencyInfo[], ViewMode, logKey2 }: ForeignCurrencyFormProps) {

    const { t, i18n } = useTranslation();

    const { register, reset, setError, errors } = useForm<cbMast>()

    const [currentCB, setcurrentCB] = useState({} as cbMast)
    const [LastCB, setLastCB] = useState({} as cbMast)
    const [BlankCB, setBlankCB] = useState({} as cbMast)
    const [NoRecCB, setNoRecCB] = useState(false)
    const [Comm_Info2, setComm_Info2] = useState({} as CommInfo)
    const [BlankComm_Info2, setBlankComm_Info2] = useState({} as CommInfo)
    const [Comm_Info3, setComm_Info3] = useState([] as CommInfo[])

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [showSIMore, setshowSIMore] = useState(true)

    const [isForeignCurrencyPress, setisForeignCurrencyPress] = useState(false)
    const [isForeignCurrencyNew, setisForeignCurrencyNew] = useState(false)
    const [isForeignCurrencyEdit, setisForeignCurrencyEdit] = useState(false)
    const [isForeignCurrencyDeletePress, setisForeignCurrencyDeletePress] = useState(false)

    const [StatusDesc, setStatusDesc] = useState("")

    const [CurrencyValue, setCurrencyValue] = useState("")
    const [BankInfoValue, setBankInfoValue] = useState("")
    const [BankACNoValue, setBankACNoValue] = useState("")



    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [showCOMM_MKT, setshowCOMM_MKT] = useState(false)

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    //cbMast default value
    BlankCB.d_acCode = ""
    BlankCB.d_Currency = ""
    BlankCB.d_BankInfo = ""
    BlankCB.d_BankACNo = ""

    if ( ViewMode === "L" ) {
        ac.d_acCode = cb.d_acCode
        if ( typeof(cb0.d_BankInfo) === "undefined") {
            cb0.d_BankInfo = ""
        }
        if ( typeof(cb0.d_BankACNo) === "undefined") {
            cb0.d_BankACNo = ""
        }
    }


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

                //setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );
                setshowCOMM_MKT(GsbFlag.COMM_MKT)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])


    useEffect(() => {
        //console.log("set StatusList: ", StatusList)
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        //console.log("ClMastView StatusList.find StatusItem3 : ", StatusItem3) 
        //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc : ", StatusList[StatusItem3]) 
        if ( StatusItem3 > 0 ) {
            //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc2 : ", StatusList[StatusItem3].d_StatusDesc) 
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

        //console.log("ClMastView StatusList.find StatusDes : ", StatusDes) 
    }, [ac, ac.d_status])

        
    useEffect(() => {
        (async () => {
            if ( GsbFlag.MULTI_MKT && ( ! GsbFlag.SING_CURR) && ViewMode === "V" ) {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Cbmast", cAction: "GetCbACInfo", cAcCode: ac.d_acCode})
                    console.log("ClMastView Cbmast res.data.data : ", res.data.data) 
                    if ( ac.d_acCode.trim().length > 0 && res.data.count > 0 )
                    {
                        setcurrentCB(res.data.data[0])
                        setLastCB(res.data.data[0])
                        setNoRecCB(false)
                        setComm_Info2(res.data.data[0].d_CommInfo2)
                        setComm_Info3(res.data.data[0].d_CommInfo3)
                    } else {
                        BlankCB.d_acCode = ac.d_acCode
                        setcurrentCB(BlankCB)
                        setLastCB(BlankCB)
                        setNoRecCB(true)
                        setComm_Info2(BlankComm_Info2)
                        setComm_Info3([])
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [gsb, ac, ac.d_acCode])
    

    useEffect(() => {
        (async () => {
            if ( ViewMode === "L" ) {
                try {
                    // get AC Info
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: cb.d_acCode})
                    
                    if ( cb.d_acCode.trim().length > 0 && res.data.count > 0 )
                    {
                        ac.d_acName1 = res.data.data[0].d_acName1
                        ac.d_acName2 = res.data.data[0].d_acName2
                        ac.d_status = res.data.data[0].d_status

                    } else { 
                        ac.d_acName1 = ""
                        ac.d_acName2 = ""
                        ac.d_status = ""
                    }
                
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()

    }, [gsb, cb, cb.d_acCode])

    useEffect(() => { 
        if ( ViewMode === "V" ) {
            reset(currentCB);
            setCurrencyValue(currentCB.d_Currency)
            setBankInfoValue(currentCB.d_BankInfo)
            setBankACNoValue(currentCB.d_BankACNo)
        }

        if ( ViewMode === "L" ) {
            setCurrencyValue(cb.d_Currency)
            setBankInfoValue(cb.d_BankInfo)
            setBankACNoValue(cb.d_BankACNo)
        }

    }, [gsb, ac, ac.d_acCode, cb, cb.d_acCode, currentCB, currentCB.d_Currency, isForeignCurrencyEdit])
    


    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }



    const onForeignCurrencyPrevRecProcess = async () => {
        try {
            const res = await gsb.post('/Master', {sb_data: currentCB, cPgmId: "Cbmast", cAction: "PrevRec"})
            //console.log("ClMastView Cbmast PrevRec res.data.data : ", res.data.data) 
            if ( res.data.count > 0 )
            {
                setcurrentCB(res.data.data[0])
                setLastCB(res.data.data[0])
                setNoRecCB(false)
                setComm_Info2(res.data.data[0].d_CommInfo2)
                setComm_Info3(res.data.data[0].d_CommInfo3)
            } else {
                BlankCB.d_acCode = ac.d_acCode
                setcurrentCB(BlankCB)
                setLastCB(BlankCB)
                setNoRecCB(true)
                setComm_Info2(BlankComm_Info2)
                setComm_Info3([])
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onForeignCurrencyDelete = ( ) => {

    }

    const onForeignCurrencyNextRecProcess = async () => {
        try {
            const res = await gsb.post('/Master', {sb_data: currentCB, cPgmId: "Cbmast", cAction: "NextRec"})
            //console.log("ClMastView Cbmast NextRec res.data.data : ", res.data.data) 
            if ( res.data.count > 0 )
            {
                setcurrentCB(res.data.data[0])
                setLastCB(res.data.data[0])
                setNoRecCB(false)
                setComm_Info2(res.data.data[0].d_CommInfo2)
                setComm_Info3(res.data.data[0].d_CommInfo3)
            } else {
                BlankCB.d_acCode = ac.d_acCode
                setcurrentCB(BlankCB)
                setLastCB(BlankCB)
                setNoRecCB(true)
                setComm_Info2(BlankComm_Info2)
                setComm_Info3([])
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onForeignCurrencySave1 = ( ) => {
        currentCB.d_acCode = ac.d_acCode
        currentCB.d_Currency = CurrencyValue
        currentCB.d_BankInfo = BankInfoValue
        currentCB.d_BankACNo = BankACNoValue

        currentCB.d_AuthTran = ""
        if (isReqAuth) {
            currentCB.d_AuthTran = "Y"
        }

        ;
        (async () => {
            try {
                //console.log("onForeignCurrencySave1 currentCB", currentCB)
                const res = await gsb.post('/Master', {sb_data: currentCB, cPgmId: "Cbmast", cAction: "CbValidate", isNew: isForeignCurrencyNew})
                //console.log("onForeignCurrencySave1 CbValidate res.data.data", res.data.data)
                ;
                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    setisForeignCurrencyEdit(true)
                    return
                }

                onForeignCurrencySave2(currentCB)

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    const onForeignCurrencySave2 = (CBdata: cbMast) => {
        (async () => {
            try {
                //console.log("onForeignCurrencySave2 CBdata", CBdata)
                var res1_data = {} as cbMast
                if ( isForeignCurrencyNew ) {
                    const res = await gsb.post('/Master', {sb_data: CBdata, cPgmId: "Cbmast", cAction: "AddRec"})
                    res1_data = res.data.data[0]
                } else {
                    const res = await gsb.post('/Master', {sb_data: CBdata, cPgmId: "Cbmast", cAction: "UpdateRec"})
                    res1_data = res.data.data[0]
                }
                //console.log("onForeignCurrencySave2 save res1.data.data", res1_data)
                setcurrentCB(res1_data)
                setLastCB(res1_data)
                setisForeignCurrencyEdit(false)

                if (isReqAuth2) {
                    utils.showSuccess("Account No. " + res1_data.d_acCode +","+ res1_data.d_Currency + " record saving, waiting for authorization")

                    setisShowAlert1(true)
                    setShowAlert1Msg("Account No. " + res1_data.d_acCode +","+ res1_data.d_Currency + " record saving, waiting for authorization")
                } else {
                    utils.showSuccess("Account No. " + res1_data.d_acCode +","+ res1_data.d_Currency + " record saved")

                    setisShowAlert1(true)
                    setShowAlert1Msg("Account No. " + res1_data.d_acCode +","+ res1_data.d_Currency + " record saved")
                }

            } catch (error) {
                //utils.showError(error)
                showErrorBox(error)
            }
        })()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_acCode" in ac)) return null


    return (
        <form>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>Foreign Currency</H3></div>
                { ! isForeignCurrencyEdit && ViewMode === "V" && <div>
                    <Button className="Col-1-1" disabled={NoRecCB} icon="direction-left" onClick={() => onForeignCurrencyPrevRecProcess()} >Prev</Button>
                    <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => { BlankCB.d_acCode = ac.d_acCode; setcurrentCB(BlankCB); setisForeignCurrencyNew(true) ; setisForeignCurrencyEdit(true) } } >New</Button>
                    <Button className="Col-3-1" disabled={isReadOnly || NoRecCB} icon="edit" onClick={() => { setisForeignCurrencyNew(false) ; setisForeignCurrencyEdit(true) } } >Edit</Button>
                    <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisForeignCurrencyDeletePress(true) } >Delete</Button>
                        <Alert
                            cancelButtonText="Cancel"
                            confirmButtonText="Delete"
                            canEscapeKeyCancel={true}
                            icon="trash"
                            intent={Intent.DANGER}
                            isOpen={isForeignCurrencyDeletePress}
                            onCancel={ () => setisForeignCurrencyDeletePress(false) }
                            onConfirm={ () => { onForeignCurrencyDelete(); setisForeignCurrencyDeletePress(false) } }
                        >
                            <p>
                                Are you sure to Delete ?
                            </p>
                        </Alert>
                    <Button className="Col-5-1" disabled={NoRecCB} icon="direction-right" onClick={() => onForeignCurrencyNextRecProcess()} >Next</Button>
                    { isReqAuth && 
                        <Callout className="Col-6-2"
                            icon="warning-sign"
                            intent={Intent.WARNING}
                        >
                            Authorization Required
                        </Callout>
                    }
                </div> }
                { isForeignCurrencyEdit && ViewMode === "V" && <div>
                    <Button className="Col-1-1" icon="cross" onClick={() => { setcurrentCB(LastCB); setisForeignCurrencyEdit(false) } }>Cancel</Button>
                    <Button className="Col-2-1" icon="tick" onClick={() => { onForeignCurrencySave1() } }>Save</Button>
                    { isReqAuth && 
                        <Callout className="Col-6-2"
                            icon="warning-sign"
                            intent={Intent.WARNING}
                        >
                            Authorization Required
                        </Callout>
                    }
                </div> }
                { ViewMode === "L" && <div>
                    Account No. : {cb.d_acCode}
                    { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                    { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                    { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                    <div className="Spacing-V-16" />
                </div> }

                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                        <InputGroup readOnly value={ac.d_acCode} />
                    </FormGroup>
                    <FormGroup className="Col-3-3" label="Client Name">
                        <InputGroup readOnly value={ac.d_acName1} />
                        <InputGroup readOnly value={ac.d_acName2} />
                    </FormGroup>
                    <FormGroup className="Col3-6-1" label="Status">
                        <InputGroup readOnly value={ac.d_status} />
                        <div>
                        <div id="DispText">{StatusDesc}</div>
                        </div>
                    </FormGroup>
                </div>
                <div className="Row">
                    { ! isForeignCurrencyEdit && <FormGroup className="Col3-1-1" label="Currency" labelFor="inputCurrency">
                        <InputGroup readOnly value={CurrencyValue} />
                    </FormGroup> }
                    { isForeignCurrencyEdit && <FormGroup className="Col3-1-1" label="Currency" labelFor="inputCurrency">
                        <Suggest2
                            disabled={! isForeignCurrencyNew}
                            inputProps={{ placeholder: "Currency ..." }}
                            defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                            items={CurrencyList}
                            itemRenderer={CurrencyRenderer}
                            itemPredicate={CurrencyPredicate}
                            inputValueRenderer={CurrencyValueRenderer}
                            onItemSelect={(CurrencyList) => { setCurrencyValue(CurrencyList.d_Currency) }}
                        />
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isForeignCurrencyEdit && <FormGroup className="Col-1-3" label="Bank">
                        <InputGroup readOnly value={BankInfoValue} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (cb0.d_BankInfo.toString() == "") && 
                        <div id="DispText2" > {cb0.d_BankInfo} </div> }
                    </FormGroup> }
                    { isForeignCurrencyEdit && <FormGroup className="Col-1-3" label="Bank">
                        <InputGroup id="inputBankInfo" value={BankInfoValue} autoFocus={true} placeholder="Bank ..." onChange={utils.handleStringChange(s => setBankInfoValue(s)) } inputRef={register({ }) } />
                        {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isForeignCurrencyEdit && <FormGroup className="Col-1-3" label="AC No.">
                        <InputGroup readOnly value={BankACNoValue} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (cb0.d_BankACNo.toString() == "") && 
                        <div id="DispText2" > {cb0.d_BankACNo} </div> }
                    </FormGroup> }
                    { isForeignCurrencyEdit && <FormGroup className="Col-1-3" label="AC No.">
                        <InputGroup id="inputBankInfo" value={BankACNoValue} placeholder="AC No ..." onChange={utils.handleStringChange(s => setBankACNoValue(s)) } inputRef={register({ }) } />
                        {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                    </FormGroup> }
                </div>

                <div className="Spacing-V-16" />
                { ! isForeignCurrencyEdit && ViewMode === "V" && <div className="Row">
                    <FormGroup className="Col-1-2" label="Commission Code" labelInfo="">
                        <InputGroup readOnly value={Comm_Info2.d_CommCode} />
                    </FormGroup>
                </div> }
                { ! isForeignCurrencyEdit && ViewMode === "V" && <div className="Row">
                    <FormGroup className="Col-1-12" >
                        <div>
                        <div id="DispText">Comm Rate: {Comm_Info2.d_CommRate} Min Comm: {Comm_Info2.d_MinComm} Rebate Rate: {Comm_Info2.d_RebateRate} Min Rebate: {Comm_Info2.d_MinRebate} Net Comm: {Comm_Info2.d_NetComm}</div>
                        </div>
                    </FormGroup>
                </div> }
                <div className="Spacing-V-16" />
                { ! isForeignCurrencyEdit && showCOMM_MKT && ViewMode === "V" && <div className="Row">
                    {Comm_Info3.map((item, i) =>
                        <FormGroup className="Col-1-12" >
                            <div>
                            <div>Currency: {item.d_Currency} Market: {item.d_Market}</div>
                            <div id="DispText">Comm Rate: {item.d_CommRate} Min Comm: {item.d_MinComm} Rebate Rate: {item.d_RebateRate} Min Rebate: {item.d_MinRebate} Net Comm: {item.d_NetComm}</div>
                            </div>
                        </FormGroup>
                    )}
                </div> }

                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </form>
    )

}


interface DocUploadFormProps {
    gsb: AxiosInstance,
    ac: Account,
    ViewMode: any,
    logKey2: any,
}


function DocUploadForm({ gsb, ac = {} as Account, ViewMode, logKey2 }: DocUploadFormProps) {

    const { t, i18n } = useTranslation();
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [docList, setDocList] = useState([] as Document[])
    const [showButton, setshowButton] = useState(false)
    const [ShowWait, setShowWait] = useState(false)

    const [file1Label, setFile1Label] = useState("Choose file...")
    const [file2Label, setFile2Label] = useState("Choose file...")
    const [file3Label, setFile3Label] = useState("Choose file...")
    const [importok, setimportok] = useState(false)
    const [nUploaded, setnUploaded] = useState(0)

    const [file1docType, setfile1docType] = useState("id")
    const [file2docType, setfile2docType] = useState("Sign")
    const [file3docType, setfile3docType] = useState("Contract")

    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    const refreshDocTasks = async () => {
        try {
            const res = await gsb.post('/documentlist',
            {
                acCode: ac.d_acCode,
                idNo: ac.d_idNo
            });
            //setDocList(res.data.data)

            let newData = [...res.data.data]

            for (let j = 0; j < newData.length; j++) {
                newData[j].d_select = "N" ;
            }
            setDocList(newData);

        } catch (error) {
            utils.showError(error)
        }
    }

    useEffect(() => {
        refreshDocTasks();
    }, [gsb, ac.d_acCode])

    const onUpload = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setnUploaded(0);

        if ( (file1Label == "Choose file..."  || file1Label.length == 0) && (file2Label == "Choose file..."  || file2Label.length == 0) && (file3Label == "Choose file..."  || file3Label.length == 0)) {
            //utils.showError("Please choose a file"); 
            setisShowAlert2(true)
            setShowAlert2Msg("Please choose a file")
            return
        } 
        ;
        
        (async () => {
            try {
                const res = await gsb.post('/importupload2',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });


                
                if ( res.data.uploaded > 0) {
                    setimportok(true)
                }
     
                setnUploaded(res.data.uploaded)
              
                //utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)
                //setisShowAlert1(true)
                //setShowAlert1Msg(`${res.data.uploaded} file(s) uploaded, Please press Process button`)

                try {
                    const res = await gsb.post('/import',
                        {   sb_data: {
                            acCode: ac.d_acCode,
                            idNo: ac.d_idNo,
                            impfile1Name: file1Label,
                            docType1: file1docType,
                            impfile2Name: file2Label,
                            docType2: file2docType,
                            impfile3Name: file3Label,
                            docType3: file3docType,
                        },
                        cPgmId: "ImportClDoc",
                    }); 
                    
                    if (res.data.ok ){
                        setimportok(false)
                        if ( res.data.count > 0) {
                            setFile1Label("Choose file...")
                            setFile2Label("Choose file...")
                            setFile3Label("Choose file...")
                            refreshDocTasks();
                            setisShowAlert1(true)
                            setShowAlert1Msg(`${res.data.count} file(s) success Processed`)
                        }
                    } else {
                        if (! res.data.ok) {
                            if ( res.data.err_Msg.trim().length > 0 ) {
                                setisShowAlert2(true)
                                setShowAlert2Msg(res.data.err_Msg)
                            }
                            for (let j = 0; j < res.data.data.length; j++) {
                                utils.showError("error");
                            }
                            return
                        }
                    }
                } catch (error) {
                    showErrorBox(error)
                }

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    const onImportFiles = () => {

        if ( (file1Label == "Choose file..."  || file1Label.length == 0) && (file2Label == "Choose file..."  || file2Label.length == 0) && (file3Label == "Choose file..."  || file3Label.length == 0)) {
            //utils.showError("Please choose a file"); 
            setisShowAlert2(true)
            setShowAlert2Msg("Please choose a file")
            return
        } 
        //console.log("onImportFiles file1Label", file1Label)
        ;
        if ( file1Label.length > 0 || file2Label.length > 0 || file3Label.length > 0) {
    
            (async () => {
                    try {
                        const res = await gsb.post('/import',
                            {   sb_data: {
                                acCode: ac.d_acCode,
                                idNo: ac.d_idNo,
                                impfile1Name: file1Label,
                                docType1: file1docType,
                                impfile2Name: file2Label,
                                docType2: file2docType,
                                impfile3Name: file3Label,
                                docType3: file3docType,
                            },
                            cPgmId: "ImportClDoc",
                        }); 
                        
                        if (res.data.ok ){
                            setimportok(false)
                            if ( res.data.count > 0) {
                                refreshDocTasks();
                                setisShowAlert1(true)
                                setShowAlert1Msg(`${res.data.count} file(s) success Processed`)
                            }
                        } else {
                            if (! res.data.ok) {
                                if ( res.data.err_Msg.trim().length > 0 ) {
                                    setisShowAlert2(true)
                                    setShowAlert2Msg(res.data.err_Msg)
                                }
                                for (let j = 0; j < res.data.data.length; j++) {
                                    utils.showError("error");
                                }
                                return
                            }
                        }
                    } catch (error) {
                        showErrorBox(error)
                    }
                })()
        }else {

            utils.showError("Please choose a file"); 

        }
    }

    const onSelectDoc = (fileName: string) => {
        if (fileName.length > 0 ) {
            let newData = [...docList]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].fileName == fileName ) {
                    if ( newData[j].d_select == "Y" ) {
                        newData[j].d_select = "N"
                    } else {
                        newData[j].d_select = "Y"
                    }
                    break
                }
            }
            setDocList(newData);
            setshowButton(true)
        }
    }

    const onDeleteSelectDoc = () => {
        setShowWait(true)
        setshowButton(false)
        ;

        //console.log("onDeleteSelectDoc docList", docList);
        (async () => {
            try {
                const res = await gsb.post('/import',
                    {   sb_data: {
                        acCode: ac.d_acCode,
                        idNo: ac.d_idNo,
                        docList: docList, 
                    },
                    cPgmId: "DeleteClDoc",
                }); 

                if ( res.data.ok ) {
                    if ( res.data.count > 0) {
                        refreshDocTasks();
                        setisShowAlert1(true)
                        setShowAlert1Msg(`${res.data.count} file(s) success Deleted`)
                    }
                } else {
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.err_Msg)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    return (
        <>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>Manage Client Document</H3></div>
                <form className="Import" onSubmit={onUpload}>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button disabled={! showButton} className="Col-1-3" icon="confirm" onClick={() => { onDeleteSelectDoc() } }>Delete Selected File(s)</Button> 
                    </div>
                    {ShowWait && <div>{imgWait}</div> }
                    <HTMLTable striped interactive condensed>
                        <thead>
                            <th className="TCol-DocName">Doc Name</th>
                            <th className="TCol-DocType">Doc Type</th>
                            <th className="TCol-View">View</th>
                            <th className="TCol-Select">Select</th>
                        </thead>
                        <tbody id="ma_tr">
                            {docList.map((doc, i) =>
                                <tr key={i} onDoubleClick={() => { } }>
                                    <td className="TCol-DocName" onClick={() => { } }>{doc.fileName}</td>
                                    <td className="TCol-DocType" onClick={() => { } }>{doc.docType}</td>
                                    <td className="TCol-View" onClick={() => { window.open(`${gsb.defaults.baseURL}/documentdownload?doctype=${doc.docType}&filename=${encodeURIComponent(doc.fileName)}&acCode=${ac.d_acCode}&inline`, '_blank') } }>View</td>
                                    <td className="TCol-Select"><Checkbox disabled={false} checked={doc.d_select=="Y"} onChange={() => onSelectDoc(doc.fileName)} /></td>
                                </tr>
                            )}
                        </tbody>
                    </HTMLTable>
                    <div className="Spacing-V-8"></div>
                    <div className="Spacing-V-8"></div>
                    <H4>Upload File(s)</H4>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <FormGroup className="Col1-1-3" label="File1" labelFor="inputFile1">
                            <FileInput className="Col-1-3" inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                        </FormGroup>
                        <FormGroup className="Col3-4-1" label="Doc Type" labelFor="inputDocTupe">
                            <select id="inputDocTupe" value={file1docType} onChange={utils.handleStringChange(s => setfile1docType(s)) } >
                                <option value="id">ID</option>
                                <option value="Sign">Signature</option>
                                <option value="Contract">Contract</option>
                                <option value="Other">Others</option>
                            </select>
                        </FormGroup>
                    </div>    
                    <div className="Row">
                        <FormGroup className="Col1-1-3" label="File2" labelFor="inputFile2">
                            <FileInput className="Col-1-3" inputProps={{ name: "file2" }} text={file2Label} onInputChange={utils.handleStringChange((s) => setFile2Label(utils.fileBase(s)))} />
                        </FormGroup>
                        <FormGroup className="Col3-4-1" label="Doc Type" labelFor="inputDocTupe">
                            <select id="inputDocTupe" value={file2docType} onChange={utils.handleStringChange(s => setfile2docType(s)) } >
                                <option value="id">ID</option>
                                <option value="Sign">Signature</option>
                                <option value="Contract">Contract</option>
                                <option value="Other">Others</option>
                            </select>
                        </FormGroup>
                    </div>    
                    <div className="Row">
                        <FormGroup className="Col1-1-3" label="File3" labelFor="inputFile3">
                            <FileInput className="Col-1-3" inputProps={{ name: "file3" }} text={file3Label} onInputChange={utils.handleStringChange((s) => setFile3Label(utils.fileBase(s)))} />
                        </FormGroup>
                        <FormGroup className="Col3-4-1" label="Doc Type" labelFor="inputDocTupe">
                            <select id="inputDocTupe" value={file3docType} onChange={utils.handleStringChange(s => setfile3docType(s)) } >
                                <option value="id">ID</option>
                                <option value="Sign">Signature</option>
                                <option value="Contract">Contract</option>
                                <option value="Other">Others</option>
                            </select>
                        </FormGroup>
                    </div>    
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-2" type="submit" icon={IconNames.UPLOAD}>Upload & Process</Button>               
                        {/* <Button disabled= {! importok} className="Col-2-1" icon={IconNames.UPLOAD} onClick={onImportFiles}>Process</Button> */}
                    </div>
                </form>


                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <div>
                <div >Upload Steps : </div>
                <div >1. Browse a file(s)</div>
                <div >2. Press Upload & Process Button</div>
            </div>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </>
    )

}



interface ClMastEditProps {
    gsb: AxiosInstance,
    ac?: Account,
    StatusList?: StatusInfo[],
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ClMastEdit({ gsb, ac = {} as Account, StatusList=[] as StatusInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: ClMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [aeList, setAeList] = useState([] as Account[])

    const [userKey, setuserKey] = useState("DEMO")
    const [showSIMore, setshowSIMore] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.CAT_STATUS.substr(i, 1).length > 0 ) {
                        let tmpCategoryDesc = " "
                        tmpCategoryDesc = GsbFlag.CAT_DESC[i]

                        CategoryList2.push({d_CategoryCode: GsbFlag.CAT_STATUS.substr(i, 1), d_CategoryDesc: tmpCategoryDesc})
                    }
                }
                setCategoryList(CategoryList2)
                //console.log("set CategoryList: ", CategoryList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

    const [LocationList, setLocationList] = useState([] as LocationInfo[])
    const [BranchEmailList, setBranchEmailList] = useState([] as BranchEmailInfo[])
    const [ExtraFeeList, setExtraFeeList] = useState([] as ExtraFeeInfo[])
    const [PlanNoList, setPlanNoList] = useState([] as MktFeeInfo[])
    const [RegionCodeList, setRegionCodeList] = useState([] as RegionCodeInfo[])

    const [CategoryList, setCategoryList] = useState([] as GetCategoryInfo[])
    const [CategoryList2, setCategoryList2] = useState([] as GetCategoryInfo[])
    
    const [SendemailValue, setSendemailValue] = useState(ac.d_sendemail)
    const [HardCopyStmtValue, setHardCopyStmtValue] = useState(ac.d_HardCopyStmt)
    const [HardCopyMthStmtValue, setHardCopyMthStmtValue] = useState(ac.d_HardCopyMthStmt)
    const [PrintChqValue, setPrintChqValue] = useState(ac.d_PrintChq)
    const [PrintCBBCChqValue, setPrintCBBCChqValue] = useState(ac.d_PrintCBBCChq)
    const [PrintDivChqValue, setPrintDivChqValue] = useState(ac.d_PrintDivChq)
    const [HighRiskValue, setHighRiskValue] = useState(ac.d_HighRisk)
    const [AutoCalcAvgPriceValue, setAutoCalcAvgPriceValue] = useState(ac.d_AutoCalcAvgPrice)
    const [PrnDetailTranValue, setPrnDetailTranValue] = useState(ac.d_PrnDetailTran)
    const [Charge_CCASS_FeeValue, setCharge_CCASS_FeeValue] = useState(ac.d_Charge_CCASS_Fee)
    const [Charge_PortfolioFeValue, setCharge_PortfolioFeValue] = useState(ac.d_ChgPortfolioFee)
    const [Custom_TermValue, setCustom_TermValue] = useState(ac.d_Custom_Term)
    const [LocationValue, setLocationValue] = useState(ac.d_Location)
    const [BranchEmailValue, setBranchEmailValue] = useState(ac.d_Branch_code)
    const [ExtraFeeValue, setExtraFeeValue] = useState(ac.d_Extra_Fee)
    const [PlanNoValue, setPlanNoValue] = useState(ac.d_Plan_no)
	const [RegionCodeValue, setRegionCodeValue] = useState(ac.d_RegionCode)
    const [AcStatusValue, setAcStatusValue] = useState(ac.d_status)
    const [AeCodeValue, setAeCodeValue] = useState(ac.d_aeCode)
    const [CategoryValue, setCategoryValue] = useState(ac.d_Category)

    const [ShowAdd4, setShowAdd4] = useState(false)

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT = Axxxx</div> 
        { GsbFlag.TBOB_USER && <div>Broker A/C : Bxxxx </div> }
        { GsbFlag.CTDY_USER && <div>Custodian A/C : Pxxxx </div> }
        <div>Margin A/C : MARGIN = Mxxxx </div> 
        <div>RUNNER = Cxxxx, INTERNAL = Ixxxx </div> 
   </div>
   

   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_aeCode" })
        register({ name: "d_status" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        if (GsbFlag.user_key === "CHEERFUL" || GsbFlag.user_key === "BENDURA" || GsbFlag.user_key === "DEMO") {
            setShowAdd4(true);
        }
    }, [gsb])


    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key === "CHEERFUL") {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "LocationMast", cAction: "GetBranchEmailList"})
                    //console.log("ClMastEdit GetBranchEmailList data : ", res.data.data, res.data.count) 
                    
                    if ( res.data.count > 0 )
                    {
                        setBranchEmailList(res.data.data)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if ( GsbFlag.MU_BRANCH ) {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "LocationMast", cAction: "GetLocationList"})
                    //console.log("ClMastEdit GetLocationList data : ", res.data.data, res.data.count) 
                    
                    if ( res.data.count > 0 )
                    {
                        setLocationList(res.data.data)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key === "CHEERFUL") {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExtraFeeMast", cAction: "GetExtraFeeList"})
                    //console.log("ClMastEdit GetExtraFeeList data : ", res.data.data, res.data.count) 
                    
                    if ( res.data.count > 0 )
                    {
                        setExtraFeeList(res.data.data)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key === "CHEERFUL") {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PlanNoDivMast", cAction: "GetPlanNoDivList"})
                    //console.log("ClMastEdit GetPlanNoDivList data : ", res.data.data, res.data.count) 
                    
                    if ( res.data.count > 0 )
                    {
                        setPlanNoList(res.data.data)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [])
    
    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: "C",
                            nPage: 1,
                            limit: 0,
                            //PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            backward: false,
                            SortByName: false,
                                    filters: {
                                aeList: "Y",
                            }
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });
                    //console.log("aeList ", res.data.data, res.data.count)
                    setAeList(res.data.data)
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, ac, ac.d_acCode, ac.d_aeCode])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetRegionCodeList"})
                //console.log("ClMastEdit GetRegionCodeList data : ", res.data.data, res.data.count) 
                
                if ( res.data.count > 0 )
                {
                    setRegionCodeList(res.data.data)
                }
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [])


    if (!isNew && !("d_acCode" in ac)) return null

    let idNoLength = 20
    if ( GsbFlag.user_key === "TATLEE" ) {
        idNoLength = 22
    }

    let AddrLength = 30
    if ( GsbFlag.user_key === "WINFULL" || GsbFlag.user_key === "WINGHANG" || GsbFlag.user_key === "COMPSHARE" ) {
        AddrLength = 35
    }
    if ( GsbFlag.user_key === "BENDURA" ) {
        AddrLength = 40
    }

    let TelLength = 15
    if ( GsbFlag.user_key === "BERICH" || GsbFlag.user_key === "CHEERFUL" ) {
        TelLength = 20
    }

    let OffTelLength = 15
    if ( GsbFlag.user_key === "CHEERFUL"  ) {
        OffTelLength = 20
    }

    let MobileLength = 15

    let FaxLength = 15

    let EmailLength = 100
    if ( GsbFlag.user_key === "PAMIRS"  ) {
            EmailLength = 130
    } else if ( GsbFlag.user_key === "COMPSHARE" || GsbFlag.user_key === "BELTRADE" ) {
        EmailLength = 200
    }

    let BankInfoLength = 20
    if ( GsbFlag.user_key === "WINGHANG"  ) {
        BankInfoLength = 9
    }

    let BankACNoLength = 20
    if ( GsbFlag.user_key === "WINGHANG"  ) {
        BankACNoLength = 9
    }


    const onSubmit = (data: Account) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();

        data.d_sendemail = SendemailValue
        data.d_HardCopyStmt = HardCopyStmtValue
        data.d_HardCopyMthStmt = HardCopyMthStmtValue
        data.d_PrintChq = PrintChqValue;
        data.d_PrintCBBCChq = PrintCBBCChqValue;
        data.d_PrintDivChq = PrintDivChqValue;
        data.d_HighRisk = HighRiskValue
        data.d_AutoCalcAvgPrice = AutoCalcAvgPriceValue
        data.d_PrnDetailTran = PrnDetailTranValue
        data.d_ChgPortfolioFee = Charge_PortfolioFeValue
        data.d_Charge_CCASS_Fee = Charge_CCASS_FeeValue
        data.d_Custom_Term = Custom_TermValue
        data.d_Location = LocationValue
        data.d_Branch_code = BranchEmailValue
        data.d_Extra_Fee = ExtraFeeValue
        data.d_Plan_no = PlanNoValue
        data.d_RegionCode = RegionCodeValue
        data.d_status = AcStatusValue
        data.d_aeCode = AeCodeValue
        if ( typeof(data.d_Category) === "undefined" ) {
            data.d_Category = " "
        }
        if  ( GsbFlag.CL_CATEGY ) {
            data.d_Category = CategoryValue
        }

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call clmastedit data", data)
                //console.log("call clmastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "Clmast", cAction: "ClValidate", isNew: isNew})
                //console.log("clmastedit res.data.ok", res.data.ok)
                //console.log("clmastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const acRenderer: ItemRenderer<Account> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<Account> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: Account) => {
        return ac.d_acCode
    }

    const StatusRenderer: ItemRenderer<StatusInfo> = (StatusList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${StatusList.d_StatusDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={StatusList.d_StatusCode}
                key={StatusList.d_StatusCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StatusPredicate: ItemPredicate<StatusInfo> = (query, StatusList, _index, exactMatch) => {
        const normalizedText = `${StatusList.d_StatusCode} - ${StatusList.d_StatusDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StatusValueRenderer = (StatusList: StatusInfo) => {
        return StatusList.d_StatusCode
    }

    const LocationRenderer: ItemRenderer<LocationInfo> = (LocationList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${LocationList.d_Branch_name}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={LocationList.d_Location}
                key={LocationList.d_Location}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const LocationPredicate: ItemPredicate<LocationInfo> = (query, LocationList, _index, exactMatch) => {
        const normalizedText = `${LocationList.d_Location} - ${LocationList.d_Branch_name}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const LocationValueRenderer = (LocationList: LocationInfo) => {
        return LocationList.d_Location
    }

    const BranchEmailRenderer: ItemRenderer<BranchEmailInfo> = (BranchEmailList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${BranchEmailList.d_Location}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={BranchEmailList.d_Location}
                key={BranchEmailList.d_Location}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const BranchEmailPredicate: ItemPredicate<BranchEmailInfo> = (query, BranchEmailList, _index, exactMatch) => {
        const normalizedText = `${BranchEmailList.d_Location} - ${BranchEmailList.d_Location}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const BranchEmailValueRenderer = (BranchEmailList: BranchEmailInfo) => {
        return BranchEmailList.d_Location
    }

    const ExtraFeeRenderer: ItemRenderer<ExtraFeeInfo> = (ExtraFeeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${ExtraFeeList.d_ExtraFee_Rate}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ExtraFeeList.d_ExtraFeeCode}
                key={ExtraFeeList.d_ExtraFeeCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const ExtraFeePredicate: ItemPredicate<ExtraFeeInfo> = (query, ExtraFeeList, _index, exactMatch) => {
        const normalizedText = `${ExtraFeeList.d_ExtraFeeCode} - ${ExtraFeeList.d_ExtraFee_Rate}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const ExtraFeeValueRenderer = (ExtraFeeList: ExtraFeeInfo) => {
        return ExtraFeeList.d_ExtraFeeCode
    }

    const PlanNoRenderer: ItemRenderer<MktFeeInfo> = (PlanNoList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${PlanNoList.d_Plan_no}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={PlanNoList.d_Plan_no}
                key={PlanNoList.d_Plan_no}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const PlanNoPredicate: ItemPredicate<MktFeeInfo> = (query, PlanNoList, _index, exactMatch) => {
        const normalizedText = `${PlanNoList.d_Plan_no} - ${PlanNoList.d_Plan_no}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const PlanNoValueRenderer = (PlanNoList: MktFeeInfo) => {
        return PlanNoList.d_Plan_no
    }

    const RegionCodeRenderer: ItemRenderer<RegionCodeInfo> = (RegionCodeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${RegionCodeList.d_Country}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={RegionCodeList.d_RegionCode}
                key={RegionCodeList.d_RegionCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const RegionCodePredicate: ItemPredicate<RegionCodeInfo> = (query, RegionCodeList, _index, exactMatch) => {
        const normalizedText = `${RegionCodeList.d_RegionCode} - ${RegionCodeList.d_Country}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const RegionCodeValueRenderer = (RegionCodeList: RegionCodeInfo) => {
        return RegionCodeList.d_RegionCode
    }

    const CategoryRenderer: ItemRenderer<GetCategoryInfo> = (CategoryList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CategoryList.d_CategoryDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CategoryList.d_CategoryCode}
                key={CategoryList.d_CategoryCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CategoryPredicate: ItemPredicate<GetCategoryInfo> = (query, CategoryList, _index, exactMatch) => {
        const normalizedText = `${CategoryList.d_CategoryCode} - ${CategoryList.d_CategoryDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CategoryValueRenderer = (CategoryList: GetCategoryInfo) => {
        return CategoryList.d_CategoryDesc
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name" labelFor="inputAcName1">
                    <InputGroup id="inputAcName1" name="d_acName1" autoFocus={! isNew} defaultValue="" placeholder="Name 1 ..." inputRef={register({ maxLength: 30}) } />
                    <InputGroup id="inputAcName2" name="d_acName2" defaultValue="" placeholder="Name 2 ..." inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No." labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="d_idNo" defaultValue="" placeholder="ID No. ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_idNo && errors.d_idNo.types && <span id="Err_msg">{errors.d_idNo.types.message}</span>}
                    {errors.d_idNo && errors.d_idNo.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
                <FormGroup className="Col3-7-1" label="Status" labelFor="inputStatus">
                    {/* <InputGroup id="inputStatus" name="d_status" defaultValue="" placeholder="Status ..." inputRef={register({ maxLength: 1}) } /> */}
                    <Suggest2
                            inputProps={{ placeholder: "Status ..." }}
                            activeItem={StatusList.find((Status) => Status.d_StatusCode === AcStatusValue)}
                            selectedItem={StatusList.find((Status) => Status.d_StatusCode === AcStatusValue)}
                            items={StatusList}
                            itemRenderer={StatusRenderer}
                            itemPredicate={StatusPredicate}
                            inputValueRenderer={StatusValueRenderer}
                            onItemSelect={(ac) => { setAcStatusValue(ac.d_StatusCode) }}
                        />
                    {errors.d_status && errors.d_status.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_status && errors.d_status.types && <span id="Err_msg">{errors.d_status.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-8-2" label="Open" labelFor="inputOpenDate">
                    <InputGroup id="inputOpenDate" name="d_openDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_openDate && errors.d_openDate.types && <span id="Err_msg">{errors.d_openDate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-10-2" label="Renew / GEM" labelFor="inputRenewDate">
                    <InputGroup id="inputRenewDate" name="d_renewDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_renewDate && errors.d_renewDate.types && <span id="Err_msg">{errors.d_renewDate.types.message}</span>}
                    <InputGroup id="inputGemDate" name="d_gemDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_gemDate && errors.d_gemDate.types && <span id="Err_msg">{errors.d_gemDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="" labelFor="">
                </FormGroup>
                {(GsbFlag.CHQ_NAME) && 
                    <FormGroup className="Col-2-3" label="Cheque Name" labelFor="inputChqName1">
                        <InputGroup id="inputAcName1" name="d_ChqName1" defaultValue="" placeholder="Cheque Name 1 ..." inputRef={register({ maxLength: 30}) } />
                        <InputGroup id="inputAcName2" name="d_ChqName2" defaultValue="" placeholder="Cheque Name 2 ..." inputRef={register({ maxLength: 30}) } />
                        {errors.d_ChqName1 && errors.d_ChqName1.types && <span id="Err_msg">{errors.d_ChqName1.types.message}</span>}
                        {errors.d_ChqName2 && errors.d_ChqName2.types && <span id="Err_msg">{errors.d_ChqName2.types.message}</span>}
                        {errors.d_ChqName1 && errors.d_ChqName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                        {errors.d_ChqName2 && errors.d_ChqName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    </FormGroup>
                }
                {(GsbFlag.SEL_CQPRT) && 
                    <FormGroup className="Col-5-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            inline={true}
                            selectedValue={PrintChqValue}
                            onChange={utils.handleStringChange(s => setPrintChqValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrintChq && errors.d_PrintChq.types && <span id="Err_msg">{errors.d_PrintChq.types.message}</span>}
                    </FormGroup>
                }
                {(GsbFlag.CBBC_USER) && 
                    <FormGroup className="Col-7-2" label="Print CBBC Cheque" labelFor="inputPrintCBBCChq">
                        <RadioGroup
                            name="d_PrintCBBCChq"
                            inline={true}
                            selectedValue={PrintCBBCChqValue}
                            onChange={utils.handleStringChange(s => setPrintCBBCChqValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrintCBBCChq && errors.d_PrintCBBCChq.types && <span id="Err_msg">{errors.d_PrintCBBCChq.types.message}</span>}
                    </FormGroup>
                }
                <FormGroup className="Col-9-2" label="Print Div Cheque (For Margin Client)" labelFor="inputPrintDivChq">
                    <RadioGroup
                        name="d_PrintDivChq"
                        inline={true}
                        selectedValue={PrintDivChqValue}
                        onChange={utils.handleStringChange(s => setPrintDivChqValue(s)) }
                        ref={register}
                >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_PrintDivChq && errors.d_PrintDivChq.types && <span id="Err_msg">{errors.d_PrintDivChq.types.message}</span>}
                </FormGroup>
                {(GsbFlag.user_key === "CHEERFUL") && 
                    <FormGroup className="Col-11-2" label="Custom_Term" labelFor="inputCustom_Term">
                        <RadioGroup
                            name="d_Custom_Term"
                            inline={true}
                            selectedValue={Custom_TermValue}
                            onChange={utils.handleStringChange(s => setCustom_TermValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_Custom_Term && errors.d_Custom_Term.types && <span id="Err_msg">{errors.d_Custom_Term.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-4" label="Address" labelFor="inputAddr1">
                    <InputGroup id="inputAddr1" name="d_acAddr1" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    <InputGroup id="inputAddr2" name="d_acAddr2" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    <InputGroup id="inputAddr3" name="d_acAddr3" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } />
                    { ShowAdd4 && <InputGroup id="inputAddr4" name="d_acAddr4" defaultValue="" placeholder="Address ..." inputRef={register({ maxLength: AddrLength}) } /> }
                    {errors.d_acAddr1 && errors.d_acAddr1.types && <span id="Err_msg">{errors.d_acAddr1.types.message}</span>}
                    {errors.d_acAddr2 && errors.d_acAddr2.types && <span id="Err_msg">{errors.d_acAddr2.types.message}</span>}
                    {errors.d_acAddr3 && errors.d_acAddr3.types && <span id="Err_msg">{errors.d_acAddr3.types.message}</span>}
                    {errors.d_acAddr4 && errors.d_acAddr4.types && <span id="Err_msg">{errors.d_acAddr4.types.message}</span>}
                    {errors.d_acAddr1 && errors.d_acAddr1.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                    {errors.d_acAddr2 && errors.d_acAddr2.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                    {errors.d_acAddr3 && errors.d_acAddr3.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                    {errors.d_acAddr4 && errors.d_acAddr4.type === "maxLength" && <span id="Err_msg">Max length is {AddrLength}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Phone / Office" labelFor="inputTel">
                    <InputGroup id="inputTel" name="d_tel" defaultValue="" placeholder="Phone ..." inputRef={register({ maxLength: TelLength}) } />
                    {errors.d_tel && errors.d_tel.types && <span id="Err_msg">{errors.d_tel.types.message}</span>}
                    {errors.d_tel && errors.d_tel.type === "maxLength" && <span id="Err_msg">Max length is {TelLength}</span>}
                    <InputGroup id="inputOffTel" name="d_offTel" defaultValue="" placeholder="Office Tel ..." inputRef={register({ maxLength: OffTelLength}) } />
                    {errors.d_offTel && errors.d_offTel.types && <span id="Err_msg">{errors.d_offTel.types.message}</span>}
                    {errors.d_offTel && errors.d_offTel.type === "maxLength" && <span id="Err_msg">Max length is {OffTelLength}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="Mobile / Fax" labelFor="inputMobile">
                    <InputGroup id="inputMobile" name="d_mobile" defaultValue="" placeholder="Mobile ..." inputRef={register({ maxLength: MobileLength}) } />
                    {errors.d_mobile && errors.d_mobile.types && <span id="Err_msg">{errors.d_mobile.types.message}</span>}
                    {errors.d_mobile && errors.d_mobile.type === "maxLength" && <span id="Err_msg">Max length is {MobileLength}</span>}
                    <InputGroup id="inputFax" name="d_fax" defaultValue="" placeholder="Fax No. ..." inputRef={register({ maxLength: FaxLength}) } />
                    {errors.d_fax && errors.d_fax.types && <span id="Err_msg">{errors.d_fax.types.message}</span>}
                    {errors.d_fax && errors.d_fax.type === "maxLength" && <span id="Err_msg">Max length is {FaxLength}</span>}
                </FormGroup>
                <FormGroup className="Col-9-3" label="Bank / AC No." labelFor="inputBankInfo">
                    <InputGroup id="inputBankInfo" name="d_BankInfo" defaultValue="" placeholder="Bank ..." inputRef={register({ maxLength: BankInfoLength}) } />
                    {errors.d_BankInfo && errors.d_BankInfo.types && <span id="Err_msg">{errors.d_BankInfo.types.message}</span>}
                    {errors.d_BankInfo && errors.d_BankInfo.type === "maxLength" && <span id="Err_msg">Max length is {BankInfoLength}</span>}
                    <InputGroup id="inputBankACNo" name="d_BankACNo" defaultValue="" placeholder="AC No. ..." inputRef={register({ maxLength: BankACNoLength}) } />
                    {errors.d_BankACNo && errors.d_BankACNo.types && <span id="Err_msg">{errors.d_BankACNo.types.message}</span>}
                    {errors.d_BankACNo && errors.d_BankACNo.type === "maxLength" && <span id="Err_msg">Max length is {BankACNoLength}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                {(GsbFlag.CHQ_TO_BANK || GsbFlag.CCHQ_FILE || GsbFlag.DIVCHQ_TO_BANK) && <FormGroup className="Col-1-2" label="AutoPay Bank Code" labelFor="inputAutopay_BankCode">
                    <InputGroup id="inputAutopay_BankCode" name="d_Autopay_BankCode" defaultValue="" placeholder="Autopay Bank Code ..." inputRef={register({ maxLength: 1}) } /> 
                    {errors.d_Autopay_BankCode && errors.d_Autopay_BankCode.types && <span id="Err_msg">{errors.d_Autopay_BankCode.types.message}</span>}
                    {errors.d_Autopay_BankCode && errors.d_Autopay_BankCode.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Runner" labelFor="inputAeCode">
                    {/*<InputGroup id="inputAeCode" name="aeCode" inputRef={register} />*/}
                    <Suggest
                        inputProps={{ placeholder: "Please choose AE" }}
                        activeItem={aeList.find((ae) => ae.d_acCode === AeCodeValue)}
                        selectedItem={aeList.find((ae) => ae.d_acCode === AeCodeValue)}
                        items={aeList}
                        itemRenderer={acRenderer}
                        itemPredicate={acPredicate}
                        inputValueRenderer={acValueRenderer}
                        onItemSelect={(ac) => { setAeCodeValue(ac.d_acCode) }}
                    />
                </FormGroup>
                <FormGroup className="Col-2-4" label="E-Mail" labelFor="inputEmail">
                    <InputGroup id="inputEmail" name="d_email" defaultValue="" placeholder="E-Mail ..." inputRef={register({ maxLength: EmailLength}) } />
                    {errors.d_email && errors.d_email.types && <span id="Err_msg">{errors.d_email.types.message}</span>}
                    {errors.d_email && errors.d_email.type === "maxLength" && <span id="Err_msg">Max length is {EmailLength}</span>}
                </FormGroup>
                <FormGroup className="Col-6-2" label="Send e-mail Statement" labelFor="inputSendemail">
                    <RadioGroup
                        name="d_sendemail"
                        inline={true}
                        selectedValue={SendemailValue}
                        onChange={utils.handleStringChange(s => setSendemailValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_sendemail && errors.d_sendemail.types && <span id="Err_msg">{errors.d_sendemail.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-8-2" label="Skip Hard Copy Statement" labelFor="inputHardCopyStmt">
                    <RadioGroup
                        name="d_HardCopyStmt"
                        inline={true}
                        selectedValue={HardCopyStmtValue}
                        onChange={utils.handleStringChange(s => setHardCopyStmtValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_HardCopyStmt && errors.d_HardCopyStmt.types && <span id="Err_msg">{errors.d_HardCopyStmt.types.message}</span>}
                </FormGroup>
                {(GsbFlag.HC_MTH_STMT) && 
                    <FormGroup className="Col-10-2" label="Skip Monthly Statement" labelFor="inputHardCopyMthStmt">
                        <RadioGroup
                            name="d_HardCopyMthStmt"
                            inline={true}
                            selectedValue={HardCopyMthStmtValue}
                            onChange={utils.handleStringChange(s => setHardCopyMthStmtValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_HardCopyMthStmt && errors.d_HardCopyMthStmt.types && <span id="Err_msg">{errors.d_HardCopyMthStmt.types.message}</span>}
                    </FormGroup>
                }
                { ( GsbFlag.user_key === "CHEERFUL" ) && 
                    <FormGroup className="Col3-10-1" label="Mailling" labelFor="inputMailing">
                        <select  id="inputMailing" name="d_Mailing" ref={register}>
                            <option value="M">M: By Mail</option>
                            <option value="S">S: Self Collect</option>
                            <option value="O">O: Oversea</option>
                            <option value="F">F: By Mail *</option>
                            <option value=" "> : *Undefine*</option>
                        </select>
                        {errors.d_Mailing && errors.d_Mailing.types && <span id="Err_msg">{errors.d_Mailing.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Commission" labelFor="inputCommCode">
                    <InputGroup id="inputCommCode" name="d_commCode" defaultValue="" placeholder="Commission Code ..." inputRef={register({ maxLength: 2}) } />
                    {errors.d_commCode && errors.d_commCode.types && <span id="Err_msg">{errors.d_commCode.types.message}</span>}
                    {errors.d_commCode && errors.d_commCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Interest" labelFor="inputIntCode">
                    <InputGroup id="inputIntCode" name="d_intCode" defaultValue="" placeholder="Interest Code ..." inputRef={register({ maxLength: 2}) } />
                    {errors.d_intCode && errors.d_intCode.types && <span id="Err_msg">{errors.d_intCode.types.message}</span>}
                    {errors.d_intCode && errors.d_intCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Loan Limit" labelFor="inputloanLimit">
                    <InputGroup className="inputNumberNoSpin" id="inputloanLimit" name="d_loanLimit" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_loanLimit && errors.d_loanLimit.types && <span id="Err_msg">{errors.d_loanLimit.types.message}</span>}
                </FormGroup>
                { (! GsbFlag.MGNGP_USER) &&
                    <FormGroup className="Col-5-1" label="Margin Ratio" labelFor="inputMarginRatio">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio" name="d_MarginRatio" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio && errors.d_MarginRatio.types && <span id="Err_msg">{errors.d_MarginRatio.types.message}</span>}
                    </FormGroup>
                }
                {(GsbFlag.MGNGP_USER) &&
                    <FormGroup className="Col-5-1" label="Margin Group" labelFor="inputMarginGroup">
                        <InputGroup id="inputMarginGroup" name="d_MarginGroup" defaultValue="" placeholder="Margin Group ..." inputRef={register({ maxLength: 1}) } />
                        {errors.d_MarginGroup && errors.d_MarginGroup.types && <span id="Err_msg">{errors.d_MarginGroup.types.message}</span>}
                        {errors.d_MarginGroup && errors.d_MarginGroup.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Allow High Risk" labelFor="inputHighRisk">
                    {/* <input id="inputHighRisk" type="checkbox" checked={HighRiskValue} onChange={utils.handleBooleanChange(v => setHighRiskValue(v))} ref={register}/> High Risk */}
                    <RadioGroup
                        name="d_HighRisk"
                        inline={true}
                        selectedValue={HighRiskValue}
                        onChange={utils.handleStringChange(s => setHighRiskValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_HighRisk && errors.d_HighRisk.types && <span id="Err_msg">{errors.d_HighRisk.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="High Risk verified on" labelFor="inputHighRiskDate">
                    <InputGroup id="inputHighRiskDate" name="d_HighRiskDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_HighRiskDate && errors.d_HighRiskDate.types && <span id="Err_msg">{errors.d_HighRiskDate.types.message}</span>}
                </FormGroup>
                {(GsbFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Auto Calc Average Price" labelFor="inputAutoCalcAvgPrice">
                        <RadioGroup
                            name="d_AutoCalcAvgPrice"
                            inline={true}
                            selectedValue={AutoCalcAvgPriceValue}
                            onChange={utils.handleStringChange(s => setAutoCalcAvgPriceValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_AutoCalcAvgPrice && errors.d_AutoCalcAvgPrice.types && <span id="Err_msg">{errors.d_AutoCalcAvgPrice.types.message}</span>}
                    </FormGroup>
                }
                {(GsbFlag.MULTI_PRCE) && (! GsbFlag.AVG_MUL_PRCE) && 
                    <FormGroup className="Col-5-2" label="Print Detail Transaction" labelFor="inputPrnDetailTran">
                        <RadioGroup
                            name="d_PrnDetailTran"
                            inline={true}
                            selectedValue={PrnDetailTranValue}
                            onChange={utils.handleStringChange(s => setPrnDetailTranValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrnDetailTran && errors.d_PrnDetailTran.types && <span id="Err_msg">{errors.d_PrnDetailTran.types.message}</span>}
                    </FormGroup>
                }
                {(GsbFlag.SEL_CCASS) && 
                    <FormGroup className="Col-7-2" label="Charge CCASS Fee" labelFor="inputCharge_CCASS_Fee">
                        <RadioGroup
                            name="d_Charge_CCASS_Fee"
                            inline={true}
                            selectedValue={Charge_CCASS_FeeValue}
                            onChange={utils.handleStringChange(s => setCharge_CCASS_FeeValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_Charge_CCASS_Fee && errors.d_Charge_CCASS_Fee.types && <span id="Err_msg">{errors.d_Charge_CCASS_Fee.types.message}</span>}
                    </FormGroup>
                }
            </div>
            {(GsbFlag.ChrgPortFee) && <div className="Row">
                <FormGroup className="Col-1-2" label="Charge Custody Fee" labelFor="input_ChgPortfolioFee">
                    <RadioGroup
                        name="d_ChgPortfolioFee"
                        inline={true}
                        selectedValue={Charge_PortfolioFeValue}
                        onChange={utils.handleStringChange(s => setCharge_PortfolioFeValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_ChgPortfolioFee && errors.d_ChgPortfolioFee.types && <span id="Err_msg">{errors.d_ChgPortfolioFee.types.message}</span>}
                </FormGroup>
            </div> }
            <div className="Row">
                {( GsbFlag.MU_BRANCH ) &&
                    <FormGroup className="Col3-1-1" label="Location" labelFor="inputLocation">
                        <Suggest
                                inputProps={{ placeholder: "Location ..." }}
                                activeItem={LocationList.find((loc) => loc.d_Location === LocationValue)}
                                selectedItem={LocationList.find((loc) => loc.d_Location === LocationValue)}
                                items={LocationList}
                                itemRenderer={LocationRenderer}
                                itemPredicate={LocationPredicate}
                                inputValueRenderer={LocationValueRenderer}
                                onItemSelect={(loc2) => { setLocationValue(loc2.d_Location) }}
                            />
                        {errors.d_Location && errors.d_Location.types && <span id="Err_msg">{errors.d_Location.types.message}</span>}
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-2-1" label="Branch Code" labelFor="inputBranchEmail">
                        <Suggest2
                                inputProps={{ placeholder: "Branch Code ..." }}
                                activeItem={BranchEmailList.find((brE) => brE.d_Location === BranchEmailValue)}
                                selectedItem={BranchEmailList.find((brE) => brE.d_Location === BranchEmailValue)}
                                items={BranchEmailList}
                                itemRenderer={BranchEmailRenderer}
                                itemPredicate={BranchEmailPredicate}
                                inputValueRenderer={BranchEmailValueRenderer}
                                onItemSelect={(brE2) => { setBranchEmailValue(brE2.d_Location) }}
                            />
                        {errors.d_Branch_code && errors.d_Branch_code.types && <span id="Err_msg">{errors.d_Branch_code.types.message}</span>}
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-3-3" label="Extra Fee" labelFor="inputExtra_Fee">
                        <Suggest2
                                inputProps={{ placeholder: "Extra Fee ..." }}
                                activeItem={ExtraFeeList.find((Extra) => Extra.d_ExtraFeeCode === ExtraFeeValue)}
                                selectedItem={ExtraFeeList.find((Extra) => Extra.d_ExtraFeeCode === ExtraFeeValue)}
                                items={ExtraFeeList}
                                itemRenderer={ExtraFeeRenderer}
                                itemPredicate={ExtraFeePredicate}
                                inputValueRenderer={ExtraFeeValueRenderer}
                                onItemSelect={(Extra2) => { setExtraFeeValue(Extra2.d_ExtraFeeCode) }}
                            />
                        {errors.d_Extra_Fee && errors.d_Extra_Fee.types && <span id="Err_msg">{errors.d_Extra_Fee.types.message}</span>}
                    </FormGroup>
                }
                {( GsbFlag.user_key === "CHEERFUL") &&
                    <FormGroup className="Col3-6-3" label="Plan No. of Dividend Charges" labelFor="inputPlan_no">
                        <Suggest2
                                inputProps={{ placeholder: "Plan No. ..." }}
                                activeItem={PlanNoList.find((PlanNo) => PlanNo.d_Plan_no.trim() === PlanNoValue.trim())}
                                selectedItem={PlanNoList.find((PlanNo) => PlanNo.d_Plan_no.trim() === PlanNoValue.trim())}
                                items={PlanNoList}
                                itemRenderer={PlanNoRenderer}
                                itemPredicate={PlanNoPredicate}
                                inputValueRenderer={PlanNoValueRenderer}
                                onItemSelect={(PlanNo2) => { setPlanNoValue(PlanNo2.d_Plan_no) }}
                            />
                        {errors.d_Plan_no && errors.d_Plan_no.types && <span id="Err_msg">{errors.d_Plan_no.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Region code" labelFor="inputRegionCode">
                    <Suggest
                            inputProps={{ placeholder: "Region code ..." }}
                            activeItem={RegionCodeList.find((RegionCode) => RegionCode.d_RegionCode === RegionCodeValue)}
                            selectedItem={RegionCodeList.find((RegionCode) => RegionCode.d_RegionCode === RegionCodeValue)}
                            items={RegionCodeList}
                            itemRenderer={RegionCodeRenderer}
                            itemPredicate={RegionCodePredicate}
                            inputValueRenderer={RegionCodeValueRenderer}
                            onItemSelect={(RegionCode2) => { setRegionCodeValue(RegionCode2.d_RegionCode) }}
                        />
                    {errors.d_RegionCode && errors.d_RegionCode.types && <span id="Err_msg">{errors.d_RegionCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-3-3" label="Professional Investors" labelFor="inputProfessional_Investor">
                    <select  id="inputProfessional_Investor" name="d_Professional_Investor" ref={register}>
                        <option value=""></option>
                        <option value="1">1:  Institutional Professional Investors</option>
                        <option value="2">2:  Corporate Professional Investors</option>
                        <option value="3">3:  Individual Professional Investors</option>
                        <option value="4">4:  Others</option>
                    </select>
                    {errors.d_Professional_Investor && errors.d_Professional_Investor.types && <span id="Err_msg">{errors.d_Professional_Investor.types.message}</span>}
                </FormGroup>
                {( GsbFlag.CL_CATEGY ) && <FormGroup className="Col3-6-2" label="Category" labelFor="inputCategory">
                    <Suggest2
                            inputProps={{ placeholder: "Category ..." }}
                            activeItem={CategoryList.find((Category) => Category.d_CategoryCode === CategoryValue)}
                            selectedItem={CategoryList.find((Category) => Category.d_CategoryCode === CategoryValue)}
                            items={CategoryList}
                            itemRenderer={CategoryRenderer}
                            itemPredicate={CategoryPredicate}
                            inputValueRenderer={CategoryValueRenderer}
                            onItemSelect={(ac) => { setCategoryValue(ac.d_CategoryCode) }}
                        />
                    {errors.d_Category && errors.d_Category.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Category && errors.d_Category.types && <span id="Err_msg">{errors.d_Category.types.message}</span>}
                </FormGroup> }
            </div>
            {( GsbFlag.SI_USER ) && ! showSIMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSIMore(! showSIMore) }} >+ PART-ID & CUSTODY A/C FOR "SI" </div> }
            {( GsbFlag.SI_USER ) && showSIMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSIMore(! showSIMore) }} >- PART-ID & CUSTODY A/C FOR "SI" </div> }
            {( GsbFlag.SI_USER && showSIMore ) && <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Part-ID No." labelFor="inputd_Part_ID">
                    <InputGroup id="inputd_Part_ID" name="d_Part_ID" defaultValue="" placeholder="CCASS Part-ID No. ..." inputRef={register({ maxLength: 6}) } /> 
                    {errors.d_Part_ID && errors.d_Part_ID.types && <span id="Err_msg">{errors.d_Part_ID.types.message}</span>}
                    {errors.d_Part_ID && errors.d_Part_ID.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Sender BIC" labelFor="inputd_Sender_BIC">
                    <InputGroup id="inputd_Sender_BIC" name="d_Sender_BIC" defaultValue="" placeholder="Sender BIC ..." inputRef={register({ maxLength: 8}) } /> 
                    {errors.d_Sender_BIC && errors.d_Sender_BIC.types && <span id="Err_msg">{errors.d_Sender_BIC.types.message}</span>}
                    {errors.d_Sender_BIC && errors.d_Sender_BIC.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                </FormGroup>
                <FormGroup className="Col-4-2" label="Custody A/C No." labelFor="inputd_Custody_AC">
                <InputGroup id="inputd_Custody_AC" name="d_Custody_AC" defaultValue="" placeholder="Custody A/C No. ..." inputRef={register({ maxLength: 15}) } /> 
                    {errors.d_Custody_AC && errors.d_Custody_AC.types && <span id="Err_msg">{errors.d_Custody_AC.types.message}</span>}
                    {errors.d_Custody_AC && errors.d_Custody_AC.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                </FormGroup>
                <FormGroup className="Col-6-2" label="Payment Terms" labelFor="inputd_Payment_Terms">
                    <select  id="inputd_Payment_Terms" name="d_Payment_Terms" ref={register}>
                        <option value="D">D: D-DVP</option>
                        <option value="F">F: F-FOP</option>
                        <option value="R">R: R-RDVP</option>
                    </select>
                    {errors.d_Payment_Terms && errors.d_Payment_Terms.types && <span id="Err_msg">{errors.d_Payment_Terms.types.message}</span>}
                </FormGroup>
            </div> }
            <div className="Spacing-V-16" />
            <div className="Row">
                {/* <textarea className="bp4-input bp4-fill Col-1-6" name="d_footnote" rows={5} ref={register}></textarea> */}
                <FormGroup className="Col-1-6" label="Footnote" labelFor="inputFootnote"> 
                    <textarea className="bp4-input bp4-fill" name="d_footnote" rows={4} ref={register}></textarea>
                </FormGroup>
            </div>
        </form>
    )
}

interface ClMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClMastMain({ gsb }: ClMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 
    
    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as Account[])
    const [StatusList, setStatusList] = useState([] as StatusInfo[])
    const [StatusList2, setStatusList2] = useState([] as StatusInfo[])

    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)
    const [CurrentLog1, setCurrentLog1] = useState({} as Account)
    const [CurrentLog0, setCurrentLog0] = useState({} as Account)
    const [CbCurrentLog1, setCbCurrentLog1] = useState({} as cbMast)
    const [CbCurrentLog0, setCbCurrentLog0] = useState({} as cbMast)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    //gsb default value
    BlankAc.d_sendemail = "Y"
    BlankAc.d_HardCopyStmt = "N"
    BlankAc.d_HardCopyMthStmt = "N"
    BlankAc.d_PrintChq = "N"
    BlankAc.d_PrintCBBCChq = "N"
    BlankAc.d_PrintDivChq = "N"
    BlankAc.d_HighRisk = "Y"
    BlankAc.d_AutoCalcAvgPrice = "Y"
    BlankAc.d_PrnDetailTran = "Y"
    BlankAc.d_RegionCode = ""
    BlankAc.d_Professional_Investor = ""
    BlankAc.d_ChgPortfolioFee = "N"
    BlankAc.d_Charge_CCASS_Fee = "Y"
    BlankAc.d_Custom_Term = "N"
    BlankAc.d_Location = ""
    BlankAc.d_Branch_code = ""
    BlankAc.d_Extra_Fee = ""
    BlankAc.d_Plan_no = ""
    BlankAc.d_Payment_Terms = "F"
    BlankAc.d_aeCode = ""
    BlankAc.d_Category = " "


    //console.log("ClMastMain logKey: ", logKey)
    //console.log("ClMastMain logKey2: ", logKey2)
    //console.log("ClMastMain logviewonly: ", logviewonly)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClMastMain disp GsbFlag: ", GsbFlag)
                //console.log("ClMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("ClMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 

                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.AC_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpStatusName = "Active"
                        tmpStatusName = GsbFlag.AC_SDESC[i]

                        StatusList2.push({d_StatusCode: GsbFlag.AC_STATUS.substr(i, 1), d_StatusDesc: tmpStatusName})
                    }
                }
                setStatusList(StatusList2)
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {

        if (logviewonly === "Y") {
            setViewMode("L")
        }
        if (logviewonly === "Y_CB") {
            setViewMode("L_CB")
        }
        //console.log("ClMastMain ViewMode: ", ViewMode)

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 && logviewonly === "Y" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Clmast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("ClMastMain Clmast ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
                if ( logKey.trim().length > 0 && logviewonly === "Y_CB" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Cbmast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("ClMastMain Cbmast ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCbCurrentLog0(res.data.data[0])
                        setCbCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    // useEffect(() => {

    //     setnPage(0)
    //     if ( nPage > 0 ) {
    //         //setcPrintMode("PS")
    //     } 

    //     (async () => {
    //         try {
    //             // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
    //             // TODO: Read A/E prefix from config
    //             // fixed can read true A/E
    //             const res = await gsb.post('/Master',
    //                 {   sb_data: {
    //                         by: "acCode",
    //                         start: "C",
    //                         nPage: 1,
    //                         limit: 0,
    //                         PageShowInfo: aPageShowInfo,
    //                         refreshPageShow: true,
    //                         backward: false,
    //                         SortByName: false,
    //                                 filters: {
    //                             aeList: "Y",
    //                         }
    //                     },
    //                     cPgmId: "Clmast",
    //                     cAction: "ListRec",
    //                 });
    //                 //"aeList ", res.data.data, res.data.count)
    //                 setAeList(res.data.data)
    //                 //setAeList(utils.sortByColumn(res.data.data, "d_acName1"))

    //         } catch (error) {
    //             utils.showError(error)
    //         }
    //     })()
    // }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //CurrencyList2.push({d_Currency: "", d_CcyDesc: "HKD"})     //not need
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "Please Choose Currency"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    
    const PrevRecProcess = async (ac: Account) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (ac: Account) => {
        try {
            ac.d_AuthTran = ""
            if (isReqAuth2) {
                ac.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "DelRec"})
            if (res.data.ok) {
                if (isReqAuth2) {
                    showSuccess("Account No. " + ac.d_acCode + " record deleting, waiting for authorization")
                } else {
                    showSuccess("Account No. " + ac.d_acCode + " record deleted")
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].d_acCode === res.data.data.d_acCode) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
                if (isReqAuth2) {
                    //alert("Account No. " + ac.d_acCode + " record deleting, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Account No. " + ac.d_acCode + " record deleting, waiting for authorization")
                }
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (ac: Account) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (ac: Account) => {
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
            } else {
                showSuccess("Account No. " + ac.d_acCode + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record adding, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (ac: Account) => {
        try {
            //console.log("call editOnSave", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account No. " +ac.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            idNo: searchIdNo,
                            tel: searchTel,
                            status: searchStatus
                        },
                    },
                    cPgmId: "Clmast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                //console.log("ListRec res.data.PageShowInfo", res.data.PageShowInfo)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "Clmast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)

            ;

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });

                    //console.log("ListRec Press  res.data.PageShowInfo", res.data.PageShowInfo)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }
    
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ClMastView gsb={gsb} ac={currentAc} ac0={currentAc} StatusList={StatusList} CurrencyList={CurrencyList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClMastView>
    switch (currentMode) {
        case "edit":
            detail = <ClMastEdit gsb={gsb} ac={currentAc} StatusList={StatusList} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></ClMastEdit>
            break
        case "new":
            detail = <ClMastEdit gsb={gsb} ac={BlankAc} StatusList={StatusList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ClMastEdit>
            break
    }

    let logKeyViewRec = <ClMastView gsb={gsb} ac={CurrentLog1} ac0={CurrentLog0} StatusList={StatusList} CurrencyList={CurrencyList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClMastView>

    let logKeyViewCBRec = <ForeignCurrencyForm gsb={gsb} ac={currentAc} cb={CbCurrentLog1} cb0={CbCurrentLog0} StatusList={StatusList} CurrencyList={CurrencyList} ViewMode={"L"} logKey2={logKey2.trim()} ></ForeignCurrencyForm>


    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>


    // old, backup 
    let Pagination2 = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-IDNo">ID</th>
                <th className="TCol-Tel">Phone</th>
                <th className="TCol-AECode">Runner</th>
                <th className="TCol-Status">Status</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>
                        <td className="TCol-IDNo" onClick={() => { setCurrentAc(ac) }}>{ac.d_idNo}</td>
                        <td className="TCol-Tel" onClick={() => { setCurrentAc(ac) }}>{ac.d_tel}</td>
                        <td className="TCol-AECode" onClick={() => { setCurrentAc(ac) }}>{ac.d_aeCode}</td>
                        <td className="TCol-Status" onClick={() => { setCurrentAc(ac) }}>{ac.d_status}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && <><div className="Spacing-V-16" />{Pagination}</>}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly.substr(0,1) === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Client Master</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>
                                    <option value="aeCode">Runner</option>
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            <FormGroup className="Col-1-1" label="ID" labelFor="searchIdNo">
                                <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                            </FormGroup>
                            <FormGroup className="Col-2-1" label="Tel" labelFor="searchTel">
                                <InputGroup id="searchTel" value={searchTel} onChange={utils.handleStringChange(s => setSearchTel(s))} />
                            </FormGroup>
                            <div className="Col-3-2 Col-H">
                                <FormGroup label="Status" labelFor="searchStatus">
                                    <InputGroup className="Input-XS" id="searchStatus" value={searchStatus} onChange={utils.handleStringChange(s => setSearchStatus(s))} />
                                </FormGroup>
                                {/*<FormGroup label="Limit" labelFor="searchLimit" >
                                    <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                                </FormGroup>*/}
                            </div>
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Client Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }
                {ViewMode === "L_CB" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>CB Master Log View (Foreign Currency)</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewCBRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ClMastMain